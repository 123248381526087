import React from 'react';
import Modal from '../../../../components/Modal';
import * as Yup from 'yup';
import SecondaryButton from "../../../../components/SecondaryButton";
import Input from '../../../../components/form/Input';
import { Formik } from 'formik';
import Error from '../../../../components/form/Error';
import CenterContainer from '../../../../components/layout/CenterContainer';
import PrimaryButton from "../../../form/PrimaryButton";
import woundChart from '../../../../assets/images/chart.png'
import Select from 'react-select';
import {woundAssessmentOptions} from '../../../../utils/woundCareUtils'
import FormCheckbox from '../../../form/FormCheckbox';
import CheckboxWithInput from '../../../form/CheckboxWithInput';
import Magnifier from "react-magnifier";

class WoundCareForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      woundCare: props.woundCare,
      charLeft: 60,
      error: null,
    };
  };

  _handleSubmit = woundCare => {
    this.props.onSubmit(woundCare);
  }

  _validationSchema = () => {
    return Yup.object().shape({
      location: Yup.string().required('You must enter a location value'),
      type: Yup.string().required('You must enter a type value'),
      stage: Yup.string().when('type', {
        is: (type) => ['incision', 'ulceration'].includes(type),
        then: Yup.string().required("You must enter a stage value"),
        otherwise: null
      }),
      drainageType: Yup.string(),
      coveredWith: Yup.string(),
      packedWith: Yup.string().when('type', {
        is: (type) => ['incision', 'ulceration'].includes(type),
        then: Yup.string().required("You must enter a packed with value"),
        otherwise: null
      }),
      cleansedWith: Yup.string(),
      securedWith: Yup.string(),
      length: Yup.number(),
      width: Yup.number(),
      depth: Yup.number(),
      description: Yup.string(),
      other: Yup.boolean(),
      otherText: Yup.string().when('other', {
        is: true,
        then: Yup.string().required("Please type a description in the 'Other' box").max(60),
        otherwise: null
      })
    })
  };

  _handleBack = () => {
    this.setState({
      step: 1,
    });
  }

  _handleNext = (errors) => {
      if(Object.keys(errors).length > 0) {
        this.setState({
          error: true,
          step: 1
        })
      } else {
        this.setState({
          error: false,
          step: 2,
        })
      }
  }

  _handleCharLeft = (e) => {
    const value = e.target.value
    this.setState({
      charLeft: 60 - value.length
    })
  }

  render = () => (
    <React.Fragment>
      <Modal
        isOpen={true}
      >
        <CenterContainer>
          <Formik
            validationSchema={this._validationSchema()}
            initialValues={{
              location: this.props.woundCare.location ? this.props.woundCare.location : "",
              type: this.props.woundCare.type ? this.props.woundCare.type : "",
              stage: this.props.woundCare.stage ? this.props.woundCare.stage : "",
              drainageType: this.props.woundCare.drainageType ? this.props.woundCare.drainageType : "",
              coveredWith: this.props.woundCare.coveredWith ? this.props.woundCare.coveredWith : "",
              packedWith: this.props.woundCare.packedWith ? this.props.woundCare.packedWith : "",
              cleansedWith: this.props.woundCare.cleansedWith ? this.props.woundCare.cleansedWith : "",
              securedWith: this.props.woundCare.securedWith ? this.props.woundCare.securedWith : "",
              length: this.props.woundCare.length ? this.props.woundCare.length : "",
              width: this.props.woundCare.width ? this.props.woundCare.width: "",
              depth: this.props.woundCare.depth ? this.props.woundCare.depth : "",
              otherText: this.props.woundCare.otherText ? this.props.woundCare.otherText : "",
              other: this.props.woundCare.other ? this.props.woundCare.other : false,
              malodorous: this.props.woundCare.malodorous ? this.props.woundCare.malodorous : false,
              tunneling: this.props.woundCare.tunneling ? this.props.woundCare.tunneling : false,
              description: this.props.woundCare.description ? this.props.woundCare.description : ""
            }}
            onSubmit={values => this._handleSubmit(values)}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                validateForm,
                setFieldValue
              }) => (
              <form onSubmit={handleSubmit} className="max-w-6xl bg-white rounded-lg shadow-lg">
                  {this.state.step === 1 && (
                  <div className="flex p-8 pb-0 text-md">
                    <div className="w-full">
                    <h1 id="wound-care-heading" className="font-bold text-lg mb-4">Wound Assessment</h1>
                    <figure className="w-full h-full">
                      <Magnifier src={woundChart} width='100%' mgHeight={100} mgWidth={100}/>
                    </figure>
                    </div>
                      <div className="flex flex-col w-4/6">
                        <div className="flex">
                          <div className="m-3">
                            <label className="block mb-2">Location</label>
                            <Select
                              id="location"
                              name="location"
                              value={woundAssessmentOptions("location").find(opt => opt.value === values.location )}
                              options={woundAssessmentOptions("location")}
                              placeholder="Select one..."
                              className="react-select-container--clinical w-48"
                              classNamePrefix="react-select"
                              onChange={(selected) => setFieldValue('location', selected.value)}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              />
                              {(errors.location && touched.location) || (this.state.error && errors.location ) ? <Error>{errors.location}</Error> : null}
                          </div>
                          <div className="m-3">
                            <label className="block mb-2">Type</label>
                            <Select
                              id="type"
                              name="type"
                              value={woundAssessmentOptions("type").find(opt => opt.value === values.type)}
                              onChange={(selected) => setFieldValue('type', selected.value)}
                              options={woundAssessmentOptions("type")}
                              placeholder="Select one..."
                              className="react-select-container--clinical w-48"
                              classNamePrefix="react-select"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              />
                              {(errors.type && touched.type) || (this.state.error && errors.type ) ? <Error>{errors.type}</Error> : null}
                          </div>
                          <div className="m-3">
                            <label className="block mb-2">Stage</label>
                            <Select
                              id="stage"
                              name="stage"
                              value={woundAssessmentOptions("stage").find(opt => opt.value === values.stage )}
                              onChange={(selected) => setFieldValue('stage', selected.value)}
                              options={woundAssessmentOptions("stage")}
                              placeholder="Select one..."
                              className="react-select-container--clinical w-48"
                              classNamePrefix="react-select"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              />
                              {(errors.stage && touched.stage) || (this.state.error && errors.stage) ? <Error>{errors.stage}</Error> : null}
                          </div>
                        </div>
                        <div className="flex">
                          <div className="m-3">
                            <label className="block mb-2">Drainage type</label>
                            <Select
                              id="drainageType"
                              name="drainageType"
                              value={woundAssessmentOptions("drainageType").find(opt => opt.value === values.drainageType )}
                              onChange={(selected) => setFieldValue('drainageType', selected.value)}
                              options={woundAssessmentOptions("drainageType")}
                              placeholder="Select one..."
                              className="react-select-container--clinical w-48"
                              classNamePrefix="react-select"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              />
                              {(errors.drainageType && touched.drainageType) || (this.state.error && errors.drainageType) ? <Error>{errors.drainageType}</Error> : null}
                          </div>
                          <div className="m-3">
                            <label className="block mb-2">Covered with</label>
                            <Select
                              id="coveredWith"
                              name="coveredWith"
                              value={woundAssessmentOptions("coveredWith").find(opt => opt.value === values.coveredWith )}
                              onChange={(selected) => setFieldValue('coveredWith', selected.value)}
                              options={woundAssessmentOptions("coveredWith")}
                              placeholder="Select one..."
                              className="react-select-container--clinical w-48"
                              classNamePrefix="react-select"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              />
                              {(errors.coveredWith && touched.coveredWith) || (this.state.error && errors.coveredWith) ? <Error>{errors.coveredWith}</Error> : null}
                          </div>
                          <div className="m-3">
                            <label className="block mb-2">Packed With</label>
                            <Select
                              id="packedWith"
                              name="packedWith"
                              value={woundAssessmentOptions("packedWith").find(opt => opt.value === values.packedWith)}
                              onChange={(selected) => setFieldValue('packedWith', selected.value)}
                              options={woundAssessmentOptions("packedWith")}
                              placeholder="Select one..."
                              className="react-select-container--clinical w-48"
                              classNamePrefix="react-select"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              />
                              {(errors.packedWith && touched.packedWith) || (this.state.error && errors.packedWith) ? <Error>{errors.packedWith}</Error> : null}
                          </div>
                        </div>
                        <div className="flex">
                          <div className="m-3">
                            <label className="block mb-2">Cleansed with</label>
                            <Select
                              id="cleansedWith"
                              name="cleansedWith"
                              value={woundAssessmentOptions("cleansedWith").find(opt => opt.value === values.cleansedWith )}
                              onChange={(selected) => setFieldValue('cleansedWith', selected.value)}
                              options={woundAssessmentOptions("cleansedWith")}
                              placeholder="Select one..."
                              className="react-select-container--clinical w-48"
                              classNamePrefix="react-select"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              />
                              {(errors.cleansedWith && touched.cleansedWith) || (this.state.error && errors.cleansedWith) ? <Error>{errors.cleansedWith}</Error> : null}
                          </div>
                          <div className="m-3">
                            <label className="block mb-2">Secured with</label>
                            <Select
                              id="securedWith"
                              name="securedWith"
                              value={woundAssessmentOptions("securedWith").find(opt => opt.value === values.securedWith )}
                              onChange={(selected) => setFieldValue('securedWith', selected.value)}
                              options={woundAssessmentOptions("securedWith")}
                              placeholder="Select one..."
                              className="react-select-container--clinical w-48"
                              classNamePrefix="react-select"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              />
                              {(errors.securedWith && touched.securedWith) || (this.state.error && errors.securedWith) ? <Error>{errors.securedWith}</Error> : null}
                          </div>
                        </div>
                        <div className="flex">
                          <div className="m-3 flex">
                            <div className="mr-2 w-1/3">
                              <label className="block mb-2">Length (cm)</label>
                              <Input
                                id="length"
                                name="length"
                                className="remove-input-steps" 
                                type="number" 
                                value={values.length}
                                onChange={handleChange}
                                />
                                {(errors.length && touched.length) || (this.state.error && errors.length) ? <Error>{errors.length}</Error> : null}
                            </div>
                            <div className="mx-2 w-1/3">
                              <label className="block mb-2">Width (cm)</label>
                              <Input
                                id="width"
                                name="width" 
                                className="remove-input-steps" 
                                type="number" 
                                value={values.width}
                                onChange={handleChange}
                                />
                                {(errors.width && touched.width) || (this.state.error && errors.width) ? <Error>{errors.width}</Error> : null}
                            </div>
                            <div className="mx-2 w-1/3">
                              <label className="block mb-2">Depth (cm)</label>
                              <Input
                                id="depth"
                                name="depth" 
                                className="remove-input-steps" 
                                type="number" 
                                value={values.depth}
                                onChange={handleChange}
                                />
                                {(errors.depth && touched.depth) || (this.state.error && errors.depth) ? <Error>{errors.depth}</Error> : null}
                            </div>
                          </div>
                        </div>
                        <div className="m-3 w-full flex">
                          <FormCheckbox className="px-4 py-2 mr-2 h-auto flex justify-between items-center rounded-lg" onClick={() => setFieldValue('malodorous', !values.malodorous)} checked={values.malodorous}>Malodorous</FormCheckbox>
                          <FormCheckbox className="px-4 py-2 mx-3 h-auto flex justify-between items-center rounded-lg" onClick={() => setFieldValue('tunneling', !values.tunneling)} checked={values.tunneling}>Tunneling</FormCheckbox>
                          <CheckboxWithInput
                            className="ml-3 p-2 flex items-start mt-2 rounded-lg"
                            onClick={() => setFieldValue('other', !values.other)}
                            checked={values.other}
                            onInputChange={e => {this._handleCharLeft(e); handleChange(e)}}
                            checkboxTitle="Other"
                            inputName="otherText"
                            inputValue={values.otherText}
                            inputPlaceholder="Please clarify..."
                          />
                        </div>
                        {errors.otherText ? <Error>{errors.otherText}</Error> : null}
                        <div className="font-extrabold text-red-800 text-md">If a formation of a new wound is  noted please contact CM. Please Document call to CM in notes (date/time).</div>
                      </div>
                    </div>
                    )}
                      {this.state.step === 2 && (
                        <div className="flex flex-col wound-notes-wh p-8 pb-0 text-md">
                          <h1 id="wound-care-heading" className="font-bold text-lg mb-4">Wound Assesment</h1>
                          <div className="text-sm">Step by step wound care details</div>
                          <textarea
                            autoFocus
                            id="description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            className="w-full h-full bg-gray-200 my-4 p-2 pl-4 rounded-lg"
                          />
                          {(errors.description && touched.description) ? <Error>{errors.description}</Error> : null}
                        </div>
                      )}
                  <div className="p-3 px-8 text-right bg-gray-200 rounded-b-lg flex justify-end items-center">
                    <div>
                      {this.state.step === 1 && <SecondaryButton id ="wound-care-secondary-button" className="inline-block mr-4" type="button" onClick={this.props.onCancel}>Cancel</SecondaryButton>}
                      {this.state.step === 1 && <PrimaryButton id="wound-care-next-button" type="button" onClick={() => validateForm().then(errors => this._handleNext(errors))} >Next</PrimaryButton>}
                      {this.state.step === 2 && <SecondaryButton id ="wound-care-secondary-button" className="inline-block mr-4" type="button" onClick={this._handleBack}>Back</SecondaryButton>}
                      {this.state.step === 2 && <PrimaryButton id="wound-care-next-button" type="submit" >Submit</PrimaryButton>}
                    </div>
                  </div>
              </form>
            )}
          </Formik>
        </CenterContainer>
      </Modal>
    </React.Fragment>
  );
}

export default WoundCareForm;

import { loadState } from '../storage/localStorage';

const Api = {
  fetchClient: async id => (
    fetch(`${loadState().settings.apiEndpoint}/clients/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${loadState().settings.apiKey}`,
      }
    })
  ),

  fetchShiftReportsByClientId: async clientId => (
    fetch(`${loadState().settings.apiEndpoint}/clients/${clientId}/shift_reports`, {
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${loadState().settings.apiKey}`
      }
    })
  ),

  postShiftReport: async payload => (
    fetch(`${loadState().settings.apiEndpoint}/shift_reports`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${loadState().settings.apiKey}`,
      },
      body: payload
    })
  )
};

export default Api;

import React from 'react'
import {Formik} from 'formik'
import * as Yup from 'yup'
import SecondaryButton from './SecondaryButton'
import DangerButton from './DangerButton'
import CenterContainer from './layout/CenterContainer'

const Reset = props => (
  <CenterContainer>
    <div className="bg-white shadow-lg rounded-lg w-1/2">
      <Formik
        initialValues=""
        onSubmit={values => {
          props.onSubmit()
        }}
        validationSchema={Yup.object().shape({
        })}
      >
        {({
            handleSubmit,
          }) => (
          <form onSubmit={handleSubmit}>
            <div className="p-8 rounded-t-lg">
              <h1 id="dialog-heading" className="font-bold text-gray-800 text-lg">Reset data?</h1>
              <h2 className="text-gray-600 mb-4">Delete all local data except for settings.</h2>
              <div className="border-l-4 border-red-400 text-red-900">
                <p className="pl-4 leading-snug">
                  By selecting "Yes, Delete" all of the unsaved shift reports and data on this computer will be deleted.
                  The settings will not be affected.  Are you sure you want to delete?
                </p>
              </div>
            </div>
            <div className="bg-gray-100 text-right p-4 px-8 rounded-b-lg">
              <SecondaryButton className="mr-4" onClick={props.onCancelClick} type="button">No, Cancel</SecondaryButton>
              <DangerButton>Yes, Delete</DangerButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  </CenterContainer>
)

export default Reset
import React from 'react'
import classnames from 'classnames'

const checkedClassName = "fas fa-check-square inline-block mr-3 text-green-600"
const disabledClassName = "fas fa-square inline-block mr-3 bg-gray-200 text-gray-300"
const className = "fal fa-square inline-block mr-3"

const Checkbox = props => (
  <i className={props.checked ?
                classnames(checkedClassName, props.className) : props.disabled ?
                classnames(disabledClassName, props.className) :
                classnames(className, props.className)}
     disabled={props.disabled} />
)

export default Checkbox

import React from 'react'
import PropTypes from 'prop-types'
import WebcamCapture from '../../../WebcamCapture'
import SecondaryButton from '../../../SecondaryButton'
import PrimaryButton from '../../../form/PrimaryButton'
import Checkbox from '../../../Checkbox'
import {compareTimes} from "../../../../utils/timeUtils";

const ShiftReportSubmitModal = ({
  user,
  step,
  onAdvance,
  onBack,
  onCapture,
  onConfirm,
  onCancel,
  onSubmit,
  userConfirmed,
  signatureConfirmed,
  onConfirmSignature,
  shiftEndTime,
  image
}) => {
  return (
    <div>
      <div className="p-8">
        <div className="font-bold text-lg">Submit Shift Report</div>
        { step === 0 && (
          <>
            <div id="confirm-endtime" className="text-gray-600 mb-6">Confirm end of shift</div>
              <div>
                { compareTimes(shiftEndTime, Date.now()).hours() > 0 && (
                  <p>
                    You are trying to submit your shift report <span className="font-bold"> {compareTimes(shiftEndTime, Date.now()).hours()} hours {compareTimes(shiftEndTime, Date.now()).minutes()} minutes</span> before the end of the shift.
                    If you are leaving early, please click "Cancel" and change the shift end time as needed.
                  </p>
                )}
                { compareTimes(shiftEndTime, Date.now()).hours() === 0 && (
                  <p>
                    You are trying to submit your shift report <span className="font-bold"> {compareTimes(shiftEndTime, Date.now()).minutes()} minutes</span> before the end of the shift.
                    If you are leaving early, please click "Cancel" and change the shift end time as needed.
                  </p>
                )}
              </div>
          </>
        )}

        { step === 1 && (
          <>
            <div className="text-gray-600 mb-6">Confirm and acknowledge</div>
            <button id="acknowledge-checkbox" onClick={onConfirm} type="button" className={userConfirmed ? "text-left p-4 rounded-lg text-green-800 leading-tight bg-green-100 flex" : "leading-tight p-4 rounded-lg bg-gray-100 flex text-left"}>
              <Checkbox checked={userConfirmed} className="text-2xl" />
              <div>
                <p>
                  I acknowledge that the clinical documentation provided is accurate, unbiased and the services
                  recorded have been rendered.
                </p>
              </div>
            </button>
          </>
        )}

        { step === 2 && (
          <>
            <div className="text-gray-600 mb-6">Confirm your name and signature</div>
            <div className="flex">
              <div className="w-1/2 mr-2">
                <div className="signature mb-1 text-xl border-b border-gray-400">
                  { user.firstName } { user.lastName } / { user.role }
                </div>
                <div className="text-sm mb-4 text-gray-600">
                  Signature/Role
                </div>
              </div>
              <div className="w-1/2 ml-2">
                <div className="mb-1 text-xl border-b border-gray-400">
                  { user.firstName } { user.lastName } / { user.role }
                </div>
                <div className="text-sm mb-4 text-gray-600">
                  Name/Role
                </div>
              </div>
            </div>
            <p className="mb-4">
              By clicking "Adopt and Sign", I agree that the signature will be the electronic representation of my
              signature for all purposes when I use them on documents, including legally
              binding contracts - just the same as a pen-and-paper signature or initial.
            </p>
            <button id="signature-checkbox" onClick={onConfirmSignature} type="button" className={signatureConfirmed ? "w-full text-left p-4 rounded-lg text-green-800 leading-tight bg-green-100 flex" : "leading-tight p-4 rounded-lg bg-gray-100 flex text-left w-full"}>
              <Checkbox checked={signatureConfirmed} className="text-2xl" />
              <div>
                <p>
                  Adopt and Sign
                </p>
              </div>
            </button>
          </>
        )}

        { step === 3 && (
          <>
            <div id="confirm-identity-heading" className="text-gray-600 mb-8">Confirm your identity</div>
            <WebcamCapture onCapture={onCapture} image={image} />
          </>
        )}
        { step === 4 && (
          <>
            <div className="text-gray-600 mb-4">Are you sure?</div>
            <p id="report-submit-warning">
              Once you submit this shift report you will no longer be able to make changes to it.<br />
              <b>Are you sure you want to submit?</b>
            </p>
          </>
        )}
      </div>
      <div className="text-right bg-gray-100 p-4 rounded-b-lg">
        { step === 0 && (
          <>
            <SecondaryButton id="endtime-secondary-button" className="inline-block mr-4" onClick={onCancel}>Cancel</SecondaryButton>
            <PrimaryButton id="endtime-primary-button" onClick={onAdvance}>Continue Anyways</PrimaryButton>
          </>
        )}

        { step === 1 && (
          <>
            <SecondaryButton id="acknowledge-secondary-button" className="inline-block mr-4" onClick={onCancel}>Cancel</SecondaryButton>
            <PrimaryButton id="acknowledge-primary-button" onClick={onAdvance} disabled={!userConfirmed}>Next</PrimaryButton>
          </>
        )}
        { step === 2 && (
          <>
            <SecondaryButton id="signature-secondary-button" className="inline-block mr-4" onClick={onBack}>Back</SecondaryButton>
            <PrimaryButton id="signature-primary-button" onClick={onAdvance} disabled={!signatureConfirmed}>Next</PrimaryButton>
          </>
        )}
        { step === 3 && (
          <>
            <SecondaryButton id="identity-secondary-button" className="inline-block mr-4" onClick={onBack}>Back</SecondaryButton>
            <PrimaryButton id="identity-primary-button" onClick={onAdvance} disabled={!image}>Next</PrimaryButton>
          </>
        )}
        { step === 4 && (
          <>
            <SecondaryButton  id="report-secondary-button"className="inline-block mr-4" onClick={onBack}>Back</SecondaryButton>
            <PrimaryButton id="report-primary-button" onClick={onSubmit} disabled={!image}>Yes, Submit</PrimaryButton>
          </>
        )}
      </div>
    </div>
  )
}

ShiftReportSubmitModal.propTypes = {
  userConfirmed: PropTypes.bool.isRequired,
  signatureConfirmed: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onConfirmSignature: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  onAdvance: PropTypes.func.isRequired,
  onCapture: PropTypes.func.isRequired,
  shiftEndTime: PropTypes.string.isRequired,
  image: PropTypes.string
}

export default ShiftReportSubmitModal

import React, { useState } from 'react'
import classnames from 'classnames'
import BasicCheckbox from '../../../form/BasicCheckbox'

const BowelSoundsCheckboxes = ({
  audible,
  activity,
  onAudibleChange,
  onActivityChange,
  name,
  index
}) => {
  const [audibleState, setAudibleState] = useState(audible)
  const [activityState, setActivityState] = useState(activity)

  const handleAudibleClick = (val) => {
    setAudibleState(val);
    onAudibleChange(val);
  }

  const handleActivityClick = (val) => {
    setActivityState(val);
    onActivityChange(val);
  }

  return (
    <tr className={classnames('align-top', {'bg-gray-100': !(index % 2)})} id={`bowel_sounds_${index}_checkboxes`}>
      <td className="p-4">
        {name}
      </td>
      <td className="p-4">
        <BasicCheckbox
          checked={audibleState === 'present'}
          onClick={() => handleAudibleClick('present')}
          className="block m-2"
        />
      </td>
      <td className="p-4" >
        <BasicCheckbox
          checked={audibleState === 'absent'}
          onClick={() => handleAudibleClick('absent')}
          className="block m-2"
        />
      </td>
      <td className="p-4 pl-6" >
        <BasicCheckbox
          checked={activityState === 'hyperactive'}
          onClick={() => handleActivityClick('hyperactive')}
          className="block m-2"
          disabled={audibleState === 'absent'}
        />
      </td>
      <td className="p-4" >
        <BasicCheckbox
          checked={activityState === 'hypoactive'}
          onClick={() => handleActivityClick('hypoactive')}
          className="block m-2"
          disabled={audibleState === 'absent'}
        />
      </td>
      <td className="p-4" >
        <BasicCheckbox
          checked={activityState === 'normal'}
          onClick={() => handleActivityClick('normal')}
          className="block m-2"
          disabled={audibleState === 'absent'}
        />
      </td>
    </tr>
  )
}

export default BowelSoundsCheckboxes

import { createSelector } from 'reselect'
import { SHIFT_REPORT_STATUS_SUBMITTED } from '../constants';

const getClientId = (state) => state.settings.clientId;
const getClientEntities = (state) => state.entities.clients.byId;
const getEmployeeEntities = (state) => state.entities.employees.byId;
const getShiftReportEntities = (state) => state.entities.shiftReports.byId;
const getUserId = (state) => state.user.id;

export const getClient = createSelector(
  [ getClientId, getClientEntities ],
  (clientId, clients) => {
    return clients[clientId];
  }
);

export const getEmployees = createSelector(
  [ getClientId, getClientEntities, getEmployeeEntities],
  (clientId, clients, employees) => {
    if(!clients[clientId]) {
      return null;
    }

    return clients[clientId].employees.map(employeeId => {
      return employees[employeeId];
    });
  }
);

export const getUser = createSelector(
  [getUserId, getEmployeeEntities],
  (userId, employees) => {
    return employees[userId] ? employees[userId] : null;
  }
);

export const getUserShiftReports = createSelector(
  [getClient, getUser, getShiftReportEntities],
  (client, user, shiftReports) => {
    if(user.shiftReports === undefined) {
      return [];
    }

    return user.shiftReports.filter(id => {
      return client.shiftReports.indexOf(id) !== -1
    }).map(id => {
      return shiftReports[id];
    });
  }
);

export const getSubmittedClientShiftReports = createSelector(
  [getClient, getShiftReportEntities],
  (client, shiftReports) => {
    if(client.shiftReports === undefined) {
      return [];
    }
    
    return client.shiftReports
              .map(id => shiftReports[id])
              .filter(report => report.status === SHIFT_REPORT_STATUS_SUBMITTED)
  }
)

import React from 'react'
import { connect } from 'react-redux'
import { createIncidentReport, updateIncidentReport } from '../actions'
import { isMidnight } from '../utils/timeUtils'
import IncidentReportForm from '../components/IncidentReportForm'
import IncidentReportTable from '../components/IncidentReportTable'

const initialState = {
  isAdding: false,
  isEditing: false,
  curIncidentReportId: null
}

class IncidentReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  _handleAddIncidentReport = () => {
    this.setState({
      isAdding: true
    })
  }

  _handleEditIncidentReport = id => {
    this.setState({
      isEditing: true,
      curIncidentReportId: id
    })
  }

  _handleCancel = () => {
    this.setState(initialState)
  }

  _handleAddSubmit = (incidentReport) => {
    this.props.createIncidentReport({
      ...incidentReport,
      shiftReportId: this.props.shiftReport.id
    })
    this._handleCancel()
  }

  _handleEditSubmit = (incidentReport) => {
    this.props.updateIncidentReport({
      ...this.props.incidentReports[this.state.curIncidentReportId],
      ...incidentReport
    })
    this._handleCancel()
  }

  render() {
    return (
      <>
        {this.state.isAdding && (
          <IncidentReportForm
            onCancel={this._handleCancel}
            onSubmit={this._handleAddSubmit}
            incidentReport={{
              type: 'client_fall',
              comment: '',
              notified: false
            }}
            start={this.props.shiftReport.start}
            end={this.props.shiftReport.end}
          />
        )}
        {this.state.isEditing && (
          <IncidentReportForm
            onCancel={this._handleCancel}
            onSubmit={this._handleEditSubmit}
            incidentReport={this.props.incidentReports.find(ir => ir.id === this.state.curIncidentReportId)}
            start={this.props.shiftReport.start}
            end={this.props.shiftReport.end}
          />
        )}
        <IncidentReportTable
          onAdd={this._handleAddIncidentReport}
          onEdit={this._handleEditIncidentReport}
          incidentReports={this.props.incidentReports}
          shiftEndsAtMidnight={isMidnight(this.props.shiftReport.end)}
        />
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  updateIncidentReport: incidentReport => dispatch(updateIncidentReport(incidentReport)),
  createIncidentReport: incidentReport => dispatch(createIncidentReport(incidentReport))
});

const mapStateToProps = (state, ownProps) => ({
  incidentReports: ownProps.shiftReport.incidentReports.map(id => state.entities.incidentReports.byId[id]),
  ...ownProps
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentReports)

import moment from 'moment-timezone'
import uuidv4 from 'uuid/v4'
import { SHIFT_TYPE_HHA, SHIFT_TYPE_RN } from '../constants'
import { SHIFT_REPORT_STATUS_OPEN } from '../constants'

const shiftReportGen = (userId, clientId, shiftStart, shiftEnd, shiftType) => {
  return {
    id: uuidv4(),
    type: shiftType,
    userId: userId,
    clientId: clientId,
    verificationImage: null,
    start: shiftStart,
    end: shiftEnd,
    status: SHIFT_REPORT_STATUS_OPEN,
    timezone: moment.tz.guess(),
    createdAt: moment().format(),
    updatedAt: moment().format()
  };
}

const hhaShiftReportDecoratorV1 = (report, shiftStart, shiftEnd) => {
  report.version = 1;
  report.personalCareTasks = [];
  report.incidentReports = [];
  report.activities = [];
  report.vitals = [];
  return report;
}

const clinicalNoteShiftReportDecoratorV1 = (report, shiftStart, ShiftEnd) => {
  report.version = 1;
  report.assessment = null;
  report.intake = null;
  report.output = null;
  report.vitals = [];
  report.woundCares = [];
  report.clinicalContinuationNotes = [];
  report.painAssessments = [];
  return report;
}

export const generateShiftReport = (userId, clientId, shiftStart, shiftEnd, shiftType) => {
  const report = shiftReportGen(userId, clientId, shiftStart, shiftEnd, shiftType);
  switch(shiftType) {
    case SHIFT_TYPE_HHA:
      return hhaShiftReportDecoratorV1(report, shiftStart, shiftEnd);
    case SHIFT_TYPE_RN:
      return clinicalNoteShiftReportDecoratorV1(report, shiftStart, shiftEnd);
    default:
      return null;
  }
}

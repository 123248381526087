import { createSelector } from 'reselect'

const getActivitiesByShiftReportId = (state, props) => state.entities.shiftReports.byId[props.shiftReportId].activities.map(id => state.entities.activities.byId[id]);

export const getActivities = createSelector(
  [getActivitiesByShiftReportId], (activities) => {
    return activities
  })

export const getActivityMatrix = createSelector(
  [getActivities], (activities) => {
    const activityMatrix = [];
      activities
      .forEach(activity => {
        if(activityMatrix[activity.type] === undefined) {
          activityMatrix[activity.type] = [];
        }
        activityMatrix[activity.type][activity.recordedAt] = activity;
      });

    return activityMatrix;
  });

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../Modal'
import CenterContainer from '../../../layout/CenterContainer'
import ShiftReportErrorModal from './ShiftReportErrorModal'
import ShiftReportSubmitModal from './ShiftReportSubmitModal'
import {checkTime} from '../../../../utils/timeUtils'

const ShiftReportModal = ({
  isOpen,
  errors,
  user,
  onCancel,
  onCapture,
  shiftEndTime
}) => {
  const [userConfirmed, changeUserConfirmed] = useState(false)
  const [signatureConfirmed, changeSignatureConfirmed] = useState(false);
  const [step, changeStep] = useState(0)
  const [image, changeImage] = useState(null)

  const toggleUserConfirmed = () => changeUserConfirmed(!userConfirmed)
  const toggleSignatureConfirmed = () => changeSignatureConfirmed(!signatureConfirmed)
  const nextStep = () => changeStep(step + 1)
  const prevStep = () => changeStep(step - 1)
  const capture = (image) => changeImage(image)

  const submit = () => {
    onCapture(image)
  }

  useEffect(() => {
    if(checkTime(shiftEndTime)){
      changeStep(0)
    } else {
      changeStep(1)
    }
  },[isOpen, shiftEndTime])

  useEffect(() => {
    changeUserConfirmed(false)
    changeImage(null)
    changeSignatureConfirmed(false)
  }, [isOpen])

  return (
    <Modal isOpen={isOpen}>
      <CenterContainer>
        <div className="bg-white rounded-lg shadow-lg w-1/2">
          {errors.length > 0 ? (
            <ShiftReportErrorModal 
              errors={errors} 
              onClose={onCancel} 
            />
          ) : (
            <ShiftReportSubmitModal
              step={step}
              user={user}
              image={image}
              userConfirmed={userConfirmed}
              signatureConfirmed={signatureConfirmed}
              onAdvance={nextStep}
              onBack={prevStep}
              onConfirm={toggleUserConfirmed}
              onConfirmSignature={toggleSignatureConfirmed}
              onCancel={onCancel} 
              onSubmit={submit}
              onCapture={capture}
              shiftEndTime={shiftEndTime}
            />
          )}
        </div>
      </CenterContainer>
    </Modal>
  )
}

ShiftReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCapture: PropTypes.func.isRequired,
  image: PropTypes.string,
  shiftEndTime: PropTypes.string.isRequired
}

export default ShiftReportModal
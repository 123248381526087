export const ROLE_HHA = 'HHA';
export const ROLE_LPN = 'LPN';
export const ROLE_RN = 'RN';


export const SHIFT_TYPE_HHA = 'hha';
export const SHIFT_TYPE_RN = 'rn';
export const SHIFT_TYPE_CLINICAL = 'clinical'


export const roleShiftTypeMap = {
  [ROLE_HHA]: SHIFT_TYPE_HHA,
  [ROLE_LPN]: SHIFT_TYPE_RN,
  [ROLE_RN]: SHIFT_TYPE_RN,
};

export const SHIFT_REPORT_STATUS_OPEN = 'open';
export const SHIFT_REPORT_STATUS_PENDING = 'pending';
export const SHIFT_REPORT_STATUS_SUBMITTED = 'submitted';
export const SHIFT_REPORT_STATUS_FAILED = 'failed';
export const SHIFT_REPORT_STATUS_FAILED_NETWORK_CONNECTIVITY = 'failed_network_connectivity';

// Personal Care Tasks
export const personalCareTasks = [
  'bath_tub_shower',
  'skin_care',
  'oral_denture_care',
  'dressing_assist',
  'med_reminder',
  'meal_prep_feeding',
  'elimination_assist'
];

export const personalCareTaskLabels = {
  'bath_tub_shower': 'Bath - Tub/Shower',
  'skin_care': 'Skin Care',
  'oral_denture_care': 'Oral Care/Denture Care',
  'dressing_assist': 'Dressing Assist',
  'med_reminder': 'Med Reminder',
  'meal_prep_feeding': 'Meal Prep/Feeding',
  'elimination_assist': 'Elimination Assist'
};

export const personalCareTaskHints = {
  'skin_care': 'Refer to HHA scope of practice',
  'med_reminder': 'Refer to HHA scope of practice',
  'elimination_assist': 'Record output',
};

// Incident Reports
export const incidentReportTypes = {
  client_fall: "Client fall",
  new_skin_issue: "New skin issue",
  client_admin_med_error: "Client administration medication error",
  client_injury: "Client injury",
  client_abnormal_visits: "Client abnormal visits",
  other: "Other"
}

export const AMBULATION_TRANSFER_ASSIST = 'ambulation_transfer_assist';
export const TURN_POSITION = 'turn_position';
export const ROM_EXERCISE = 'rom_exercise';

export const activities = [
  AMBULATION_TRANSFER_ASSIST,
  TURN_POSITION,
  ROM_EXERCISE,
];

export const activityLabels = {
  [AMBULATION_TRANSFER_ASSIST]: 'Ambulation/Transfer Assist',
  [TURN_POSITION]: 'Turn/Position',
  [ROM_EXERCISE]: 'ROM Exercise - Active/Passive',
};

export const shiftTypeLabels = {
  [SHIFT_TYPE_HHA]: 'HHA',
  [SHIFT_TYPE_RN]: 'Clinical'
}

// Assessments
export const basicAssessmentMappings = {
  disposition_stable: 'Stable',
  disposition_happiness: '',
  disposition_anxious: 'Anxious',
  disposition_irritable: 'Irritable',
  disposition_withdrawn: 'Withdrawn',
  disposition_change_in_condition: 'Change in condition',
  head_centered: 'Normocephalic/Erect/Centered',
  head_facial_expressions_smooth: 'Facial expressions smooth/even',
  head_lesions: 'Lesions',
  head_flaking_scalp: 'Flaking scalp',
  head_other: 'Other',
  head_other_value: '',
  eyes_perrla: 'PERRLA',
  eyes_clear: 'Clear',
  eyes_glasses: 'Glasses/Contacts',
  eyes_drainages: 'Drainages',
  eyes_glaucoma: 'Glaucoma',
  eyes_macular_degeneration: 'Macular Degeneration',
  eyes_edema: 'Periorbital Edema',
  eyes_other: 'Other',
  eyes_other_value: '',
  ears_clear: 'Clear',
  ears_drainage: 'Drainage',
  ears_hoh_right: 'HOH (R)',
  ears_hoh_left: 'HOH (L)',
  ears_aide_right: 'Hearing Aide (R)',
  ears_aide_left: 'Hearing Aide (L)',
  ears_other: 'Other',
  ears_other_value: '',
  nose_patent: 'Patent',
  nose_drainage: 'Drainage',
  nose_septal_deviation: 'Septal Deviation',
  nose_other: 'Other',
  nose_other_value: '',
  mouth_pink: 'Pink/Moist',
  mouth_cyanotic: 'Cyanotic',
  mouth_dry: 'Dry/Cracked',
  mouth_bleeding: 'Bleeding',
  mouth_lesions: 'Lesions',
  mouth_other: 'Other',
  mouth_other_value: '',
  tongue_pink: 'Pink/Moist',
  tongue_midline: 'Midline',
  tongue_intact: 'Intact',
  tongue_glistening: 'Glistening',
  tongue_dry: 'Dry',
  tongue_swollen: 'Swollen',
  tongue_deviated: 'Deviated',
  tongue_other: 'Other',
  tongue_other_value: ''
}

export const digestiveAssessmentMappings = {
  bowels_continence: '',
  bowels_constipated: 'Constipated',
  bowels_loose: 'Loose',
  bowels_diarrhea: 'Diarrhea',
  bowels_tarry_stools: 'Tarry Stools',
  bowels_brown: 'Brown/Formed',
  bowels_ostomy: 'Ostomy',
  bowels_last_bm: '',
  bowels_enema: 'Enema used',
  bowel_sounds_ruq_audible: '',
  bowel_sounds_ruq_activity: '',
  bowel_sounds_rlq_audible: '',
  bowel_sounds_rlq_activity: '',
  bowel_sounds_luq_audible: '',
  bowel_sounds_luq_activity: '',
  bowel_sounds_llq_audible: '',
  bowel_sounds_llq_activity: '',
  bladder_continence: '',
  bladder_distended: 'Distended',
  bladder_odor: 'Urinary Odor',
  bladder_dribbles: 'Dribbles',
  bladder_pain: 'Pain/Burning',
  bladder_oliguria: 'Oliguria',
  bladder_spasms: 'Spasms',
  bladder_urostomy: 'Urostomy',
  bladder_other: 'Other',
  bladder_other_value: '',
  bladder_catheter_care: 'Catheter Care',
  bladder_catheter_location: '',
  bladder_catheter_type: '',
  bladder_catheter_size: '',
  bladder_catheter_insertion_date: '',
  bladder_catheter_change_frequency: 'Frequency of Change',
  bladder_catheter_change_frequency_value: '',
  abdomen_size: '',
  abdomen_firmness: '',
  abdomen_mass: 'Mass',
  abdomen_mass_value: '',
  abdomen_feeding_tube: 'Feeding Tube',
  abdomen_feeding_tube_value: '',
  abdomen_girth: ''
}

export const cardiovascularAssessmentMappings = {
  apical_rhythm: '',
  edema_jvd: 'JVD',
  edema_pitting: '',
  edema_location: '',
  edema_grade: '',
  capillary_refill_timing: '',
  capillary_refill_clubbing: 'Clubbing',
  pedal_pulses_presence_left: '',
  pedal_pulses_presence_right: '',
  pedal_pulses_strength_left: '',
  pedal_pulses_strength_right: '',
  radial_pulses_presence_left: '',
  radial_pulses_presence_right: '',
  radial_pulses_strength_left: '',
  radial_pulses_strength_right: '',
  abnormal_cardiovascular_findings: ''
}

export const neuroAssessmentMappings = {
  consciousness_alert: "Alert",
  consciousness_lethargic: "Lethargic",
  consciousness_oriented: "Oriented",
  consciousness_disoriented: "Disoriented",
  consciousness_forgetful: "Forgetful",
  consciousness_short_term_memory_loss: "Short-term memory loss",
  consciousness_follows_simple_commands: "Follows Simple Commands",
  consciousness_non_responsive: "Non-Responsive",
}

export const pulmonaryAssessmentMappings = {
  chest_expansion_symmetry: '',
  chest_expansion_barrel: 'Barrel Chested',
  breath_sounds_right: [],
  breath_sounds_left: [],
  breath_sounds_upper: [],
  breath_sounds_lower: [],
  breath_sounds_coarse: '',
  respiratory_depth: '',
  respiratory_accessory: 'Use of accessory muscles',
  pattern_labor: '',
  quality_nasal_breathing: 'Nasal Breathing',
  quality_apnea: 'Apnea',
  quality_dyspnea: 'Dyspnea',
  quality_tachypnea: 'Tachypnea',
  quality_accessory: 'Use of accessory muscles',
  quality_nasal_flaring: 'Nasal Flaring',
  quality_pursed: 'Pursed Lip Breathing',
  cough_presence: '',
  cough_productive: '',
  cough_wetness: '',
  cough_hacking: 'Hacking',
  sputum_thickness: '',
  sputum_foul_odor: 'Foul Odor',
  sputum_amount: '',
  sputum_color: '',
  oxygen_administered: 'Oxygen Administered',
  oxygen_liters: '',
  oxygen_delivery_method: '',
  oxygen_continuity: '',
  oxygen_trach: 'Trach',
  oxygen_trach_value: '',
  oxygen_cpap: 'CPAP',
  oxygen_cpap_value: '',
  oxygen_bipap: 'BI-PAP',
  oxygen_bipap_value: '',
  oxygen_apap: 'APAP',
  oxygen_apap_value: '',
  abnormal_pulmonary_findings: ''
}

export const neuroAssessmentTypes = ["Level of Consciousness"]

export const neuroAssessmentFields = function() {
  const fields = Object.keys({
    ...neuroAssessmentMappings
  }).map(t => [t, false])
  return Object.fromEntries(fields)
}()

export const assessmentFields = function() {
  const fields = Object.keys({
    ...basicAssessmentMappings,
    ...digestiveAssessmentMappings,
    ...cardiovascularAssessmentMappings,
    ...neuroAssessmentMappings,
    ...pulmonaryAssessmentMappings
  }).map(t => {
    if(basicAssessmentMappings[t] === '' ||
       digestiveAssessmentMappings[t] === '' ||
       cardiovascularAssessmentMappings[t] === '' ||
       pulmonaryAssessmentMappings[t] === ''
      ) {
      return [t, '']
    } else if(Array.isArray(pulmonaryAssessmentMappings[t])) {
      return [t, []]
    } else {
      return [t, false]
    }
  })
  return Object.fromEntries(fields)
}()

export const intakeOptions = [
  {
    'value': "0",
    'label': '0%'
  },
  {
    'value': "25",
    'label': '25%'
  },
  {
    'value': "50",
    'label': '50%'
  },
  {
    'value': "75",
    'label': '75%'
  },
  {
    'value': "100",
    'label': '100%'
  }
]

const createLocationNumberObject = () => {
  const numArray = Array.from({length: 45}, (v, k) => (k + 1).toString())
  const object = {}
  numArray.forEach(number => object[`${number}`] = Number(number))
  return object;
}


export const LOCATION_MAPPINGS = createLocationNumberObject();

export const WOUND_ASSESSMENT_TYPE_MAPPINGS = {
  incision: "Incision",
  skin_tear: "Skin Tear",
  ulceration: "Ulceration",
  fracture: "Fracture"
}

export const WOUND_ASSESSMENT_STAGE_MAPPINGS = {
  stage_1: "Stage 1",
  stage_2: "Stage 2",
  stage_3: "Stage 3",
  stage_4: "Stage 4",
  unstageable: "Unstageable",
  suspected_deep_tissue_injury: "Suspected Deep Tissue Injury"
}

export const WOUND_ASSESSMENT_DRAINAGE_TYPE_MAPPINGS = {
  none: "None",
  serosanguineous: "Serosanguineous (Blood-tinged amber fluid)",
  sanguineous: "Sanguineous (bloody)",
  purulent: "Purulent (pus)"
}

export const WOUND_ASSESSMENT_COVERED_WITH_MAPPINGS = {
  non_adherent_dressing : "Non-adherent dressing (ex-Telda)",
  surgi_pads_abdominal_pads: "Surgi-Pads/Abdominal Pads",
  transparent_film_dressing: "Transparent Film Dressing (ex-OpSite)",
  hydrocolloid_dressing : "Hydrocolloid Dressing (ex- DuoDerm)",
  binder: "Binder",
  negative_pressure_treatment: "Negative Pressure Treatment (Wound Vac)"
}

export const WOUND_ASSESSMENT_PACKED_WITH = {
  moistened_gauze: "Moistened Gauze",
  dry_gauze: "Dry Gauze",
}

export const WOUND_ASSESSMENT_CLEANSED_WITH = {
  water: "Water",
  saline: "Saline",
  wound_cleanser: "Wound Cleanser",
}

export const WOUND_ASSESSMENT_SECURED_WITH = {
  wound_tape: "Wound Tape",
  stretch_roller_gauze: "Stretch Roller Gauze",
  mesh_netting: "Mesh Netting",
  elastic_bandage: "Elastic Bandage",
  montgomery_straps: "Montgomery Straps"
}

const createNumberObject = () => {
  const numArray = Array.from({length: 11}, (v, k) => k.toString())
  const object = {}
  numArray.forEach(number => object[`${number}`] = number)
  return object;
}

export const PAIN_ASSESSMENT_SCALE_MAPPINGS = {
  numeric: "Numeric",
  flacc: "FLACC",
  wong_baker_face: "Wong Baker-Face"
}

export const PAIN_ASSESSMENT_PRESENT_LEVEL_MAPPINGS = createNumberObject();

export const PAIN_ASSESSMENT_WORST_PAIN_GETS_MAPPINGS = createNumberObject();

export const PAIN_ASSESSMENT_BEST_PAIN_GETS_MAPPINGS = createNumberObject();


export const CONTINUE= 'CONTINUE'
export const EXIT= 'EXIT'

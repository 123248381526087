import React from "react";

const TimeOfDayIcon = ({time}) => {
  // 7 is 7:00 AM, 19 is 7:00 PM
  if(time.format("H") >= 7 && time.format("H") < 19) {
    return <i className="fas fa-sun text-yellow-500"></i>;
  }
  return <i className="fas fa-moon-stars text-blue-600"></i>;
}

export default TimeOfDayIcon;

import React from 'react';

const Error = props => (
  <div {...props} className="fixed bg-red-200 p-4 pt-3 pb-3 text-red-600 w-full flex justify-between">
    <div className="flex items-center">
      <i className="fas fa-exclamation-circle mr-2" /> {props.children}
    </div>
    <div >
      <button onClick={() => props.onDismiss()} className="text-2xl"><i className="fal fa-times" /></button>
    </div>
  </div>
);

export default Error;

import React from 'react';
import { connect } from 'react-redux';
import Loader from '../components/Loader';
import { logoutUser } from '../actions';

class Logout extends React.Component {
    componentDidMount() {
        this.props.logoutUser();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.lastUpdated !== this.props.lastUpdated) {
            this.props.history.push('/');
        }
    }

    render = () => (
        <React.Fragment>
            <Loader>Logging out...</Loader>
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser())
});

const mapStateToProps = state => ({
    lastUpdated: state.user.lastUpdated,
});

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Logout);

export default Container;

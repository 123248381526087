import React from 'react'
import { Formik } from "formik";
import PropTypes from 'prop-types'
import moment from "moment";
import * as Yup from "yup";
import CenterContainer from "./layout/CenterContainer";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./form/PrimaryButton";
import TimeOfDayIcon from "./TimeOfDayIcon";
import TimeSelect from './TimeSelect';
import {
  getDate,
  timeOptions,
  timeOptionSelect,
} from "../utils/timeUtils";

const ShiftReportTimeStart = (props) => (
  <CenterContainer>
    <Formik
      initialValues={{
        start: props.start ? timeOptionSelect(props.start) : ""
      }}
      validationSchema={Yup.object().shape({
        start: Yup.string().required(),
      })}
      onSubmit={(values) => {
        props.handleStartNext({
          start: values.start.value,
        });
      }}
    >
      {({ values, errors, setFieldValue, handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className="w-1/2 bg-white shadow-lg border-lg rounded-lg"
        >
          <div className="p-8">
            <span className="font-bold text-lg">Create a new shift report</span>
            <p id="shift-time-start-heading" className="mb-6 text-gray-600">
              Select the shift start time
            </p>
            <div className="mb-4">
              <div className="flex justify-between">
                <div className="w-2/5">
                  <div>
                    <div className="text-gray-600">What time does this shift start?</div>
                    <div className="text-xl my-2">
                    <TimeSelect
                        id="time-start"
                        value={values.start}
                        options={timeOptions(moment().startOf("day"), moment().add(1,'days').startOf('day'), true)}
                        placeholder="Choose a start time..."
                        onChange={time => setFieldValue('start', time)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between bg-gray-100 px-8 py-4">
              <div className="text-gray-600">
                {values.start && (
                  <>
                    <div className="uppercase text-sm">{getDate(props.date, values.start.value).format("ll")}</div>
                    <div className="text-xl flex items-center">
                      <TimeOfDayIcon time={moment(values.start.value)} />
                      <span className="ml-2">{moment(values.start.value).format("h:mma")}</span>
                    </div>
                  </>
                )}
              </div>
            <div className="rounded-b-lg">
              <SecondaryButton
                id="time-start-secondary-button"
                type="button"
                className="inline-block mr-4"
                onClick={props.onBackClick}
              >
                Back
              </SecondaryButton>
              <PrimaryButton
                id="time-start-primary-button"
                disabled={!values.start || errors.start}
                type="submit"
              >
                Next
              </PrimaryButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  </CenterContainer>
)


ShiftReportTimeStart.propTypes = {
  date: PropTypes.object.isRequired,
  start: PropTypes.string,
  handleStartNext: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired
}

export default ShiftReportTimeStart

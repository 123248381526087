import React, { useEffect } from 'react';
import AssessmentLayout from './AssessmentLayout';
import PulmonaryAssessmentForm from './PulmonaryAssessmentForm'

const PulmonaryAssessment = (props) => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  const redirectBack = () => {
    const { history, shiftReport } = props
    history.push(`/shift-report/clinical/v1/${shiftReport.id}/edit`);
  }

  return (
      <AssessmentLayout {...props}>
        <h3 id="specific-assessment-header" className="block text-gray-600 text-3xl mb-6">Assessment - Pulmonary</h3>
        <div className="bg-white justify-between rounded-lg shadow-md">
          <PulmonaryAssessmentForm
            onCancel={redirectBack}
            onContinue={() => null}
            onSubmit={props.updateAssessment}
            assessment={props.assessment}
          />
        </div>
      </AssessmentLayout>
  );
}


export default PulmonaryAssessment;

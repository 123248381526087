import React from 'react';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import Modal from '../../../../../components/Modal';
import SecondaryButton from "../../../../../components/SecondaryButton";
import { TextArea, InputWithCharsLeft } from '../../../../../components/form/Input';
import Error from '../../../../../components/form/Error';
import CenterContainer from '../../../../../components/layout/CenterContainer';
import PrimaryButton from "../../../../form/PrimaryButton";
import woundChart from '../../../../../assets/images/chart.png'
import {painAssessmentOptions, selectedOption} from '../../../../../utils/painAssessmentUtils'
import Magnifier from 'react-magnifier';

class PainAssessmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      painAssessment: props.painAssessment,
      error: null
    };
  };

  _handleSubmit = painAssessment => {
    this.props.onSubmit(painAssessment);
  }

  _validationSchema = () => {
    return Yup.object().shape({
      location: Yup.string().when('presentLevel', {
        is: (presentLevel) => presentLevel !== '0',
        then: Yup.string().required('Location is required'),
        otherwise: null,
      }),
      scale: Yup.string().when('presentLevel', {
        is: (presentLevel) => presentLevel !== '0',
        then: Yup.string().required('Scale is required'),
        otherwise: null,
      }),
      onset: Yup.string().when('presentLevel', {
        is: (presentLevel) => presentLevel !== '0',
        then: Yup.string().required('Onset is required'),
        otherwise: null,
      }),
      worstPainGets: Yup.string().when('presentLevel', {
        is: (presentLevel) => presentLevel !== '0',
        then: Yup.string().required('Worst pain gets is required'),
        otherwise: null,
      }),
      bestPainGets: Yup.string().when('presentLevel', {
        is: (presentLevel) => presentLevel !== '0',
        then: Yup.string().required('Best pain gets is required'),
        otherwise: null,
      }),
      presentLevel: Yup.string().required('Present Level is required'),
      description: Yup.string()
    })
  };

  _intialValues = () => {
    return {
      location: this.props.painAssessment.location ? selectedOption("location", this.props.painAssessment.location) : "",
      scale: this.props.painAssessment.scale ? selectedOption("scale", this.props.painAssessment.scale) : "",
      onset: this.props.painAssessment.onset ? this.props.painAssessment.onset : "",
      presentLevel: this.props.painAssessment.presentLevel ? this.props.painAssessment.presentLevel : "",
      worstPainGets: this.props.painAssessment.worstPainGets ? selectedOption("worstPainGets", this.props.painAssessment.worstPainGets) : "",
      bestPainGets: this.props.painAssessment.bestPainGets ? selectedOption("bestPainGets", this.props.painAssessment.bestPainGets) : "",
      description: this.props.painAssessment.description ? this.props.painAssessment.description : "",
    }
  }

  render = () => (
    <React.Fragment>
      <Modal
        isOpen={true}
      >
        <CenterContainer>
          <Formik
            validationSchema={this._validationSchema()}
            initialValues={this._intialValues()}
            onSubmit={values => {
              this._handleSubmit({
                ...values,
                location: values.location.value,
                scale: values.scale.value,
                presentLevel: values.presentLevel,
                worstPainGets: values.worstPainGets.value,
                bestPainGets: values.bestPainGets.value,
              });
            }}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue
              }) => (
              <form onSubmit={handleSubmit} className="max-w-6xl bg-white rounded-lg shadow-lg">
                <div className="flex p-8 pb-0 text-md">
                  <div className="w-full">
                    <h1 id="wound-care-heading" className="font-bold text-lg mb-4">Pain Assessment</h1>
                    <figure className="w-full h-full">
                      <Magnifier src={woundChart} width='100%' mgHeight={100} mgWidth={100}/>
                    </figure>
                  </div>
                  <div className="flex flex-col w-4/6">
                    <div className="flex">
                      <div className="m-3">
                        <label className="block mb-2">Location</label>
                        <Select
                          id="location"
                          name="location"
                          value={values.location}
                          options={painAssessmentOptions("location")}
                          placeholder="Select one..."
                          className="react-select-container--clinical w-48"
                          classNamePrefix="react-select"
                          onChange={(selected) => setFieldValue('location', selected)}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          />
                          {(errors.location && touched.location) ? <Error>{errors.location}</Error> : null}
                      </div>
                      <div className="m-3">
                        <label className="block mb-2">Scale</label>
                        <Select
                          id="scale"
                          name="scale"
                          value={values.scale}
                          onChange={(selected) => setFieldValue('scale', selected)}
                          options={painAssessmentOptions("scale")}
                          placeholder="Select one..."
                          className="react-select-container--clinical w-48"
                          classNamePrefix="react-select"
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          />
                          {(errors.scale && touched.scale) ? <Error>{errors.scale}</Error> : null}
                      </div>
                      <div className="m-3">
                        <label className="block mb-2">Onset</label>
                        <InputWithCharsLeft
                          id="onset"
                          name="onset"
                          value={values.onset}
                          maxLength={60}
                          onChange={handleChange}
                          className="react-select-container--clinical w-48"
                          />
                          {(errors.onset && touched.onset) ? <Error>{errors.onset}</Error> : null}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="m-3">
                        <label className="block mb-2">Present Level</label>
                        <Select
                          id="presentLevel"
                          name="presentLevel"
                          value={painAssessmentOptions("presentLevel").find(opt => opt.value === values.presentLevel)}
                          onChange={(selected) => setFieldValue('presentLevel', selected.value)}
                          options={painAssessmentOptions("presentLevel")}
                          placeholder="Select one..."
                          className="react-select-container--clinical w-48"
                          classNamePrefix="react-select"
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          />
                          {(errors.presentLevel && touched.presentLevel) ? <Error>{errors.presentLevel}</Error> : null}
                      </div>
                      <div className="m-3">
                        <label className="block mb-2">Worst pain gets</label>
                        <Select
                          id="worstPainGets"
                          name="worstPainGets"
                          value={values.worstPainGets}
                          onChange={(selected) => setFieldValue('worstPainGets', selected)}
                          options={painAssessmentOptions("worstPainGets")}
                          placeholder="Select one..."
                          className="react-select-container--clinical w-48"
                          classNamePrefix="react-select"
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          />
                          {(errors.worstPainGets && touched.worstPainGets) ? <Error>{errors.worstPainGets}</Error> : null}
                      </div>
                      <div className="m-3">
                        <label className="block mb-2">Best pain gets</label>
                        <Select
                          id="bestPainGets"
                          name="bestPainGets"
                          value={values.bestPainGets}
                          onChange={(selected) => setFieldValue('bestPainGets', selected)}
                          options={painAssessmentOptions("bestPainGets")}
                          placeholder="Select one..."
                          className="react-select-container--clinical w-48"
                          classNamePrefix="react-select"
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          />
                          {(errors.bestPainGets && touched.bestPainGets) ? <Error>{errors.bestPainGets}</Error> : null}
                      </div>
                    </div>
                    <div className="m-3 h-full pb-8">
                      <label className="block mb-2">Description</label>
                      <TextArea
                        id="description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        className="w-full h-full"                           
                        />
                        {(errors.description && touched.description) ? <Error>{errors.description}</Error> : null}
                    </div> 
                  </div>
                </div>
                <div className="p-3 px-8 text-right bg-gray-200 rounded-b-lg flex justify-end items-center">
                  <div>
                    <SecondaryButton id ="wound-care-secondary-button" className="inline-block mr-4" type="button" onClick={this.props.onCancel}>Cancel</SecondaryButton>
                    <PrimaryButton id="wound-care-next-button" type="submit" >Submit</PrimaryButton>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </CenterContainer>
      </Modal>
    </React.Fragment>
  );
}

export default PainAssessmentForm;

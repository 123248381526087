import React from 'react'
import moment from 'moment'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { incidentReportTypes } from '../constants'
import { timeOptions } from '../utils/timeUtils'
import { TextArea } from '../components/form/Input'
import Modal from '../components/Modal.js'
import Error from '../components/form/Error'
import TimeSelect from '../components/TimeSelect.js'
import SecondaryButton from '../components/SecondaryButton.js'
import SaveButton from './form/SaveButton.js'
import CenterContainer from '../components/layout/CenterContainer.js'
import Checkbox from './Checkbox'

const incidentReportValidations = incidentReport => {
  const notifications =[];
  if(!incidentReport.notified) {
    notifications.push(<span>You must indicate that you notified a supervisor</span>)
  }
  return notifications
}

class IncidentReportForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      incidentReport: props.incidentReport
    }
  }

  _handleSubmit = (incidentReport) => {
    if(incidentReportValidations(incidentReport).length > 0) {
      this.setState(incidentReport)
    } else {
      this.props.onSubmit(incidentReport)
    }
  }

  _initialTime = (time) => {
    const { start, end } = this.props
    if(time) {
      return timeOptions(start, end).find(option => {
        return time === option.value;
      });
    }

    return this._defaultTime();
  };

  _defaultTime = () => {
    const { start, end } = this.props
    return timeOptions(start, end).find(option => {
      const diff = moment.duration(moment(option.value).diff(moment())).asMinutes();
      return Math.abs(diff) < 7.5;
    });
  };

  _validationSchema = () => {
    return Yup.object().shape({
      recordedAt: Yup.object()
        .required('Recorded at is required.'),
      type: Yup.string()
        .required('Type is required.')
        .oneOf(Object.keys(incidentReportTypes), 'Must select a valid incident type.'),
      comment: Yup.string().required('Comments are required.'),
      notified: Yup.boolean()
        .required('Supervisor must be notified.')
        .oneOf([true], 'Supervisor must be notified.')
    })
  }

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={true}>
          <CenterContainer>
            <Formik
              validationSchema={this._validationSchema()}
              initialValues={{
                ...this.state.incidentReport,
                recordedAt: this._initialTime(this.state.incidentReport.recordedAt)
              }}
              onSubmit={values => {
                this._handleSubmit({
                  ...values,
                  recordedAt: values.recordedAt.value
                })
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit} className="w-1/2 max-w-6xl bg-white rounded-lg shadow-lg">
                  <h1 id="incident-report-heading" className="font-bold text-lg p-8 pb-0">Incident Report</h1>
                  <div className="flex flex-col mb-4 p-8 pt-2 pb-0">
                    <div className="my-2 w-full">
                      <label className="block mb-2">Recorded At</label>
                      <TimeSelect
                        id="recorded-at-select"
                        value={values.recordedAt}
                        options={timeOptions(this.props.start, this.props.end)}
                        placeholder="Choose a time..."
                        onChange={time => setFieldValue('recordedAt', time)}
                      />
                      {(errors.recordedAt && touched.recordedAt) ? <Error>{errors.recordedAt}</Error> : null}
                    </div>

                    <div className="flex flex-col my-2">
                      <h4 className="block mb-2">Incident Type</h4>
                      {Object.keys(incidentReportTypes).map((type, i) => (
                        <label key={i} className="cursor-pointer">
                          <input
                            type="radio"
                            value={type}
                            checked={values.type === type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="type"
                            className="form-check-input mr-2"
                          />
                          {incidentReportTypes[type]}
                        </label>
                      ))}
                      {(errors.type && touched.type) && <Error>{errors.type}</Error>}
                    </div>
                    <div className="my-2 w-full">
                      <label className="block mb-2">Comments</label>
                      <TextArea
                        id="comment-textarea"
                        type="textarea"
                        autoComplete="off"
                        name="comment"
                        onBlur={handleBlur}
                        value={values.comment}
                        onChange={handleChange}
                        placeholder="Comments..."
                        className="resize-none"
                      />
                      {(errors.comment && touched.comment) && <Error id="error-comment-validation">{errors.comment}</Error>}
                    </div>
                    <div className="my-2">
                      <button
                        id="notified-checkbox"
                        type="button"
                        onClick={() => { setFieldValue('notified', !values.notified)}}
                        className='flex items-center text-left w-full'
                      >
                        <Checkbox checked={values.notified} className="text-3xl" /> I contacted a case manager or 911 
                      </button>
                      {(errors.notified && touched.notified) && <Error id="error-notified-validation">{errors.notified}</Error>}
                    </div>
                  </div>
                  <div className="p-4 px-8 text-right bg-gray-100 rounded-b-lg">
                      <SecondaryButton id="incident-report-secondary-button" className="inline-block mr-4" type="button" onClick={this.props.onCancel}>Cancel</SecondaryButton>
                      <SaveButton id="incident-report-save-button" type="submit">Save</SaveButton>
                  </div>
                </form>
              )}
            </Formik>

          </CenterContainer>
        </Modal>
      </React.Fragment>
    )
  }
}

IncidentReportForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  incidentReport: PropTypes.object.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired
}

export default IncidentReportForm

import { schema } from 'normalizr';
import vital from './vital';
import personalCareTask from './personalCareTask';
import activity from './activity';
import incidentReport from './incidentReport';

const shiftReport = new schema.Entity('shiftReports', {
  vitals: [vital],
  personalCareTasks: [personalCareTask],
  activities: [activity],
  incidentReports: [incidentReport],
});

export default shiftReport;

import React from 'react'
import moment from 'moment'
import { Formik } from "formik";
import PropTypes from 'prop-types'
import * as Yup from "yup";
import Error from "./form/Error";
import CenterContainer from "./layout/CenterContainer";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./form/PrimaryButton";
import TimeOfDayIcon from "./TimeOfDayIcon";
import {
  minSinceMidnight,
  numHours,
  getDate,
} from "../utils/timeUtils";

const ShiftReportTimeComfirm = (props) => (
  <CenterContainer>
    <Formik
      initialValues={{
        start: props.start ? minSinceMidnight(props.start) : "",
        end: props.end ? minSinceMidnight(props.end) : "",
        hours: numHours(props.date, minSinceMidnight(props.start), minSinceMidnight(props.end)),
      }}
      validationSchema={Yup.object().shape({
        hours: Yup.number()
          .max(16, "Shifts can't be longer than 16 hours")
      })}
      onSubmit={(values) => {
        props.onSubmit({
          start: getDate(props.date, values.start).format(),
          end: getDate(props.date, values.end).format(),
        });
      }}
    >
      {({ values, errors, handleSubmit, setFieldValue, handleChange }) => (
        <form
          onSubmit={handleSubmit}
          className="w-1/2 bg-white shadow-lg border-lg rounded-lg"
        >
          <div className="p-8">
            <span className="font-bold text-lg">Create a new shift report</span>
            <p id="shift-time-confirm-heading" className="mb-6 text-gray-600">
              Confirm the shift start and end times
            </p>
            <div className="mb-10">
              <div className="flex justify-between">
                <div className="w-1/3">
                  <div>
                    <div className="text-sm text-gray-600">Start</div>
                    <div className="text-xl">
                    <TimeOfDayIcon time={moment(props.start)} />
                      <span className="font-bold ml-1">
                        {moment(props.start).format('h:mma')}
                      </span>
                    </div>
                    <div className="text-gray-600">
                      {getDate(props.date, values.start).format(
                        "ddd MMM D, YYYY"
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-1/3">
                  <div>
                    <div>
                      <div className="text-sm text-gray-600">End</div>
                      <div className="text-xl">
                        <TimeOfDayIcon time={moment(props.end)} />
                        <span className="font-bold ml-1">
                          {moment(props.end).format('h:mma')}
                        </span>
                      </div>
                    </div>
                    <div className="text-gray-600">
                      {getDate(props.date, values.end).format(
                        "ddd MMM D, YYYY"
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-1/3">
                  <div className="text-sm text-gray-600">Total</div>
                  <div
                    className={
                      numHours(props.date, values.start, values.end) > 16
                        ? "font-bold text-xl text-red-600"
                        : "font-bold text-xl"
                    }
                  >
                    {numHours(props.date, values.start, values.end)}
                  </div>
                  <div className="text-gray-600">Hours</div>
                </div>
              </div>
              {errors.hours && (
                <Error id="error-api-time" animate className="absolute">
                  {errors.hours}
                </Error>
              )}
            </div>
            <div className="mb-10">
            </div>
            <div className="border-l-4 border-blue-400">
              <p className="pl-4 leading-snug">
                <strong>Working an overnight shift?</strong>
                <br />
                If you're working overnight and into the next day, you will need
                to start a new note once the day changes. Please contact your
                case manager if you have additional questions.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between bg-gray-100 px-8 py-4">
              <div className="text-gray-600">
                <div className="uppercase text-sm">{getDate(props.date, values.start).format("ll")}</div>
                <div className="text-xl">
                  <TimeOfDayIcon time={moment(props.start)} />
                  <span className="ml-2 inline-block">{getDate(props.date, values.start).format("h:mma")}</span>
                  <span className="mx-1">&#45;</span>
                  <TimeOfDayIcon time={moment(props.end)} />
                  <span className="ml-2 inline-block">{getDate(props.date, values.end).format("h:mma")}</span>
                </div>
              </div>
            <div className="rounded-b-lg">
              <SecondaryButton
                id="time-confirm-secondary-button"
                type="button"
                className="inline-block mr-4"
                onClick={props.onBackClick}
              >
                Back
              </SecondaryButton>
              <PrimaryButton
                id="time-confirm-primary-button"
                disabled={props.disabled}
                type="submit"
              >
                Create
              </PrimaryButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  </CenterContainer>
)

ShiftReportTimeComfirm.propTypes = {
  date: PropTypes.object.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired
}

export default ShiftReportTimeComfirm
import React, { useEffect } from 'react';
import AssessmentLayout from './AssessmentLayout';
import DigestiveAssessmentForm from './DigestiveAssessmentForm';

const DigestiveAssessment = (props) => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  const redirectBack = () => {
    const { history, shiftReport } = props
    history.push(`/shift-report/clinical/v1/${shiftReport.id}/edit`);
  }

  const redirectToNeuroAssessment = () => {
    props.history.push(`/shift-report/clinical/v1/${props.shiftReport.id}/assessment/neuro`);
  }

  return (
    <AssessmentLayout {...props}>
      <h3 id="specific-assessment-header" className="block text-gray-600 text-3xl mb-6">Assessment - Digestive</h3>
      <div className="bg-white justify-between rounded-lg shadow-md">
        <DigestiveAssessmentForm
          onCancel={redirectBack}
          onContinue={redirectToNeuroAssessment}
          onSubmit={props.updateAssessment}
          assessment={props.assessment} />
      </div>
    </AssessmentLayout>
  );
}


export default DigestiveAssessment;

import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import PinInput from "react-pin-input";
import {
  updateSettings,
  fetchClientIfNeeded,
  fetchShiftReportsByClientId,
} from "../actions";
import Component from "../components/Settings";
import Error from "../components/Error";
import FormError from "../components/form/Error";
import Success from "../components/Success";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import SecondaryButton from "../components/SecondaryButton";
import PrimaryButton from "../components/form/PrimaryButton";
import Dialog from "../components/dialog/Dialog";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: null,
      success: true,
      error: null,
      pinError: null,
      loading: false,
      isAuthorized: false,
      value: "",
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.settings.lastUpdated !== prevProps.settings.lastUpdated) {
      this.props.history.push(`/`);
    }
  };

  dismissError = () => {
    this.setState({
      error: null,
      pinError: null,
    });
  };

  dismissSuccess = () => {
    this.setState({
      success: null,
    });
  };

  toggleLoading = (loading) => {
    this.setState({
      loading,
    });
  };

  isValid = async (settings) => {
    this.toggleLoading(true);
    try {
      const res = await fetch(
        `${settings.apiEndpoint}/clients/${settings.clientId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-AUTH-TOKEN": settings.apiKey,
          },
        }
      );
      if (res.status === 404) {
        this.setState({
          error:
            "We could not find a client using the client id provided. Please double-check the client id.",
        });
      } else if (res.status === 403) {
        this.setState({
          error: "A valid API Key is required.",
        });
      } else if (res.status === 200 || res.status === 304) {
        return true;
      }
    } catch (err) {
      this.setState({
        error: "Unable to connect to the server.",
      });
    }
    this.toggleLoading(false);
    return false;
  };

  _handleCancelClick = () => {
    this.props.history.goBack();
  };

  handleUpdateSettings = async (settings) => {
    this.dismissError();
    if (await this.isValid(settings)) {
      this.props.fetchShiftReportsByClientId(settings.clientId);
      this.props.onUpdateSettings(settings);
    }
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  onSubmit = () => {
    if (this.state.value === process.env.REACT_APP_SECURITY_CODE) {
      this.setState({
        isAuthorized: true,
      });
    } else {
      this.setState({
        pinError: "The PIN you entered was incorrect. Please try again.",
      });
    }
  };

  onCancel = () => {
    this.props.history.push("/#");
  };

  render = () => (
    <React.Fragment>
      {this.state.error && (
        <Error
          id="error-settings-validation"
          onDismiss={() => this.dismissError()}
        >
          {this.state.error}
        </Error>
      )}
      <Route path="/settings/application-data-reset">
        {this.state.success && (
          <Success onDismiss={() => this.dismissSuccess()}>
            Application data reset successfully!
          </Success>
        )}
      </Route>
      {this.props.isFetching && <Loader>Updating client information...</Loader>}
      {!this.state.isAuthorized ? (
        <Modal isOpen={!this.state.isAuthorized}>
          <Dialog
            id="settings-dialog"
            title="Authorization required"
            subTitle="Please enter the 4-digit PIN to continue."
            footer={() => (
              <>
                <SecondaryButton
                  id="auth-cancel-button"
                  className="inline-block mr-4"
                  onClick={this.onCancel}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton onClick={this.onSubmit}>Continue</PrimaryButton>
              </>
            )}
          >
            <div className="pb-8">
              <PinInput
                length={4}
                focus
                onChange={this.handleChange}
                type="numeric"
                ref={(p) => (this.pin = p)}
                className="pincode-input-container"
                inputFocusStyle={{ borderColor: "blue" }}
                onComplete={this.onSubmit}
              />
              {this.state.pinError && (
                <FormError
                  className="pl-8 mt-2"
                  id="pin-error"
                  onDismiss={() => this.dismissError()}
                >
                  {this.state.pinError}
                </FormError>
              )}
            </div>
          </Dialog>
        </Modal>
      ) : (
        <Component
          {...this.props}
          onUpdateSettings={this.handleUpdateSettings}
          onCancelClick={this._handleCancelClick}
        />
      )}

      {this.state.loading && <Loader>Verifying settings...</Loader>}
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onUpdateSettings: (settings) => {
    dispatch(updateSettings(settings));
  },
  fetchClientIfNeeded: (id) => {
    dispatch(fetchClientIfNeeded(id));
  },
  fetchShiftReportsByClientId: (id) => {
    dispatch(fetchShiftReportsByClientId(id));
  },
});

const mapStateToProps = (state) => ({
  settings: state.settings,
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Settings);

export default Container;

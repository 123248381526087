import React from 'react'
import { useDispatch } from 'react-redux'
import { resetApplicationData } from '../actions'
import ResetComponent from '../components/Reset'

const Reset = props => {
  const dispatch = useDispatch()
  const resetAndRedirect = () => {
    dispatch(resetApplicationData())
    props.history.push('/settings/application-data-reset')
  }

  return <ResetComponent
    onCancelClick={() => props.history.push('/client')}
    onSubmit={() => resetAndRedirect()}
  />
}

export default Reset
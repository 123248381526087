import React from 'react';
import moment from 'moment'
import { connect } from 'react-redux';
import { updateCCN, createCCN } from '../../../../actions';
import { getClinicalContinuationNotes } from '../../../../selectors/clinicalContinuationNotes';
import Component from '../../../../components/shiftReports/clinical/v1/ClinicalContinuationNotes';
import ClinicalContinuationNoteForm from '../../../../components/shiftReports/clinical/v1/ClinicalContinuationNoteForm.js';

class ClinicalContinuationNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      isEditing: false,
      curNoteId: null,
      curTime: null
    };
  };

  _curNote = () => {
    return this.props.clinicalContinuationNotes.find(obj => obj.id === this.state.curNoteId);
  }

  _handleAdd = (time) => {
    this.setState({
      isAdding: true,
      curTime: moment(time).format()
    });
  };

  _handleSubmitAdd = ccn => {
    this.props.createCCN({
      shiftReportId: this.props.shiftReport.id,
      ...ccn
    });
    this.setState({
      isAdding: false,
    });
  };

  _handleSubmitEdit = ccn => {
    this.props.updateCCN({
      ...this._curNote(),
      ...ccn
    });
    this.setState({
      isEditing: false,
      curNoteId: null,
    });
  };

  _handleCancelEdit = () => {
    this.setState({
      isEditing: false,
      curNoteId: null,
    });
  };

  _handleCancelAdd = () => {
    this.setState({
      isAdding: false,
    });
  };

  _handleEdit = id => {
    this.setState({
      isEditing: true,
      curNoteId: id,
    });
  };

  render = () => (
    <>
      {this.state.isAdding && (
        <ClinicalContinuationNoteForm
          onCancel={this._handleCancelAdd}
          onSave={this._handleSubmitAdd}
          time={this.state.curTime}
          note={{body: ''}}
        />
     )}
      {this.state.isEditing && (
        <ClinicalContinuationNoteForm
          onCancel={this._handleCancelEdit}
          onSave={this._handleSubmitEdit}
          time={this._curNote().recordedAt}
          note={this._curNote()}
        />
      )}
      <Component
        onAdd={this._handleAdd}
        onEdit={this._handleEdit}
        shiftReport={this.props.shiftReport}
        clinicalContinuationNotes={this.props.clinicalContinuationNotes}
      />
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  updateCCN: ccn => dispatch(updateCCN(ccn)),
  createCCN: ccn => dispatch(createCCN(ccn))
});

const mapStateToProps = (state, ownProps) => ({
  clinicalContinuationNotes: getClinicalContinuationNotes(state, ownProps),
  ...ownProps
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalContinuationNotes);

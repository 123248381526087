import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers';
import { loadState, saveState } from '../storage/localStorage';
import createSagaMiddleware from 'redux-saga';
import clientSaga from '../sagas/client';
import shiftReportSaga from '../sagas/shiftReport';

const sagaMiddleware = createSagaMiddleware();

const persistedState = loadState();
const composeEnhancers = process.env.NODE_ENV === "development" ? 
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : 
  compose;

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(
    applyMiddleware(sagaMiddleware)
  )
);

sagaMiddleware.run(clientSaga);
sagaMiddleware.run(shiftReportSaga);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;

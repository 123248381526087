import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Error from './form/Error';
import Input from './form/Input';
import Label from './form/Label';
import Row from './form/Row';
import SaveButton from './form/SaveButton';
import SecondaryButton from './SecondaryButton';
import CenterContainer from './layout/CenterContainer';

const Settings = props => (
  <CenterContainer>
    <div className="bg-white shadow-lg rounded-lg w-1/2">
      <Formik
          initialValues={props.settings}
          onSubmit={values => {
            props.onUpdateSettings(values);
          }}
          validationSchema={Yup.object().shape({
            apiEndpoint: Yup.string()
              .required('A valid Api Endpoint is required.'),
            apiKey: Yup.string()
              .required('A valid Api Key is required.'),
            clientId: Yup.string()
              .required('A valid Client Id is required.'),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-8 rounded-t-lg">
                <h1 id="dialog-heading" className="font-bold text-gray-800 text-lg">Settings</h1>
                <h2 className="text-gray-600 mb-8">Electronic Charting</h2>
                <Row className="mb-6">
                  <Label htmlFor="input-api-endpoint">API Endpoint</Label>
                  <Input type="text" name="apiEndpoint" id="input-api-endpoint" onChange={handleChange} onBlur={handleBlur} value={values.apiEndpoint} error={errors.apiEndpoint && touched.apiEndpoint} />
                  {errors.apiEndpoint && touched.apiEndpoint ? (
                    <Error id="error-api-endpoint">{errors.apiEndpoint}</Error>
                  ) : null}
                </Row>
                <Row className="mb-6">
                  <Label htmlFor="input-api-key">API Key</Label>
                  <Input type="text" name="apiKey" id="input-api-key" onChange={handleChange} onBlur={handleBlur} value={values.apiKey} error={errors.apiKey && touched.apiKey} />
                  {errors.apiKey && touched.apiKey ? (
                    <Error id="error-api-key">{errors.apiKey}</Error>
                  ) : null}
                </Row>
                <Row>
                  <Label htmlFor="input-client-id">Client Id</Label>
                  <Input type="text" name="clientId" id="input-client-id" onChange={handleChange} onBlur={handleBlur} value={values.clientId} error={errors.clientId && touched.clientId} />
                  {errors.clientId && touched.clientId ? (
                    <Error id="error-client-id">{errors.clientId}</Error>
                  ) : null}
                </Row>
              </div>
              <div className="bg-gray-100 text-right p-4 px-8 rounded-b-lg">
                <SecondaryButton className="mr-4" onClick={props.onCancelClick} type="button">Cancel</SecondaryButton>
                <SaveButton id="settings-submit-button" type="submit" disabled={Object.keys(errors).length > 0}>Save</SaveButton>
              </div>
            </form>
          )}
        </Formik>
    </div>
  </CenterContainer>
);

export default Settings;
import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  SHIFT_REPORT_STATUS_FAILED,
  SHIFT_REPORT_STATUS_SUBMITTED,
  SHIFT_REPORT_STATUS_OPEN,
  SHIFT_REPORT_STATUS_PENDING,
  SHIFT_REPORT_STATUS_FAILED_NETWORK_CONNECTIVITY
} from '../constants'
import { employeeName } from '../utils/employees'
import Container from './layout/Container'
import Header from './Header'
import PropTypes from 'prop-types'
import { renderMidnight } from '../utils/timeUtils.js'
import {shiftReportTypes} from '../utils/shiftTypes'

const getEmployeeName = (id, employees) => {
  const employee = employees.find(e => e.id === id)
  return employeeName(employee)
}

const buildShiftTimeRange = (shiftReport) => {
  const start = moment(shiftReport.start).format('H:mm')
  const endTime = renderMidnight(shiftReport.end)

  return `${start} - ${endTime}`
}

const Client = props => {
  const renderActionButtons = (shiftReport) => {
    if(shiftReport.status === SHIFT_REPORT_STATUS_OPEN && shiftReport.userId === props.user.id) {
      return (
        <React.Fragment>
          <button type="button" onClick={() => props.onDeleteShiftReport(shiftReport.id)} className="bg-gray-300 text-gray-700 p-3 px-4 rounded-full mr-3 inline-block whitespace-no-wrap">
            <i className="fas fa-trash inline-block mr-1" /> Delete
          </button>
          <Link to={`/shift-report/${shiftReportTypes(shiftReport.type)}/v${shiftReport.version}/${shiftReport.id}/edit`} className="bg-blue-600 text-gray-100 p-3 px-4 rounded-full inline-block">
            <i className="fas fa-pencil inline-block mr-1" /> Edit
          </Link>
        </React.Fragment>
      )
    } else {
      return shiftReport.type === 'hha' && (
        <React.Fragment>
          <Link to={`/shift-report/${shiftReportTypes(shiftReport.type)}/v${shiftReport.version}/${shiftReport.id}/view`} className="bg-blue-600 text-gray-100 p-3 px-4 rounded-full inline-block">
            <i className="fas fa-eye inline-block mr-1" /> View
          </Link>
        </React.Fragment>
      )
    }
  }

  return (
    <Container>
      <Header client={props.client} user={props.user} />
      <div className="flex p-8">
        <div className="w-1/3 pr-8">
          <h2 className="text-2xl mb-3">Approved Diagnoses</h2>
          <div className="list--rounded bg-white rounded-lg mb-8 text-gray-700 shadow-md">
            {props.client.approvedDiagnoses.map((diagnosis,i) => (
              <div className={i%2 === 0 ? 'bg-gray-100 p-4' : 'p-4'} key={diagnosis}>{diagnosis}</div>
            ))}
          </div>
          <h2 className="text-2xl mb-3">Allergies</h2>
          <div className="list--rounded bg-white rounded-lg text-gray-700 shadow-md">
            {props.client.allergies.map((allergy,i) => (
              <div className={i%2 === 0 ? 'bg-gray-100 p-4' : 'p-4'} key={allergy}>{allergy}</div>
            ))}
          </div>
        </div>
        <div className="w-2/3">
          <div className="flex justify-between mb-3">
            <h2 id="shift-reports-header" className="text-2xl">My Shift Reports</h2>
            <Link to="/shift-report/new" id="new-shift-report" className="block self-center text-blue-600 font-semibold">New Shift Report <i className="inline-block ml-1 fas fa-chevron-right" /></Link>
          </div>
          {props.userShiftReports.length === 0 && (
            <table className="text-left w-full bg-white rounded-lg mb-8 shadow-md table--rounded">
              <thead className="bg-gray-100 text-gray-600">
              <tr>
                <th className="text-sm p-3 px-4 rounded-tl-lg">
                  DATE
                </th>
                <th className="text-sm p-3 px-4">
                  STATUS
                </th>
                <th className="text-sm p-3 px-4">
                  AUTHOR
                </th>
                <th className="rounded-tr-lg"></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td colSpan="10" className="text-center py-4">
                  <Link to="/shift-report/new" className="inline-block cursor-pointer bg-blue-600 p-3 px-6 text-white font-semibold rounded-full"><i className="fas fa-plus-circle inline-block mr-1"></i> New shift report</Link>
                </td>
              </tr>
              </tbody>
            </table>
          )}
          {props.userShiftReports.length > 0 && (
            <>
              <table className="text-left w-full bg-white rounded-lg mb-8 shadow-md table--rounded">
                <thead className="bg-gray-100 text-gray-600">
                  <tr>
                    <th className="text-sm p-3 px-4 rounded-tl-lg">
                      DATE
                    </th>
                    <th className="text-sm p-3 px-4">
                      STATUS
                    </th>
                    <th className="text-sm p-3 px-4">
                      AUTHOR
                    </th>
                    <th className="rounded-tr-lg"></th>
                  </tr>
                </thead>
                <tbody id="shift-reports-table">
                {props.userShiftReports.map((shiftReport, i) => {
                  return (
                    <tr key={shiftReport.id} className={i % 2 === 1 ? 'bg-gray-100' : ''}>
                      <td className="p-3 px-4 leading-snug">
                        <div>
                          {moment(shiftReport.start).format('ddd MMM D, YYYY')}
                        </div>
                        <div className="text-sm text-gray-600">
                          {buildShiftTimeRange(shiftReport)}
                        </div>
                      </td>
                      <td className="p-3 px-4 align-top">
                        {(shiftReport.status === SHIFT_REPORT_STATUS_FAILED || shiftReport.status === SHIFT_REPORT_STATUS_FAILED_NETWORK_CONNECTIVITY) && (
                          <>
                            <i className="fas fa-exclamation-circle inline-block text-red-600" /> Failed to submit.&nbsp;
                            <span id="client-resubmit-button" onClick={() => props.reSubmitShiftReport(shiftReport)} className="text-blue-600 cursor-pointer p-0">Click here to try again.</span>
                            <div className="text-sm text-gray-600">
                              {shiftReport.status === SHIFT_REPORT_STATUS_FAILED_NETWORK_CONNECTIVITY && (<>Unable to connect to the network.  Please contact your case manager.</>)}
                              {shiftReport.status === SHIFT_REPORT_STATUS_FAILED && (<>If you are unable to submit the shift report, please contact your case manager.</>)}
                            </div>
                        </>
                        )}
                        {shiftReport.status === SHIFT_REPORT_STATUS_PENDING && (
                          <>
                            <i className="text-blue-400 fas fa-spinner fa-spin"/>&nbsp; Submitting...
                          </>
                        )}

                        {shiftReport.status === SHIFT_REPORT_STATUS_SUBMITTED && (
                          <React.Fragment>
                            Submitted
                            <div className="text-sm text-gray-600">
                              {moment(shiftReport.submittedAt).format('ddd MMM D, YYYY')}
                            </div>
                          </React.Fragment>
                        )}
                        {shiftReport.status === SHIFT_REPORT_STATUS_OPEN && (
                          <React.Fragment>
                            Open
                          </React.Fragment>
                        )}
                      </td>
                      <td className="p-3 px-4 align-top">
                        {getEmployeeName(shiftReport.userId, props.employees)}
                      </td>
                      <td className="text-right p-3 px-4">
                        {renderActionButtons(shiftReport)}
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
              {props.pages.length > 1 && (
                <nav>
                  <ul>
                    <li className="inline-block mr-2 leading-tight">
                      <Link className="inline-block p-4 bg-white rounded-lg" to={`/client/${props.prev}`}><i className="far fa-chevron-left"></i></Link>
                    </li>
                    {props.pages.map(page => (
                      <li key={page} className="inline-block mr-2 leading-tight">
                        <Link className={page === props.page ? "inline-block bg-blue-200 font-bold p-4 bg-white rounded-lg" : "inline-block p-4 bg-white rounded-lg"} to={`/client/${page}`}>{page}</Link>
                      </li>
                    ))}
                    <li className="inline-block mr-2 leading-tight">
                      <Link className="inline-block p-4 bg-white rounded-lg" to={`/client/${props.next}`}><i className="far fa-chevron-right"></i></Link>
                    </li>
                  </ul>
                </nav>
              )}
            </>
          )}
          { props.submittedShiftReports.length > 0 &&
          <>
            <div className="flex justify-between mb-3">
              <h2 className="text-2xl">Client History</h2>
            </div>
            <table className="text-left w-full bg-white rounded-lg mb-4 shadow-md table--rounded">
              <thead className="bg-gray-100 text-gray-600">
              <tr>
                <th className="text-sm p-3 px-4 rounded-tl-lg">
                  DATE
                </th>
                <th className="text-sm p-3 px-4">
                  AUTHOR
                </th>
                <th className="rounded-tr-lg"></th>
              </tr>
              </thead>
              <tbody id="shift-reports-submitted-table">
              {props.submittedShiftReports.map((shiftReport, i) => {
                return (
                  <tr key={shiftReport.id} className={i % 2 === 1 ? 'bg-gray-100' : ''}>
                    <td className="p-3 px-4 leading-snug">
                      <div>
                        {moment(shiftReport.start).format('ddd MMM D, YYYY')}
                      </div>
                      <div className="text-sm text-gray-600">
                        {buildShiftTimeRange(shiftReport)}
                      </div>
                    </td>
                    <td className="p-3 px-4 align-top">
                      {getEmployeeName(shiftReport.userId, props.employees)}
                    </td>
                    <td className="text-right p-3 px-4">
                      {renderActionButtons(shiftReport)}
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
            {props.pages.length > 1 && (
              <nav>
                <ul>
                  <li className="inline-block mr-2 leading-tight">
                    <Link className="inline-block p-4 bg-white rounded-lg" to={`/client/${props.prev}`}><i className="far fa-chevron-left"></i></Link>
                  </li>
                  {props.pages.map(page => (
                    <li key={page} className="inline-block mr-2 leading-tight">
                      <Link className={page === props.page ? "inline-block bg-blue-200 font-bold p-4 bg-white rounded-lg" : "inline-block p-4 bg-white rounded-lg"} to={`/client/${page}`}>{page}</Link>
                    </li>
                  ))}
                  <li className="inline-block mr-2 leading-tight">
                    <Link className="inline-block p-4 bg-white rounded-lg" to={`/client/${props.next}`}><i className="far fa-chevron-right"></i></Link>
                  </li>
                </ul>
              </nav>
            )}
          </>
          }
        </div>
      </div>
    </Container>
  )
}

Client.propTypes = {
  client: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userShiftReports: PropTypes.array.isRequired,
  submittedShiftReports: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  prev: PropTypes.number.isRequired,
  next: PropTypes.number.isRequired
}

export default Client;

import React from 'react';
import Container from './Container';

const CenterContainer = props => (
  <Container>
    <div className="h-screen flex items-center justify-center w-full">
      {props.children}
    </div>
  </Container>
);

export default CenterContainer;
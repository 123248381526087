import React from "react";
import { Formik } from "formik";
import { Range } from "rc-slider";
import * as Yup from "yup";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";
import Error from "./form/Error";
import CenterContainer from "./layout/CenterContainer";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./form/PrimaryButton";
import Modal from "./Modal";
import {
  numHours,
  getDate,
  getTime,
  toHours,
  toMinutes,
} from "../utils/timeUtils";

const getMinutes = (time) => {
  const parsedTime = moment(time);
  return parsedTime.hours() * 60 + parsedTime.minutes();
};

const setInitialValues = (date, start, end) => {
  const startMinutes = getMinutes(start);
  let endMinutes = getMinutes(end);

  if (startMinutes > 0 && endMinutes === 0) {
    endMinutes = 1440;
  }

  return {
    start: startMinutes,
    end: endMinutes,
    date: numHours(date, start, end),
  };
};

const ShiftReportEndTime = (props) => (
  <Modal isOpen={props.isOpen}>
    <CenterContainer>
      <Formik
        initialValues={setInitialValues(props.date, props.start, props.end)}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          hours: Yup.number().max(16, "Shifts can't be longer than 16 hours"),
        })}
        onSubmit={(values) => {
          props.onSubmit({
            end: getDate(props.date, values.end).format(),
          });
        }}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => (
          <form
            onSubmit={handleSubmit}
            className="w-1/2 max-w-6xl bg-white rounded-lg shadow-lg"
          >
            <div className="p-8">
              <span id="edit-shift-end-heading" className="font-bold text-lg">
                Edit shift end time
              </span>
              <p className="mb-6 text-gray-600">
                Use the slider below to change the shift end time.
              </p>
              <div className="mb-10">
                <div className="flex justify-between">
                  <div className="w-1/3">
                    <div>
                      <div className="text-sm text-gray-600">Start</div>
                      <div className="text-xl font-bold">
                        {getTime(
                          toHours(values.start),
                          toMinutes(values.start),
                          "start"
                        )}
                      </div>
                      <div className="text-gray-600">
                        {getDate(props.date, values.start).format(
                          "ddd MMM D, YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/3">
                    <div>
                      <div>
                        <div className="text-sm text-gray-600">End</div>
                        <div className="text-xl font-bold">
                          {getTime(
                            toHours(values.end),
                            toMinutes(values.end),
                            "end"
                          )}
                        </div>
                      </div>
                      <div className="text-gray-600">
                        {getDate(props.date, values.end).format(
                          "ddd MMM D, YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/3">
                    <div className="text-sm text-gray-600">Total</div>
                    <div
                      className={
                        numHours(props.date, values.start, values.end) > 16
                          ? "font-bold text-xl text-red-600"
                          : "font-bold text-xl"
                      }
                    >
                      {numHours(props.date, values.start, values.end)}
                    </div>
                    <div className="text-gray-600">Hours</div>
                  </div>
                </div>
                {errors.hours && (
                  <Error animate className="absolute">
                    {errors.hours}
                  </Error>
                )}
              </div>
              <div className="mb-10">
                <Range
                  min={getMinutes(props.start) + 15}
                  step={15}
                  max={1440}
                  defaultValue={[values.end]}
                  onChange={(v) => {
                    setFieldValue("end", v[0]);
                    setFieldValue(
                      "hours",
                      numHours(props.date, props.start, v[0])
                    );
                  }}
                />
              </div>
            </div>
            <div className="text-right bg-gray-100 p-4 rounded-b-lg">
              <SecondaryButton
                id="end-time-secondary-button"
                type="button"
                className="inline-block mr-4"
                onClick={props.onBackClick}
              >
                Back
              </SecondaryButton>
              <PrimaryButton
                id="end-time-primary-button"
                disabled={props.disabled}
                type="submit"
              >
                Save
              </PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </CenterContainer>
  </Modal>
);

export default ShiftReportEndTime;

import React from 'react'
import classnames from 'classnames'
import Checkbox from '../Checkbox'

const activeClass = "border border-green-600 bg-green-100 text-green-600"
const passiveClass= "border border-gray-300 bg-gray-100 text-gray-800"
const disabledClass="border border-gray-900 text-gray-800 bg-gray-400"

const FormCheckbox = ({checked, onClick, className, children, id, disabled}) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className='flex items-center text-left focus:outline-none'
    id={id}
  >
    <div className={checked ?
      classnames(className, activeClass) : disabled ?
      classnames(className, disabledClass) :
      classnames(className, passiveClass)
    }>
      <Checkbox checked={checked} disabled={disabled} className="text-2xl" /> {children}
    </div>
  </button>
)

export default FormCheckbox

import React from 'react'
import { Link } from 'react-router-dom'
import { shiftReportTypes } from '../../../../utils/shiftTypes'
import Header from '../../../Header'
import Container from '../../../layout/Container'
import Vitals from '../../../../containers/Vitals'
import ClinicalContinuationNotes from '../../../../containers/shiftReports/clinical/v1/ClinicalContinuationNotes'
import PrimaryButton from '../../../../components/form/PrimaryButton'
import ShiftReportHeader from '../../../ShiftReportHeader'
import AssessmentTable from './AssessmentTable'
import Intake from '../../../../containers/shiftReports/clinical/v1/intake'
import Output from '../../../../containers/shiftReports/clinical/v1/output'
import WoundCares from '../../../../containers/shiftReports/clinical/v1/woundCares'
import PainAssessment from '../../../../containers/shiftReports/clinical/v1/painAssessment'
import {
  useBasicMemo,
  useDigestiveMemo,
  useCardioMemo,
  useNeuroMemo,
  usePulmonaryMemo
} from '../../../customHooks/assessmentHooks'

const ShiftReport = (props) => {
  const shiftReportPath = `/shift-report/${shiftReportTypes(props.shiftReport.type)}/v${props.shiftReport.version}/${props.shiftReport.id}`;

  const basicAssessments = useBasicMemo(props.assessment, shiftReportPath)
  const digestiveAssessments = useDigestiveMemo(props.assessment, shiftReportPath)
  const cardioAssessments = useCardioMemo(props.assessment, shiftReportPath)
  const neuroAssessments = useNeuroMemo(props.assessment, shiftReportPath)
  const pulmonaryAssessments = usePulmonaryMemo(props.assessment, shiftReportPath)

  return (
    <Container>
      <Header client={props.client} user={props.user} />
      <div className="p-8">
        <ShiftReportHeader
          shiftReport={props.shiftReport}
          author={props.user}
          onEditEndTime={props.onEditEndTime}
        >
          <Link to="/client" className="cursor-pointer bg-gray-400 p-3 px-6 text-gray-800 mr-2 inline-block font-semibold rounded-full">
            <i className="fas fa-save inline-block mr-2" /> Save and Exit
          </Link>
          <PrimaryButton id="report-submit-button" onClick={props.onSubmit}><i className="fas fa-file-upload mr-2 inline-block" /> Submit Shift Report</PrimaryButton>
        </ShiftReportHeader>
        <h3 id="assessment-header" className="block text-gray-600 text-3xl mb-6">Assessment</h3>
        <div className="flex flex-wrap">
          <AssessmentTable
            assessments={basicAssessments}
            assessmentGroup='Basic'
          />
          <AssessmentTable
            assessments={digestiveAssessments}
            assessmentGroup='Digestive'
          />
          <AssessmentTable
            assessments={neuroAssessments}
            assessmentGroup='Neurological'
          />
          <AssessmentTable
            assessments={cardioAssessments}
            assessmentGroup='Cardiovascular'
          />
          <AssessmentTable
            assessments={pulmonaryAssessments}
            assessmentGroup='Pulmonary'
          />
        </div>
        <div className="flex">
          <div className="block w-1/3 m-1 my-2 mr-4">
          <h3 id="intake-header" className="block text-gray-600 text-3xl my-6">Intake</h3>
            <Intake {...props} />
          </div>
          <div className="block w-1/3 h-full m-1 my-2 ml-4">
          <h3 id="output-header" className="block text-gray-600 text-3xl my-6">Output</h3>
            <Output {...props} />
          </div>
        </div>
        <h1 className="text-2xl mb-4">Nursing Notes</h1>
        <div className="mb-8 flex flex-wrap">
          <ClinicalContinuationNotes shiftReport={props.shiftReport} />
        </div>
        <h1 className="text-2xl mb-4">Vitals</h1>
        <div className="mb-8 flex flex-wrap">
          <Vitals shiftReport={props.shiftReport} />
        </div>
        <h1 className="text-2xl mb-4">Wound Care</h1>
        <div className="mb-8 flex flex-wrap">
          <WoundCares shiftReport={props.shiftReport} />
        </div>
        <h1 className="text-2xl mb-4">Pain Assessment</h1>
        <div className="mb-8 flex flex-wrap">
        <PainAssessment shiftReport={props.shiftReport} />
        </div>

      </div>
    </Container>
  )
}

export default ShiftReport;

import React from 'react';
import { Formik } from 'formik';
import { DayPickerSingleDateController } from 'react-dates';
import * as Yup from 'yup';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './form/PrimaryButton';
import CenterContainer from './layout/CenterContainer';

const ShiftReportDate = props => (
  <CenterContainer>
    <div className="bg-white rounded-lg shadow-lg w-1/2">
      <div className="p-8">
        <span className="block font-bold text-lg">Create a new shift report</span>
        <p id="select-date-heading" className="text-gray-600">
          Select the shift date
        </p>
      </div>
      <Formik
        initialValues={{
          date: props.date,
        }}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          date: Yup.date().default(function() {
            return new Date();
          })
        })}
        onSubmit={(values) => {
          props.onSubmit(values.date);
        }}
      >
        {({
            values,
            errors,
            handleSubmit,
            setFieldValue
          }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center mb-8">
              <DayPickerSingleDateController
                date={values.date}
                onDateChange={date => setFieldValue('date', date)}
                focused
              />
            </div>
            {errors && (errors.date)}
            <div className="text-right bg-gray-100 p-4 rounded-b-lg">
              <SecondaryButton id="date-secondary-button" type="button" className="inline-block mr-4" onClick={props.onBackClick}>Back</SecondaryButton>
              <PrimaryButton id="date-primary-button" type="submit">Next</PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  </CenterContainer>
);

export default ShiftReportDate;

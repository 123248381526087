import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom'
import Header from '../../../Header';
import { personalCareTaskHints, activities, activityLabels, personalCareTasks, personalCareTaskLabels } from '../../../../constants';
import Container from '../../../layout/Container';
import VitalTable from '../../../../components/VitalTable';
import IncidentReportTable from '../../../../components/IncidentReportTable'
import ShiftReportHeader from '../../../ShiftReportHeader';
import PersonalCareTaskWidget from '../../../PersonalCareTaskWidget';
import ActivityWidget from '../../../ActivityWidget';

const ShiftReportReadOnly = props => (
  <Container>
    <Header client={props.client} user={props.user} />
    <div className="p-8">
      <ShiftReportHeader readOnly shiftReport={props.shiftReport} author={props.shiftReportUser}>
        <Link to="/client" className="cursor-pointer bg-blue-600 p-3 px-6 text-white font-semibold rounded-full">
          <i className="fas fa-arrow-circle-left inline-block mr-2" /> Return to Dashboard
        </Link>
      </ShiftReportHeader>
      <h1 className="text-2xl mb-4">Personal Care Tasks</h1>
      <table className="w-full mb-8 table--rounded shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-100">
            <th></th>
            {props.times.map(time => (
              <th className="pr-4 pb-4 pt-4" key={time}>
                {moment(time).format('H:mm')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {personalCareTasks.map((type, i) => (
          <tr className={i % 2 === 0 ? 'bg-white' : 'bg-gray-100'} key={i}>
            <td className="p-4 leading-tight w-64">
              <span className="block">{personalCareTaskLabels[type]}</span>
              {personalCareTaskHints[type] !== undefined && (<span className="text-sm text-gray-600">{personalCareTaskHints[type]}</span>)}
            </td>
            {props.times.map(time => (
            <td className="pr-4 text-center py-2" key={time}>
              <PersonalCareTaskWidget
                type={type}
                recordedAt={time}
                disabled={true}
                personalCareTask={props.personalCareTasks[type] && props.personalCareTasks[type][time] ? props.personalCareTasks[type][time] : null}
                shiftReportId={props.shiftReport.id}
              />
            </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>

      <h1 className="text-2xl mb-4">Activities</h1>
      <table className="w-full mb-8 rounded-md table--rounded shadow-md">
        <thead>
        <tr className="bg-gray-100">
          <th></th>
          {props.times.map(time => (
            <th className="pr-4 pb-4 pt-4" key={time}>
              {moment(time).format('H:mm')}
            </th>
          ))}
        </tr>
        </thead>
        <tbody>
        {activities.map((type, i) => (
          <tr className={i % 2 === 0 ? 'bg-white' : 'bg-gray-100'} key={i}>
            <td className="p-4 leading-tight w-64">
              {activityLabels[type]}
            </td>
            {props.times.map(time => (
              <td className="pr-4 text-center py-2" key={time}>
                <ActivityWidget
                  type={type}
                  recordedAt={time}
                  disabled={true}
                  activity={props.activities[type] && props.activities[type][time] ? props.activities[type][time] : null}
                  shiftReportId={props.shiftReport.id}
                />
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>

      <h1 className="text-2xl mb-4">Vitals</h1>
      <div className="mb-8 flex flex-wrap">
        <VitalTable
          readOnly
          vitals={props.vitals} 
        />
      </div>

      <h1 className="text-2xl mb-4">Incident Reports</h1>
      <div className="mb-8 flex flex-wrap">
        <IncidentReportTable 
          incidentReports={props.incidentReports}
          readOnly 
        />
      </div>
    </div>
  </Container>
)

export default ShiftReportReadOnly;

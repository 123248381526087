import React, { useEffect } from 'react';
import BasicAssessmentForm from './BasicAssessmentForm';
import AssessmentLayout from './AssessmentLayout';

const BasicAssessment = (props) => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  const redirectBack = () => {
    props.history.push(`/shift-report/clinical/v1/${props.shiftReport.id}/edit`);
  }

  const redirectToDigestiveAssessment = () => {
    props.history.push(`/shift-report/clinical/v1/${props.shiftReport.id}/assessment/digestive`);
  }

  return (
    <AssessmentLayout {...props}>
      <h3 id="specific-assessment-header" className="block text-gray-600 text-3xl mb-6">Assessment - Basic</h3>
      <div className="bg-white justify-between rounded-lg shadow-md">
        <BasicAssessmentForm
          onCancel={redirectBack}
          onContinue={redirectToDigestiveAssessment}
          onSubmit={props.updateAssessment}
          assessment={props.assessment}
        />
      </div>
    </AssessmentLayout>
  );
}

export default BasicAssessment;

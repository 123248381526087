import * as Yup from 'yup'

export const verifyChecked = (category) => (obj) => (
  Object
    .keys(obj)
    .filter(x => x.startsWith(`${category}_`))
    .map(x => obj[x])
    .some(x => x)
);

const ensureOtherValue = (category) => (
  Yup.string().when(`${category}_other`, {
    is: true,
    then: Yup.string().required("Please type a description in the 'Other' box.").max(60),
    otherwise: null
  })
);

export const checkOneMessage = 'You must check at least one option';

export const validationSchema = Yup.object().shape({
  disposition: Yup.object().shape({
    disposition_happiness: Yup.string(),
    disposition_stable: Yup.boolean(),
    disposition_change_in_condition: Yup.boolean(),
    disposition_irritable: Yup.boolean(),
    disposition_anxious: Yup.boolean(),
    disposition_withdrawn: Yup.boolean(),
  }).test(
    'disposition-answered-test',
    checkOneMessage,
    verifyChecked('disposition')
  ),
  head: Yup.object().shape({
    head_centered: Yup.boolean(),
    head_lesions: Yup.boolean(),
    head_facial_expressions_smooth: Yup.boolean(),
    head_flaking_scalp: Yup.boolean(),
    head_other: Yup.boolean(),
    head_other_value: ensureOtherValue('head')
  }).test(
    'head-answered-test',
    checkOneMessage,
    verifyChecked('head')
  ),
  eyes: Yup.object().shape({
    eyes_clear: Yup.boolean(),
    eyes_drainages: Yup.boolean(),
    eyes_perrla: Yup.boolean(),
    eyes_glaucoma: Yup.boolean(),
    eyes_macular_degeneration: Yup.boolean(),
    eyes_edema: Yup.boolean(),
    eyes_glasses: Yup.boolean(),
    eyes_other: Yup.boolean(),
    eyes_other_value: ensureOtherValue('eyes')
  }).test(
    'eyes-answered-test',
    checkOneMessage,
    verifyChecked('eyes')
  ),
  ears: Yup.object().shape({
    ears_clear: Yup.boolean(),
    ears_drainage: Yup.boolean(),
    ears_hoh: Yup.boolean(),
    ears_hoh_right: Yup.boolean(),
    ears_hoh_left: Yup.boolean(),
    ears_aide: Yup.boolean(),
    ears_aide_right: Yup.boolean(),
    ears_aide_left: Yup.boolean(),
    ears_other: Yup.boolean(),
    ears_other_value: ensureOtherValue('ears')
  }).test(
    'ears-answered-test',
    checkOneMessage,
    verifyChecked('ears')
  ),
  nose: Yup.object().shape({
    nose_patent: Yup.boolean(),
    nose_drainage: Yup.boolean(),
    nose_septal_deviation: Yup.boolean(),
    nose_other: Yup.boolean(),
    nose_other_value: ensureOtherValue('nose')
  }).test(
    'nose-answered-test',
    checkOneMessage,
    verifyChecked('nose')
  ),
  mouth: Yup.object().shape({
    mouth_pink: Yup.boolean(),
    mouth_cyanotic: Yup.boolean(),
    mouth_dry: Yup.boolean(),
    mouth_bleeding: Yup.boolean(),
    mouth_lesions: Yup.boolean(),
    mouth_other: Yup.boolean(),
    mouth_other_value: ensureOtherValue('mouth')
  }).test(
    'mouth-answered-test',
    checkOneMessage,
    verifyChecked('mouth')
  ),
  tongue: Yup.object().shape({
    tongue_pink: Yup.boolean(),
    tongue_midline: Yup.boolean(),
    tongue_intact: Yup.boolean(),
    tongue_glistening: Yup.boolean(),
    tongue_dry: Yup.boolean(),
    tongue_swollen: Yup.boolean(),
    tongue_deviated: Yup.boolean(),
    tongue_other: Yup.boolean(),
    tongue_other_value: ensureOtherValue('tongue')
  }).test(
    'tongue-answered-test',
    checkOneMessage,
    verifyChecked('tongue')
  )

});

import React from 'react';
import { Link } from 'react-router-dom';
import { employeeName } from '../utils/employees'

const Header = props => (
  <div className="p-6 flex justify-between bg-blue-700 px-8 border-gray-400 text-blue-100">
    <div>
      <Link to="/client" className="bg-blue-600 p-4 rounded-full text-white px-6">
        <i className="fas fa-home inline-block text-white mr-1" /> Home
      </Link>
    </div>
    <div>
      <span className="inline-block mr-8">
        <i className="fas fa-user-nurse inline-block mr-1" /> {employeeName(props.user)}
      </span>
      <Link to="/logout" className="bg-blue-600 p-4 rounded-full text-white px-6"><i className="fas fa-sign-out inline-block mr-1" /> Sign Out</Link>
    </div>
  </div>
);

export default Header;
import React from 'react'
import classnames from 'classnames'
import BasicCheckbox from '../../../form/BasicCheckbox'

const allowList = ['clear', 'coarse', 'diminished']

const BreathSoundsCheckboxes = ({
  index,
  formState,
  value,
  label,
  onClick,
  onMinorClick
}) => {
  const maybeRenderCheckboxes = () => {
    if(value === "coarse") {
      return (
        <div className="ml-6 flex flex-row">
          <BasicCheckbox
            checked={formState.breath_sounds_coarse === 'expiratory'}
            onClick={() => {
              if(formState.breath_sounds_coarse === 'expiratory') {
                onMinorClick('breath_sounds.breath_sounds_coarse', '')
              } else {
                onMinorClick('breath_sounds.breath_sounds_coarse', 'expiratory')
              }
            }}
            className="block m-2"
            checkboxClassName="text-xl"
          >
            Expiratory
          </BasicCheckbox>
          <BasicCheckbox
            checked={formState.breath_sounds_coarse === 'inspiratory'}
            onClick={() => {
              if(formState.breath_sounds_coarse === 'inspiratory') {
                onMinorClick('breath_sounds.breath_sounds_coarse', '')
              } else {
                onMinorClick('breath_sounds.breath_sounds_coarse', 'inspiratory')
              }
            }}
            className="block m-2"
            checkboxClassName="text-xl"
          >
            Inspiratory
          </BasicCheckbox>
        </div>
      )
    }
  }

  const shouldDisable = (values) => {
    return values && values.includes('clear') && allowList.every(x => x !== value)
  }

  return (
    <tr
      className={classnames('align-top', {'bg-gray-100': !(index % 2)})}
      id={`breath_sounds_${index}_checkboxes`}
    >
      <td className="p-4">
        <div className="flex flex-row items-center" >
          {label}
          {maybeRenderCheckboxes()}
        </div>
      </td>
      <td className="p-4">
        <BasicCheckbox
          checked={
            formState.breath_sounds_right && formState.breath_sounds_right.includes(value)
          }
          onClick={() => onClick('right', value)}
          className="block m-2"
          disabled={shouldDisable(formState.breath_sounds_right)}
        />
      </td>
      <td className="p-4" >
        <BasicCheckbox
          checked={
            formState.breath_sounds_left && formState.breath_sounds_left.includes(value)
          }
          onClick={() => onClick('left', value)}
          className="block m-2"
          disabled={shouldDisable(formState.breath_sounds_left)}
        />
      </td>
      <td className="p-4 pl-6" >
        <BasicCheckbox
          checked={
            formState.breath_sounds_upper && formState.breath_sounds_upper.includes(value)
          }
          onClick={() => onClick('upper', value)}
          className="block m-2"
          disabled={shouldDisable(formState.breath_sounds_upper)}
        />
      </td>
      <td className="p-4" >
        <BasicCheckbox
          checked={
            formState.breath_sounds_lower && formState.breath_sounds_lower.includes(value)
          }
          onClick={() => onClick('lower', value)}
          className="block m-2"
          disabled={shouldDisable(formState.breath_sounds_lower)}
        />
      </td>
    </tr>
  )
}

export default BreathSoundsCheckboxes

import React from 'react';

const Loader = props => (
  <div className="bg-gray-300 absolute h-screen w-full flex items-center justify-center left-0 top-0 z-50">
    <div className="bg-white shadow-lg w-auto p-8 pt-6 rounded-lg text-center">
      <div className="pb-5 text-blue-900">
        {props.children}
      </div>
      <div>
        <i className="text-blue-400 fas fa-spinner fa-spin fa-3x"/>
      </div>
    </div>
  </div>
);

export default Loader;
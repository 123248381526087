import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CompletedRefusedToggle from './CompletedRefusedToggle'
import { activityLabels } from '../constants'

class ActivityWidget extends React.Component {
  _handleChange = (status, comment) => {
    if(status === null && this.props.activity) {
      this._delete();
    } else if(status !== null && this._shouldCreate()) {
      this._create(status, comment);
    } else if(status !== null) {
      this._update(status, comment);
    }
  }

  _delete = () => {
    this.props.deleteActivity(this.props.activity);
  }

  _create = (status, comment) => {
    this.props.createActivity(this.props.shiftReportId, this.props.type, this.props.recordedAt, status, comment);
  }

  _update = (status, comment) => {
    this.props.updateActivity({
      id: this.props.activity.id,
      status,
      comment
    });
  }

  _shouldCreate = () => {
    return this.props.activity === null;
  }

  render = () => (
    <CompletedRefusedToggle
      heading={activityLabels[this.props.type]}
      subheading={moment(this.props.recordedAt).format('H:mm')}
      onSubmit={this._handleChange}
      status={this.props.activity ? this.props.activity.status : null}
      comment=""
      disabled={this.props.disabled}
    />
  )
}

ActivityWidget.propTypes = {
  activity: PropTypes.object,
  deleteActivity: PropTypes.func,
  updateActivity: PropTypes.func,
  createActivity: PropTypes.func,
  type: PropTypes.string,
  recordedAt: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ActivityWidget

import React from "react";
import CenterContainer from "../../components/layout/CenterContainer";

const Dialog = (props) => (
  <CenterContainer>
    <div id={props.id} className="bg-white shadow-lg rounded-lg w-1/2">
      <div className="p-8 pb-6">
        <span className="block font-bold text-lg">
          {props.title}
        </span>
        <p id={props.subTitleId} className="text-gray-600">
          {props.subTitle}
        </p>
      </div>
      <div>
        {props.children}
        <div className="text-right p-4 px-8 bg-gray-100 rounded-b-lg">
          {props.footer()}
        </div>
      </div>
    </div>
  </CenterContainer>
);

export default Dialog;

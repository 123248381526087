import React from 'react'
import ShiftReportTimeStart from '../components/ShiftReportTimeStart';
import ShiftReportTimeEnd from '../components/ShiftReportTimeEnd';
import ShiftReportTimeConfirm from '../components/ShiftReportTimeConfirm';

class ShiftReportTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0
    }
  }

  _handleStartNext = (values) => {
    this.props.handleTimeStartUpdate(values)
    this.setState({
      step: 1
    })
  }

  _handleEndNext = (values) => {
    this.props.handleTimeEndUpdate(values)
    this.setState({
      step: 2
    })
  }

  _handleBack = () => {
    this.setState({
      step: this.state.step - 1
    })
  }


  render = () => (
    <>
      {this.state.step === 0 && 
        <ShiftReportTimeStart 
          date={this.props.date}
          start={this.props.start}
          handleStartNext={this._handleStartNext}
          onBackClick={this.props.onBackClick}
        />}
      {this.state.step === 1 && 
        <ShiftReportTimeEnd
          date={this.props.date} 
          start={this.props.start}
          end={this.props.end} 
          handleEndNext={this._handleEndNext}
          onBackClick={this._handleBack}
        />}
      {this.state.step === 2 && 
        <ShiftReportTimeConfirm
          date={this.props.date}
          start={this.props.start}
          end={this.props.end}
          onBackClick={this._handleBack}
          disabled={this.props.disabled}
          onSubmit={this.props.onSubmit} 
        />}
    </>
  )
}

export default ShiftReportTime
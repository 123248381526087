import React from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Route } from 'react-router-dom';
import moment from 'moment';
import ShiftReportDate from '../components/ShiftReportDate';
import ShiftReportTime from './ShiftReportTime';
import Loader from '../components/Loader';
import { createShiftReport } from '../actions';
import { roleShiftTypeMap} from '../constants';
import { getClient, getUser, getUserShiftReports } from '../selectors';
import ShiftReportConfirmName from "../components/ShiftReportConfirmName";
import ShiftReportConfirmTime from "../components/ShiftReportConfirmTime";
import { numHours, minSinceMidnight } from '../utils/timeUtils';
import { shiftReportTypes } from '../utils/shiftTypes';

class ShiftReportWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      loading: false,
      start: null,
      end: null,
    };
  }

  _handleDateFormSubmit = date => {
    this.setState({
      date,
    });
    this.props.history.push('/shift-report/new/time/');
  };

  _handleTimeRangeFormSubmit = time => {
    this.setState({
      start: time.start,
      end: time.end
    })
    if(!Number.isInteger(numHours(this.state.date, minSinceMidnight(time.start), minSinceMidnight(time.end)))){
      this.props.history.push('/shift-report/new/time/confirm')
    } else {
      this._handleConfirmTime()
    }
  };

  _handleTimeStartUpdate = time => {
    this.setState({
      start: time.start
    })
  }
  _handleTimeEndUpdate = time => {
    this.setState({
      end: time.end
    })
  }
  
  _handleConfirmTime = () => {
    this.setState({
      loading: true,
    });
    this.props.createShiftReport(this.props.user.id, this.props.client.id, this.state.start, this.state.end, roleShiftTypeMap[this.props.user.role]);
  }

  _handleConfirmName = () => {
    this.props.history.push('/shift-report/new/date');
  }

  _handleCancelClick = () => {
    this.props.history.push('/client');
  };

  _handleBackClick = () => {
    this.props.history.goBack();
  };

  componentDidUpdate = prevProps => {
    const shiftReport = this.props.shiftReports[this.props.shiftReports.length-1];
    if(prevProps.shiftReports.length !== this.props.shiftReports.length) {
      this.props.history.push(`/shift-report/${shiftReportTypes(shiftReport.type)}/v${shiftReport.version}/${shiftReport.id}/edit`);
    }
  };

  render = () => (
    <React.Fragment>
      {this.state.loading && <Loader>Creating shift report...</Loader>}
      <Router>
        <Route
          path="/shift-report/new"
          exact
          render={() => (
            <ShiftReportConfirmName
              onSubmit={this._handleConfirmName}
              onCancelClick={this._handleCancelClick}
              user={this.props.user}
            />
          )}
        />
        <Route
          path="/shift-report/new/date"
          exact
          render={() => (
            <ShiftReportDate
              date={this.state.date}
              onBackClick={this._handleBackClick}
              onSubmit={this._handleDateFormSubmit}
            />
          )}
        />
        <Route
          path="/shift-report/new/time"
          exact
          render={() => (
            <ShiftReportTime
              date={this.state.date}
              start={this.state.start}
              end={this.state.end}
              onCancelClick={this._handleCancelClick}
              onBackClick={this._handleBackClick}
              handleTimeStartUpdate={this._handleTimeStartUpdate}
              handleTimeEndUpdate={this._handleTimeEndUpdate}
              onSubmit={this._handleTimeRangeFormSubmit}
              disabled={this.state.loading}
            />
          )}
        />
        <Route
          path="/shift-report/new/time/confirm"
          exact
          render={() => (
            <ShiftReportConfirmTime
              date={this.state.date}
              start={this.state.start}
              end={this.state.end}
              onSubmit={this._handleConfirmTime}
              onCancelClick={this._handleBackClick}
            />
          )}
          />
      </Router>
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => ({
  createShiftReport: (userId, clientId, shiftStart, shiftEnd, shiftType) => dispatch(createShiftReport(userId, clientId, shiftStart, shiftEnd, shiftType)),
});

const mapStateToProps = state => ({
  user: getUser(state),
  client: getClient(state),
  shiftReports: getUserShiftReports(state),
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShiftReportWizard);

export default Container;

import React from 'react'
import { Formik } from 'formik'
import SecondaryButton from './SecondaryButton'
import PrimaryButton from './form/PrimaryButton'
import CenterContainer from './layout/CenterContainer'

const ShiftReportConfirmName = props => (
  <CenterContainer>
    <div className="bg-white rounded-lg shadow-lg w-1/2">
      <div className="p-8 pb-0 mb-4">
        <span className="block font-bold text-lg">Create a new shift report</span>
        <p id="confirm-name" className="text-gray-600">
          Confirm your name
        </p>
      </div>
      <Formik
        onSubmit={(values) => {
          props.onSubmit();
        }}
      >
        {({
            handleSubmit,
          }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-8 pl-8">
              You are currently logged in as <span className="underline font-bold">{props.user.firstName} {props.user.lastName}</span>. Do you wish to continue?
            </div>
            <div className="text-right bg-gray-100 p-4 rounded-b-lg">
              <SecondaryButton id="name-secondary-button" type="button" className="inline-block mr-4" onClick={props.onCancelClick}>No, Cancel</SecondaryButton>
              <PrimaryButton id="name-primary-button" type="submit">Yes, Continue</PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  </CenterContainer>
);

export default ShiftReportConfirmName

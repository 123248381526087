import { 
  LOCATION_MAPPINGS, 
  PAIN_ASSESSMENT_SCALE_MAPPINGS, 
  PAIN_ASSESSMENT_PRESENT_LEVEL_MAPPINGS,
  PAIN_ASSESSMENT_WORST_PAIN_GETS_MAPPINGS,
  PAIN_ASSESSMENT_BEST_PAIN_GETS_MAPPINGS,
} from "../constants"

import { create } from "lodash"

const createOptions = (fieldMappings) => {
  return Object.keys(fieldMappings).map(key => create({label: fieldMappings[key], value: key}))
}

const locationOptions = createOptions(LOCATION_MAPPINGS)
const scaleOptions = createOptions(PAIN_ASSESSMENT_SCALE_MAPPINGS)
const presentLevelOptions = createOptions(PAIN_ASSESSMENT_PRESENT_LEVEL_MAPPINGS)
const worstPainGetsOptions = createOptions(PAIN_ASSESSMENT_WORST_PAIN_GETS_MAPPINGS)
const bestPainGetsOptions = createOptions(PAIN_ASSESSMENT_BEST_PAIN_GETS_MAPPINGS)

export const painAssessmentOptions = (field) => {
  switch(field){
    case "location":
      return locationOptions
    case "scale":
      return scaleOptions
    case "presentLevel":
      return presentLevelOptions
    case "worstPainGets":
      return worstPainGetsOptions
    case "bestPainGets":
      return bestPainGetsOptions
    default:
      break;
  }
}

export const getLabel = (field, value) => {
  const fieldOptions = painAssessmentOptions(field).filter(option => option.value === value)
  return fieldOptions[0].label
}

export const selectedOption = (field, value) => {
  return {
    label: getLabel(field, value),
    value: value
  }
}
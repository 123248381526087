import React from 'react'
import PrimaryButton from '../../../form/PrimaryButton'

const ShiftReportErrorModal = ({errors, onClose}) => (
  <React.Fragment>
    <div className="p-8 font-bold text-lg pb-0 mb-4">
      Uh-oh, something isn't quite right.
    </div>
    <p className="pl-8 mb-2">
      Please fix the following issues and try again:
    </p>
    <div className="p-8 pt-0">
      <ul id="error-list" className="list-disc ml-8">
        {errors.map(error => (
          <li key={error} className="text-red-600">
            {error}
          </li>
        ))}
      </ul>
    </div>
    <div className="text-right p-4 bg-gray-100 rounded-b-lg">
      <PrimaryButton id="errors-primary-button" className="inline-block mr-4" onClick={onClose}>Close</PrimaryButton>
    </div>
  </React.Fragment>
);

export default ShiftReportErrorModal;
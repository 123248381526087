import { LOGOUT_USER, UPDATE_USER } from '../actions';

const initialState = {
  id: null,
  lastUpdated: null,
};

const user = (state = initialState, action) => {
  switch(action.type) {
    case LOGOUT_USER:
    case UPDATE_USER:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export default user;

import React, { useMemo } from "react";
import { basicAssessmentMappings, neuroAssessmentMappings } from "../../constants";
import {
  assessmentComplete,
  getCheckedValues,
  extractDispositionAssessment,
  extractHeadAssessment,
  extractEyeAssessment,
  extractEarAssessment,
  extractNoseAssessment,
  extractMouthAssessment,
  extractTongueAssessment,
  extractBowelsAssessment,
  extractBowelSoundsAssessment,
  extractBladderAssessment,
  extractAbdomenAssessment,
  extractApicalAssessment,
  extractEdemaAssessment,
  extractCapillaryRefillAssessment,
  extractPedalPulsesAssessment,
  extractRadialPulsesAssessment,
  extractConsciousnessAssessment,
  extractChestAssessment,
  extractBreathSoundsAssessment,
  extractRespiratoryAssessment,
  extractQualityAssessment,
  extractCoughAssessment,
  extractSputumAssessment,
  extractOxygenAssessment,
} from "../../utils/assessmentUtils";
import { Summaries } from '../../components/shiftReports/clinical/v1/basicSummaryComponents'

export const useBasicMemo = (assessment, shiftReportPath) => useMemo(() => {
    const dispositionAssessment = extractDispositionAssessment(assessment);
    const headAssessment        = extractHeadAssessment(assessment);
    const eyeAssessment         = extractEyeAssessment(assessment);
    const earAssessment         = extractEarAssessment(assessment);
    const noseAssessment        = extractNoseAssessment(assessment);
    const mouthAssessment       = extractMouthAssessment(assessment);
    const tongueAssessment      = extractTongueAssessment(assessment);
    const basicAssessmentUrl    = `${shiftReportPath}/assessment/basic`;

    return [
      {
        label: 'Disposition',
        url: `${basicAssessmentUrl}#disposition-heading`,
        completed: assessmentComplete(dispositionAssessment),
        value: assessment !== null && <Summaries.Disposition
                                        assessment={dispositionAssessment}
                                      />
      },
      {
        label: 'Head/Face/Scalp',
        url: `${basicAssessmentUrl}#head-heading`,
        completed: assessmentComplete(headAssessment),
        value: getCheckedValues(headAssessment, basicAssessmentMappings)
      },
      {
        label: 'Eyes',
        url: `${basicAssessmentUrl}#eyes-heading`,
        completed: assessmentComplete(eyeAssessment),
        value: getCheckedValues(eyeAssessment, basicAssessmentMappings)
      },
      {
        label: 'Ears',
        url: `${basicAssessmentUrl}#ears-heading`,
        completed: assessmentComplete(earAssessment),
        value: getCheckedValues(earAssessment, basicAssessmentMappings)
      },
      {
        label: 'Nose',
        url: `${basicAssessmentUrl}#nose-heading`,
        completed: assessmentComplete(noseAssessment),
        value: getCheckedValues(noseAssessment, basicAssessmentMappings)
      },
      {
        label: 'Mouth',
        url: `${basicAssessmentUrl}#mouth-heading`,
        completed: assessmentComplete(mouthAssessment),
        value: getCheckedValues(mouthAssessment, basicAssessmentMappings)
      },
      {
        label: 'Tongue',
        url: `${basicAssessmentUrl}#tongue-heading`,
        completed: assessmentComplete(tongueAssessment),
        value: getCheckedValues(tongueAssessment, basicAssessmentMappings)
      }
    ]
  }, [assessment, shiftReportPath]);

export const useDigestiveMemo = (assessment, shiftReportPath) => useMemo(() => {
    const bowelsAssessment        = extractBowelsAssessment(assessment);
    const bowelSoundsAssessment   = extractBowelSoundsAssessment(assessment);
    const bladderAssessment       = extractBladderAssessment(assessment);
    const abdomenAssessment       = extractAbdomenAssessment(assessment);

    const digestiveAssessmentUrl    = `${shiftReportPath}/assessment/digestive`;

    return [
      {
        label: 'Bowels',
        url: `${digestiveAssessmentUrl}#bowels-heading`,
        completed: assessmentComplete(bowelsAssessment),
        value: assessment !== null && <Summaries.Bowel
                                        assessment={bowelsAssessment}
                                      />
      },
      {
        label: 'Bowel Sounds',
        url: `${digestiveAssessmentUrl}#bowel-sounds-table`,
        completed: assessmentComplete(bowelSoundsAssessment),
        value: assessment !== null && <Summaries.BowelSounds
                                        assessment={bowelSoundsAssessment}
                                      />
      },
      {
        label: 'Bladder',
        url: `${digestiveAssessmentUrl}#bladder-heading`,
        completed: assessmentComplete(bladderAssessment),
        value: assessment !== null && <Summaries.Bladder
                                        assessment={bladderAssessment}
                                      />
      },
      {
        label: 'Abdomen',
        url: `${digestiveAssessmentUrl}#abdomen-heading`,
        completed: assessmentComplete(abdomenAssessment),
        value: assessment !== null && <Summaries.Abdomen
                                        assessment={abdomenAssessment}
                                      />
      }
    ]
  }, [assessment, shiftReportPath]);

export const useCardioMemo = (assessment, shiftReportPath) => useMemo(() => {
    const apicalAssessment = extractApicalAssessment(assessment);
    const edemaAssessment = extractEdemaAssessment(assessment);
    const capillaryRefillAssessment = extractCapillaryRefillAssessment(assessment);
    const pedalPulsesAssessment = extractPedalPulsesAssessment(assessment);
    const radialPulsesAssessment = extractRadialPulsesAssessment(assessment);
    const cardioAssessmentUrl    = `${shiftReportPath}/assessment/cardiovascular`;

    return [
      {
        label: 'Apical',
        url: `${cardioAssessmentUrl}#apical-heading`,
        completed: assessmentComplete(apicalAssessment),
        value: assessment !== null && <Summaries.Apical
                                         assessment={apicalAssessment}
                                       />
      },
      {
        label: 'Edema',
        url: `${cardioAssessmentUrl}#edema-heading`,
        completed: assessmentComplete(edemaAssessment),
        value: assessment !== null && <Summaries.Edema
                                        assessment={edemaAssessment}
                                      />
      },
      {
        label: 'Capillary Refill',
        url: `${cardioAssessmentUrl}#capillary-refill-heading`,
        completed: assessmentComplete(capillaryRefillAssessment),
        value: assessment !== null && <Summaries.CapillaryRefill
                                        assessment={capillaryRefillAssessment}
                                      />
      },
      {
        label: 'Pedal Pulses',
        url: `${cardioAssessmentUrl}#pedal-pulses-heading`,
        completed: assessmentComplete(pedalPulsesAssessment),
        value: assessment !== null && <Summaries.Pulses
                                        assessment={pedalPulsesAssessment}
                                        pulseType="pedal"
                                      />
      },
      {
        label: 'Radial Pulses',
        url: `${cardioAssessmentUrl}#radial-pulses-heading`,
        completed: assessmentComplete(radialPulsesAssessment),
        value: assessment !== null && <Summaries.Pulses
                                        assessment={radialPulsesAssessment}
                                        pulseType="radial"
                                      />
      },
      {
        label: 'Abnormal Findings',
        url: `${cardioAssessmentUrl}#abnormal-findings-heading`,
        completed: assessment !== null && assessmentComplete({abnormal_cardiovascular_findings: assessment.abnormal_cardiovascular_findings}),
        value: ''
      }
    ]

  }, [assessment, shiftReportPath])

export const useNeuroMemo = (assessment, shiftReportPath) => useMemo(() => {
  const consciousnessAssessment = extractConsciousnessAssessment(assessment);
  const neuroAssessmentUrl = `${shiftReportPath}/assessment/neuro`;
    return [
      {
        label: 'Level of Consciousness',
        id: 'Level',
        url: neuroAssessmentUrl,
        completed: assessmentComplete(consciousnessAssessment),
        value: getCheckedValues(consciousnessAssessment, neuroAssessmentMappings)
      }
    ]
  },[assessment, shiftReportPath])

export const usePulmonaryMemo = (assessment, shiftReportPath) => useMemo(() => {
  const chestAssessment = extractChestAssessment(assessment);
  const breathSoundsAssessment = extractBreathSoundsAssessment(assessment);
  const respiratoryAssessment = extractRespiratoryAssessment(assessment);
  const qualityAssessment = extractQualityAssessment(assessment);
  const coughAssessment = extractCoughAssessment(assessment);
  const sputumAssessment = extractSputumAssessment(assessment);
  const oxygenAssessment = extractOxygenAssessment(assessment);
  const pulmonaryAssessmentUrl    = `${shiftReportPath}/assessment/pulmonary`;

  return [
    {
      label: 'Chest',
      url: `${pulmonaryAssessmentUrl}#chest-expansion-heading`,
      completed: assessmentComplete(chestAssessment),
      value: assessment !== null && <Summaries.Chest
                                       assessment={chestAssessment }
                                     />
    },
    {
      label: 'Breath Sounds',
      url: `${pulmonaryAssessmentUrl}#breath-sounds-heading`,
      completed: assessmentComplete(breathSoundsAssessment),
      value: assessment !== null && <Summaries.BreathSounds
                                      assessment={breathSoundsAssessment}
                                    />
    },
    {
      label: 'Respiratory',
      url: `${pulmonaryAssessmentUrl}#respiratory-heading`,
      completed: assessmentComplete(respiratoryAssessment),
      value: assessment !== null && <Summaries.Respiratory
                                      assessment={respiratoryAssessment}
                                    />
    },
    {
      label: 'Pattern',
      url: `${pulmonaryAssessmentUrl}#pattern-heading`,
      completed: assessment !== null && assessmentComplete({pattern_labor: assessment.pattern_labor}),
      value: assessment !== null && <Summaries.Pattern
                                      assessment={{pattern_labor: assessment.pattern_labor}}
                                    />
    },
    {
      label: 'Quality',
      url: `${pulmonaryAssessmentUrl}#quality-heading`,
      completed: assessmentComplete(qualityAssessment),
      value: assessment !== null && <Summaries.Quality
                                      assessment={qualityAssessment}
                                    />
    },
    {
      label: 'Cough',
      url: `${pulmonaryAssessmentUrl}#cough-heading`,
      completed: assessmentComplete(coughAssessment),
      value: assessment !== null && <Summaries.Cough
                                      assessment={coughAssessment}
                                    />
    },
    {
      label: 'Sputum',
      url: `${pulmonaryAssessmentUrl}#sputum-heading`,
      completed: assessmentComplete(sputumAssessment),
      value: assessment !== null && <Summaries.Sputum
                                      assessment={sputumAssessment}
                                    />
    },
    {
      label: 'Oxygen',
      url: `${pulmonaryAssessmentUrl}#oxygen-heading`,
      completed: assessmentComplete(oxygenAssessment),
      value: assessment !== null && <Summaries.Oxygen
                                      assessment={oxygenAssessment}
                                    />
    },
    {
      label: 'Abnormal Findings',
      url: `${pulmonaryAssessmentUrl}#abnormal-findings-heading`,
      completed: assessment !== null && assessmentComplete({abnormal_pulmonary_findings: assessment.abnormal_pulmonary_findings}),
      value: ''
    }
  ]

}, [assessment, shiftReportPath])

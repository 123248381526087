import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {getLabel} from '../../../../../utils/painAssessmentUtils'

const valid = measurement => {
  return measurement !== null && measurement !== '' && measurement !== undefined;
}

const PainAssessmentTableRow = ({
  index,
  readOnly,
  painAssessment,
  onClick,
}) => (
  <tr className={classnames('align-top', {'cursor-pointer': !readOnly, 'cursor-default': readOnly}, {'bg-gray-100': index % 2 === 1})} 
      onClick={() => readOnly ? null : onClick(index)} >
    <td className="p-4 align-middle">
      {valid(painAssessment.location) && <span>{getLabel("location", painAssessment.location)}</span>}
    </td>
    <td className="p-4 align-middle">
      {valid(painAssessment.onset) && <span>{painAssessment.onset}</span>}
    </td>
    <td className="p-4 align-middle">
      {valid(painAssessment.scale) && <span>{painAssessment.scale}</span>}
    </td>
    <td className="p-4 align-middle">
      {valid(painAssessment.presentLevel) && <span>{getLabel("presentLevel", painAssessment.presentLevel)}</span>}
    </td>
    <td className="p-4 align-middle">
      {valid(painAssessment.worstPainGets) && <span>{getLabel("worstPainGets", painAssessment.worstPainGets)}</span>}
    </td>
    <td className="p-4 align-middle">
      {valid(painAssessment.bestPainGets) && <span>{getLabel("bestPainGets", painAssessment.bestPainGets)}</span>}
    </td>
    {!readOnly && (
      <td className="p-4 text-right align-middle">
        <button id="vitals-edit-button" className="bg-blue-600 text-gray-100 p-3 px-4 rounded-full inline-block" onClick={() => onClick(index)}>
          <i className="fas fa-pencil inline-block mr-1" /> Edit
        </button>
      </td>
    )}
  </tr>
)

PainAssessmentTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  painAssessment: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

PainAssessmentTableRow.defaultProps = {
  readOnly: false,
}

export default PainAssessmentTableRow

import React from 'react'
import classnames from 'classnames'
import Checkbox from '../Checkbox'

const defaultClass = 'flex items-center text-left focus:outline-none'
const defaultCheckboxClass = "text-3xl"

const BasicCheckbox = ({
  checked,
  onClick,
  className,
  checkboxClassName,
  children,
  disabled,
  ...props
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    {...props}
    className={classnames(className, defaultClass)}
  >
    <Checkbox
      checked={checked}
      className={checkboxClassName || defaultCheckboxClass}
      disabled={disabled}
    /> {children}
  </button>
)

export default BasicCheckbox

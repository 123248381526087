import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { updateVitals, createVitals } from '../actions';
import { isMidnight } from '../utils/timeUtils'
import VitalForm from '../components/VitalForm';
import VitalTable from '../components/VitalTable';

class Vitals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      isEditing: false,
      curVitalId: null,
    };
  };

  _handleAddVital = () => {
    this.setState({
      isAdding: true,
    });
  };

  _handleSubmitAddVital = vital => {
    this.props.addVital({
      ...vital,
      shiftReportId: this.props.shiftReport.id,
    });
    this.setState({
      isAdding: false,
    });
  };

  _handleSubmitEditVital = vital => {
    this.props.updateVital({
      ...this.props.vitals[this.state.curVitalId],
      ...vital
    });
    this.setState({
      isEditing: false,
      curVitalId: null,
    });
  };

  _handleCancelEditVital = () => {
    this.setState({
      isEditing: false,
      curVitalId: null,
    });
  };

  _handleCancelAddVital = () => {
    this.setState({
      isAdding: false,
    });
  };

  _handleEditVital = id => {
    this.setState({
      isEditing: true,
      curVitalId: id,
    });
  };

  _sortedVitals = () => {
    return this.props.vitals.sort((a,b) => {
      return moment(a.recordedAt).unix() - moment(b.recordedAt).unix()
    });
  };

  render = () => (
    <React.Fragment>
      {this.state.isAdding && (
        <VitalForm
          onCancel={this._handleCancelAddVital}
          onSubmit={this._handleSubmitAddVital}
          start={this.props.shiftReport.start}
          end={this.props.shiftReport.end}
          vital={{
            bloodPressureSystolic: null,
            bloodPressureDiastolic: null,
            pulse: null,
            respirationRate: null,
            temperature: null,
            oxygenSaturationLevel: null,
            weight: null,
            clientRefused: false,
            recordedAt: null,
            completed: false,
          }}
        />
      )}
      {this.state.isEditing && (
        <VitalForm
          onCancel={this._handleCancelEditVital}
          onSubmit={this._handleSubmitEditVital}
          start={this.props.shiftReport.start}
          end={this.props.shiftReport.end}
          vital={this.props.vitals[this.state.curVitalId]}
        />
      )}
      <VitalTable
        vitals={this._sortedVitals()}
        onEditVital={this._handleEditVital}
        onAddVital={this._handleAddVital}
        shiftEndsAtMidnight={isMidnight(this.props.shiftReport.end)}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => ({
  updateVital: vitals => dispatch(updateVitals(vitals)),
  addVital: vitals => dispatch(createVitals(vitals))
});

const mapStateToProps = (state, ownProps) => ({
  vitals: ownProps.shiftReport.vitals.map(id => state.entities.vitals.byId[id]),
  ...ownProps
});

export default connect(mapStateToProps, mapDispatchToProps)(Vitals);

import React from "react";
import { Formik } from "formik";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./form/PrimaryButton";
import CenterContainer from "./layout/CenterContainer";
import { numHours, minSinceMidnight } from "../utils/timeUtils";

const ShiftReportConfirmTime = props => (
  <CenterContainer>
    <div className="bg-white rounded-lg shadow-lg w-1/2">
      <div className="p-8 pb-0 mb-4">
        <span className="block font-bold text-lg">
          Create a new shift report
        </span>
        <p id="confirm-time" className="text-gray-600">
          Confirm shift start and end time.
        </p>
      </div>
      <Formik
        onSubmit={values => {
          props.onSubmit();
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-8 pl-8">
              You're creating a shift that is <span className="font-bold">{numHours(props.date, minSinceMidnight(props.start), minSinceMidnight(props.end))} </span> hours. Do you wish to continue?
            </div>
            <div className="text-right bg-gray-100 p-4 rounded-b-lg">
              <SecondaryButton
                id="confirm-time-secondary-button"
                type="button"
                className="inline-block mr-4"
                onClick={props.onCancelClick}
              >
                Back
              </SecondaryButton>
              <PrimaryButton id="confirm-time-primary-button" type="submit">
                Yes, Continue
              </PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  </CenterContainer>
);

export default ShiftReportConfirmTime;

import React from 'react'
import classnames from 'classnames'
import { HashLink } from 'react-router-hash-link'

const AssessmentCheck = ({active}) => (
  <i className={active ?
                'fas fa-check-circle text-lb block mb-4 text-green-600' :
                'fas fa-check-circle text-lb block mb-4 text-gray-400'
               } />
)

const AssessmentTable = ({assessmentGroup, assessments}) => (
  <div className="bg-white justify-between rounded-lg shadow-md w-5/12 m-4 break-words">
    <h4 className="p-4 block text-gray-800 text-xl font-bold mb-4">{assessmentGroup}</h4>
    <div>
      {assessments.map((a, i) => (
        <HashLink key={i} id={`assessment-${assessmentGroup}-${a.id || a.label}`} to={a.url}>
          <div className={classnames('flex', 'p-4', 'justify-between', {'bg-gray-100': i % 2 === 1})}>
            <div className="flex flex-row">
              <div className="mr-2">
                <AssessmentCheck active={a.completed} />
              </div>
              <div className="text-blue-600">
                {a.label}
              </div>
            </div>
            <div className="w-1/2">{a.value}</div>
          </div>
        </HashLink>
      ))}
    </div>
  </div>
)

export default AssessmentTable

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { incidentReportTypes } from '../constants'
import { renderMidnight } from '../utils/timeUtils'
import PrimaryButton from './form/PrimaryButton'
import Checkbox from './Checkbox'

const truncateString = (string) => {
  const maxLength = 250
  if(string.length <= maxLength) { 
    return string 
  } else {
    return `${string.substring(0, maxLength)}...`
  }

}

const IncidentReportTable = ({
  incidentReports,
  onEdit,
  onAdd,
  readOnly,
  shiftEndsAtMidnight
}) => (
  <table className="w-full bg-white rounded-lg shadow-md table--rounded">
    <thead>
      <tr className="bg-gray-100 leading-snug">
        <th className="p-4 text-left align-top">
          <span className="block">Recorded At</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Incident Type</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Comments</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Notified</span>
          <span className="text-gray-600 text-sm font-normal">case manager / 911</span>
        </th>
        {!readOnly && (
          <th></th>
        )}
      </tr>
    </thead>
    <tbody id="incident-report-table">
      {incidentReports.map((incidentReport, i) => (
        <tr
          key={i}
          className={classnames('align-top', {'cursor-pointer': !readOnly, 'cursor-default': readOnly}, {'bg-gray-100': i % 2})}
          onClick={() => readOnly ? null : onEdit(incidentReport.id)}
        >
          <td className="p-4">
            {shiftEndsAtMidnight ? renderMidnight(incidentReport.recordedAt) : moment(incidentReport.recordedAt).format('H:mm')}
          </td>
          <td className="p-4">
            {incidentReportTypes[incidentReport.type]}
          </td>
          <td className="p-4">
            {truncateString(incidentReport.comment)}
          </td>
          <td className="p-4">
            <Checkbox checked={incidentReport.notified} className="text-3xl" />
          </td>
          {!readOnly && (
            <td className="p-4 text-right align-middle">
              <button id="incident-report-edit-button" className="bg-blue-600 text-gray-100 p-3 px-4 rounded-full inline-block" onClick={() => onEdit(incidentReport.id)}>
                <i className="fas fa-pencil inline-block mr-1" /> Edit
              </button>
            </td>
          )}
        </tr>
      ))}
      {readOnly && incidentReports.length === 0 && (
        <tr>
          <td colSpan="5" className="p-4 text-center">
            No incident reports found.
          </td>
        </tr>
      )}
      {!readOnly && (
        <tr>
          <td colSpan="5" className={incidentReports.length % 2 ? 'text-center p-4 bg-gray-100' : 'text-center p-4'}>
            <PrimaryButton id="add-incident-report-button" type="button" onClick={onAdd}>
              <i className="fa fa-plus-circle inline-block mr-1" /> Add Incident Report
            </PrimaryButton>
          </td>
        </tr>
      )}
    </tbody>
  </table>
)

IncidentReportTable.propTypes = {
  readOnly: PropTypes.bool,
  incidentReports: PropTypes.array.isRequired,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func
}

export default IncidentReportTable

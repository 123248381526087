import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from './form/PrimaryButton'
import VitalTableRow from './VitalTableRow'

const VitalTable = ({
  readOnly,
  vitals,
  onEditVital,
  onAddVital,
  shiftEndsAtMidnight
}) => (
  <table className="w-full bg-white rounded-lg shadow-md table--rounded">
    <thead>
      <tr className="bg-gray-100 leading-snug">
        <th className="p-4 text-left align-top">
          <span className="block">Taken At</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Blood Pressure</span>
          <span className="text-gray-600 text-sm font-normal">systolic / diastolic</span>
        </th>
        <th className="p-4 text-left">
          <span className="block">Pulse</span>
          <span className="text-gray-600 text-sm font-normal">beats per minute</span>
        </th>
        <th className="p-4 text-left">
          <span className="block">Respiration Rate</span>
          <span className="text-gray-600 text-sm font-normal">respirations per minute</span>
        </th>
        <th className="p-4 text-left align-top">
          Temperature &deg;F
        </th>
        <th className="p-4 text-left align-top">O<sub>2</sub> Saturation (%)</th>
        <th className="p-4 text-left">
          <span className="block">Weight</span>
          <span className="text-gray-600 text-sm font-normal">pounds</span>
        </th>
        {!readOnly && (
          <th></th>
        )}
      </tr>
    </thead>
    <tbody id="vitals-table">
      {vitals.map((vital,i) => (
        <VitalTableRow
          key={i}
          readOnly={readOnly}
          vital={vital}
          onClick={onEditVital}
          index={i}
          shiftEndsAtMidnight={shiftEndsAtMidnight}
        />
      ))}
      {!readOnly && (
        <tr>
          <td colSpan="8" className={vitals.length % 2 === 0 ? 'text-center p-4' : 'text-center p-4 bg-gray-100'}>
            <PrimaryButton id="vitals-primary-button" type="button" onClick={onAddVital}><i className="fas fa-plus-circle inline-block mr-1" /> Add vital measurements</PrimaryButton>
          </td>
        </tr>
      )}
    </tbody>
  </table>
)

VitalTable.propTypes = {
  vitals: PropTypes.array.isRequired,
  onEditVital: PropTypes.func,
  onAddVital: PropTypes.func,
  readOnly: PropTypes.bool
}

VitalTable.defaultProps = {
  readOnly: false
}

export default VitalTable

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Checkbox from '../Checkbox'

const activeCheckboxClass = "border border-green-600 bg-green-100 text-green-600"
const passiveCheckboxClass= "border border-gray-300 bg-gray-100 text-gray-800"

const inputClass = "w-full p-2 ml-2 pl-2 outline-none rounded-lg"
const activeInputClass = "border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200"
const passiveInputClass = "border-2 bg-gray-300"

const CheckboxWithInput = ({
  checked,
  checkboxTitle,
  onClick,
  onInputChange,
  className,
  inputName,
  inputPlaceholder,
  inputValue,
  inputMaxLength,
  id
}) => {
  const handleClick = (e) => {
    if(e.detail === 0) {
      e.preventDefault()
      return
    }
    onClick()
  }
  return (
    <button
      type="button"
      onClick={handleClick}
      className="flex items-center text-left focus:outline-none"
      id={id}
    >
      <div className={checked ?
        classnames(className, activeCheckboxClass) :
        classnames(className, passiveCheckboxClass)}>
        <div className="flex items-center mt-2">
          <Checkbox checked={checked} className="text-2xl" />
          {checkboxTitle}
        </div>
        <div className="mr-2">
          <input
            className={checked ?
                        classnames(inputClass, activeInputClass) :
                        classnames(inputClass, passiveInputClass)}
            type="text"
            onClick={e => e.stopPropagation()}
            name={inputName}
            onChange={onInputChange}
            value={checked ? inputValue : ''}
            placeholder={inputPlaceholder}
            maxLength={inputMaxLength}
            disabled={!checked}
          />
          <span className="ml-2">
            <span className="font-bold">{checked ? (inputMaxLength - inputValue.length) : inputMaxLength}</span> characters remaining
          </span>
        </div>
      </div>
    </button>
  )
}

CheckboxWithInput.defaultProps = {
  inputValue: '',
  inputMaxLength: 60
}

CheckboxWithInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  checkboxTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  inputMaxLength: PropTypes.number
}

export default CheckboxWithInput


import merge from 'lodash/merge'
import union from 'lodash/union'
import {APPLICATION_DATA_RESET, SHIFT_REPORT_DELETED, SHIFT_REPORT_IMAGE_CAPTURED} from '../actions';
import pick from "lodash/pick";

const verificationImagesReducer = (state = { byId: {}, allIds: [] }, action) => {
  switch(action.type) {
    case APPLICATION_DATA_RESET:
      return {
        byId: {},
        allIds: [],
      }
    case SHIFT_REPORT_DELETED:
      return Object.assign({}, {
        byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
        allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
      });
    case SHIFT_REPORT_IMAGE_CAPTURED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: {
            ...action.payload
          }
        },
        allIds: union(state.allIds, [action.payload.id])
      })
    default:
      return state
  }
}

export default verificationImagesReducer
import { combineReducers } from 'redux';
import settings from './settings';
import clients from './clients';
import entities from './entities';
import user from './user';

const rootReducer = combineReducers({
  settings,
  clients,
  entities,
  user,
});

export default rootReducer;
import React, { useState } from 'react';
import Modal from '../../../Modal';
import CenterContainer from '../../../layout/CenterContainer';
import { TextArea } from '../../../form/Input';
import SecondaryButton from '../../../SecondaryButton';
import PrimaryButton from '../../../form/PrimaryButton';
import Error from '../../../form/Error'

const ClinicalContinuationNoteForm = ({note, time, onCancel, onSave}) => {
  const [noteBody, setNoteBody] = useState(note.body);
  const [error, setError] = useState(null);

  const handleSave = () => {
    if(noteBody === '') {
      setError('Note cannot be blank');
    } else {
      onSave({
        body: noteBody,
        recordedAt: time
      });
    }
  }

  return (
    <Modal isOpen={true}>
      <CenterContainer>
        <div className="w-1/2 bg-white rounded-lg shadow-lg">
          <div className="p-8 pt-4">
            <h3 className="font-bold text-xl p-2">Nursing Note</h3>
            { error && <Error className="pb-2">{error}</Error>}
            <TextArea
              type="textarea"
              className="h-64 resize-none"
              onChange={(e) => setNoteBody(e.target.value)}
              value={noteBody}
            />
          </div>
          <div className="p-4 px-8 text-right bg-gray-100 rounded-b-lg">
            <SecondaryButton id="confirmation-secondary-button" className="inline-block mr-4" type="button" onClick={onCancel}>Cancel</SecondaryButton>
            <PrimaryButton id="confirmation-primary-button" type="button" onClick={handleSave}>Save</PrimaryButton>
          </div>
        </div>
      </CenterContainer>
    </Modal>
  )
}



export default ClinicalContinuationNoteForm;

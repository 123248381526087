import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../../../form/PrimaryButton'
import PainAssessmentTableRow from './PainAssessmentTableRow'

const PainAssessmentTable = ({
  readOnly,
  painAssessments,
  onEditPainAssessment,
  onAddPainAssessment,
}) => (
  <table className="w-full bg-white rounded-lg shadow-md table--rounded">
    <thead>
      <tr className="bg-gray-100 leading-snug">
        <th className="p-4 text-left align-top">
          <span className="block">Location</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Onset</span>
        </th>
        <th className="p-4 text-left">
          <span className="block">Scale</span>
        </th>
        <th className="p-4 text-left">
          <span className="block">Present Level</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Worst pain gets</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Best pain gets</span>
        </th>
        {!readOnly && (
          <th></th>
        )}
      </tr>
    </thead>
    <tbody id="wounds-table">
      {painAssessments.map((painAssessment,i) => (
        <PainAssessmentTableRow
          key={i}
          readOnly={readOnly}
          painAssessment={painAssessment}
          onClick={onEditPainAssessment}
          index={i}
        />
      ))}
      {!readOnly && (
        <tr>
          <td colSpan="11" className={painAssessments.length % 2 === 0 ? 'text-center p-4' : 'text-center p-4 bg-gray-100'}>
            <PrimaryButton id="wounds-primary-button" type="button" onClick={onAddPainAssessment}><i className="fas fa-plus-circle inline-block mr-1" /> Add Pain Assessment</PrimaryButton>
          </td>
        </tr>
      )}
    </tbody>
  </table>
)

PainAssessmentTable.propTypes = {
  painAssessments: PropTypes.array.isRequired,
  onEditPainAssessment: PropTypes.func,
  onAddPainAssessment: PropTypes.func,
  readOnly: PropTypes.bool
}

PainAssessmentTable.defaultProps = {
  readOnly: false
}

export default PainAssessmentTable

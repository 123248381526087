import { UPDATE_SETTINGS } from '../actions';

const initialState = {
  lastUpdated: null,
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || '',
  apiKey: process.env.REACT_APP_API_KEY || '',
  clientId: process.env.REACT_APP_CLIENT_ID || '',
};

const settings = (state = initialState, action) => {
  switch(action.type) {
    case UPDATE_SETTINGS:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
};

export default settings;
import {
  CLIENT_FETCH_REQUESTED,
  CLIENT_FETCH_SUCCEEDED,
  CLIENT_FETCH_FAILED,
} from '../actions';

const initialState = {
  isFetching: false,
  didInvalidate: false,
  lastUpdated: null,
};

const clients = (state = initialState, action) => {
  switch(action.type) {
    case CLIENT_FETCH_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CLIENT_FETCH_SUCCEEDED:
      return Object.assign({}, state, {
        isFetching: false,
        lastUpdated: action.payload.lastUpdated,
      });
    case CLIENT_FETCH_FAILED:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default clients;

import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import PropTypes from 'prop-types'
import { getClient, getUser } from '../../../../selectors'
import Component from '../../../../components/shiftReports/hha/v1/ShiftReport'
import {
  updateActivity,
  createActivity,
  deleteActivity,
  deletePersonalCareTask,
  createPersonalCareTask,
  updatePersonalCareTask,
  submitHhaShiftReport,
  updateShiftReport,
  captureShiftReportImage,
} from '../../../../actions'
import { sortByDateAsc } from '../../../../utils/sort'
import { getPersonalCareTaskMatrix, getPersonalCareTasks } from '../../../../selectors/personalCareTask';
import {getActivities, getActivityMatrix} from '../../../../selectors/activities';
import ShiftReportModal from '../../../../components/shiftReports/hha/v1/ShiftReportModal';
import { generateTimeRange } from '../../../../utils/timeUtils';
import ActivityValidator from '../../../../utils/activityValidator';
import ShiftReportEndTime from '../../../../components/ShiftReportEndTime';
import {shiftReportTypes} from '../../../../utils/shiftTypes'

const requiredVitalCount = (start, end) => {
  const diff = moment.duration(moment(end).diff(moment(start))).asHours();
  return diff < 12 ? 1 : 2;
}

const requiredPersonalCareTaskCount = (start, end) => {
  const diff = moment.duration(moment(end).diff(moment(start))).asHours();
  return diff < 6 ? 3 : 5;
}

class ShiftReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingEnd: false,
      isConfirming: false,
      validationErrors: [],
      verificationImage: null,
    };
  }

  _validate = () => {
    const errors = this._validatePersonalCareTasks([]);
    return this._validateVitals(this._validateActivities(errors));
  }

  _personalCareTaskCount = () => {
    let count = 0;
    for (let [key] of Object.entries(this.props.personalCareTaskMatrix)) {
      count += Object.entries(this.props.personalCareTaskMatrix[key]).length;
    }
    return count;
  }

  _validatePersonalCareTasks = errors => {
    const count = this._requiredPersonalCareTaskCount()
    if(this._personalCareTaskCount() < count) {
      errors.push(`At least ${count} personal care tasks must be completed.`);
    }

    return errors;
  }

  _validateVitals = errors => {
    if(this.props.vitals.length < this._requiredVitalCount()) {
      errors.push('Make sure you have recorded all required vitals.');
    }

    return errors;
  }

  _validateActivities = errors => {
    const activityTimes = Object.values(this.props.activityMatrix).map(obj => {
      return Object.keys(obj)
    }).flat().sort()

    const validator = new ActivityValidator(this.props.shiftReport.start, this.props.shiftReport.end, activityTimes)

    if(!validator.validate()) {
      errors.push('An activity must be completed (or refused) every 2 hours.');
    }

    return errors;
  }

  _requiredPersonalCareTaskCount = () => {
    return requiredPersonalCareTaskCount(this.props.shiftReport.start, this.props.shiftReport.end)
  }

  _requiredVitalCount = () => {
    return requiredVitalCount(this.props.shiftReport.start, this.props.shiftReport.end);
  }

  _toggleConfirmation = () => {
    this.setState({
      isConfirming: !this.state.isConfirming,
    });
  }

  _handleConfirmation = () => {
    this.setState({
      validationErrors: this._validate(),
      isConfirming: true
    });
  }

  _handleSubmit = () => {
    this.props.updateShiftReport({
      ...this.props.shiftReport,
      recordedAt: moment().format(),
    })
  }

  _deletePersonalCareTasksAfter = (time) => {
    this.props.personalCareTasks.filter(pct => {
      return moment(pct.recordedAt) >= moment(time)
    }).forEach(task => {
      this.props.deletePersonalCareTask(task)
    })
  }

  _deleteActivitiesAfter = (time) => {
    this.props.activities.filter(act => {
      return moment(act.recordedAt) >= moment(time)
    }).forEach(activity => {
      this.props.deleteActivity(activity)
    })
  }

  _handleEndTimeSubmit = (endTime) => {
    this.props.updateShiftReport({
      ...this.props.shiftReport,
      ...endTime,
    })

    this._deletePersonalCareTasksAfter(endTime.end)
    this._deleteActivitiesAfter(endTime.end)

    this.setState({ isEditingEnd: false })
  }

  _captureImage = (image) => {
    console.log("Image Size (kb): ", (new TextEncoder().encode(image)).length / 1024)
    this.props.captureShiftReportImage(this.props.shiftReport.id, image.split(',')[1])
  }

  componentDidMount = () => {
    const shiftReport = this.props.shiftReport;
    if(this.props.user.id !== shiftReport.userId) {
      this.props.history.push(`/shift-report/${shiftReportTypes(shiftReport.type)}/v${shiftReport.version}/${shiftReport.id}/view`)
    }
  }

  componentDidUpdate = prevProps => {
    if(prevProps.shiftReport.verificationImage !== this.props.shiftReport.verificationImage) {
      this._handleSubmit()
    }

    if(prevProps.shiftReport.recordedAt !== this.props.shiftReport.recordedAt)  {
      this.props.submitShiftReport({
        shiftReport: this.props.shiftReport,
        personalCareTasks: this.props.personalCareTasks,
        activities: this.props.activities,
        vitals: this.props.vitals,
        incidentReports: this.props.incidentReports,
        verificationImage: this.props.verificationImage,
      });
      this.props.history.push('/client');
    }
  }

  render = () => (
    <React.Fragment>
      <ShiftReportEndTime
        start={this.props.shiftReport.start}
        end={this.props.shiftReport.end}
        date={moment(this.props.shiftReport.start)}
        onSubmit={this._handleEndTimeSubmit}
        onBackClick={() => this.setState({isEditingEnd: false})}
        isOpen={this.state.isEditingEnd}
      />
      <ShiftReportModal
        errors={this.state.validationErrors}
        user={this.props.user}
        shiftEndTime={this.props.shiftReport.end}
        onSubmit={this._handleSubmit}
        isOpen={this.state.isConfirming}
        onCancel={this._toggleConfirmation}
        onCapture={this._captureImage}
      />
      <Component 
        {...this.props}
        times={generateTimeRange(this.props.shiftReport.start, this.props.shiftReport.end)}
        onEditEndTime={() => this.setState({isEditingEnd: true})}
        onSubmit={this._handleConfirmation} />
    </React.Fragment>
  )
}

ShiftReport.propTypes = {
  user: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  shiftReport: PropTypes.object.isRequired,
  vitals: PropTypes.array.isRequired
}

const mapDispatchToProps = dispatch => ({
  createActivity: (shiftReportId, type, recordedAt, status, comment) => dispatch(createActivity(shiftReportId, type, recordedAt, status, comment)),
  updateActivity: activity => dispatch(updateActivity(activity)),
  deleteActivity: activity => dispatch(deleteActivity(activity)),
  submitShiftReport: (shiftReportObj) => dispatch(submitHhaShiftReport(shiftReportObj)),
  deletePersonalCareTask: personalCareTask => dispatch(deletePersonalCareTask(personalCareTask)),
  createPersonalCareTask: (shiftReportId, type, recordedAt, status, comment) => dispatch(createPersonalCareTask(shiftReportId, type, recordedAt, status, comment)),
  updatePersonalCareTask: personalCareTask => dispatch(updatePersonalCareTask(personalCareTask)),
  updateShiftReport: shiftReport => dispatch(updateShiftReport(shiftReport)),
  captureShiftReportImage: (shiftReportId, image) => { dispatch(captureShiftReportImage(shiftReportId, image)) }
})

const mapStateToProps = (state, props) => ({
  user: getUser(state),
  client: getClient(state),
  shiftReport: state.entities.shiftReports.byId[props.match.params.id],
  vitals: sortByDateAsc(state.entities.shiftReports.byId[props.match.params.id].vitals.map(id => state.entities.vitals.byId[id]), 'recordedAt'),
  personalCareTasks: getPersonalCareTasks(state, { shiftReportId: props.match.params.id }),
  activities: getActivities(state, { shiftReportId: props.match.params.id }),
  incidentReports: state.entities.shiftReports.byId[props.match.params.id].incidentReports.map(id => state.entities.incidentReports.byId[id]),
  personalCareTaskMatrix: getPersonalCareTaskMatrix(state, { shiftReportId: props.match.params.id }),
  activityMatrix: getActivityMatrix(state, { shiftReportId: props.match.params.id }),
  verificationImage: state.entities.shiftReports.byId[props.match.params.id].verificationImage ?
    state.entities.verificationImages.byId[state.entities.shiftReports.byId[props.match.params.id].verificationImage] : null
})

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShiftReport)

export default Container

import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { employeeName } from '../utils/employees'
import {shiftTypeLabels} from '../constants/'

const buildEndTime = (endTime) => {
  return endTime === '0:00' ? '24:00' : endTime
}

const ShiftReportHeader = ({shiftReport, author, children, onEditEndTime, readOnly}) => (
  <div className="bg-white p-4 flex justify-between rounded-lg mb-8 shadow-md">
    <div>
      <span className="block text-gray-600 text-sm">Type</span>
      <span className="block text-2xl font-bold">{shiftTypeLabels[shiftReport.type]}</span>
    </div>
    <div>
      <span className="block text-gray-600 text-sm">From</span>
      <span className="block text-2xl font-bold">{moment(shiftReport.start).format('HH:mm')}</span>
      <span className="block text-gray-600">{moment(shiftReport.start).format('ddd MMM D, YYYY')}</span>
    </div>
    { readOnly ? (
        <div>
          <span className="block text-gray-600 text-sm">Until</span>
          <span className="block text-2xl font-bold">{buildEndTime(moment(shiftReport.end).format('HH:mm'))}</span>
          <span className="block text-gray-600">{moment(shiftReport.end).format('ddd MMM D, YYYY')}</span>
        </div>
      ) : (
        <div onClick={onEditEndTime} className="cursor-pointer">
          <span className="block text-gray-600 text-sm">Until</span>
          <span id="edit-end-time" className="block text-2xl font-bold text-blue-600 flex items-center">
            {buildEndTime(moment(shiftReport.end).format('H:mm'))} <span className="fas fa-pencil text-base ml-1"></span>
          </span>
          <span className="block text-blue-600">{moment(shiftReport.end).format('ddd MMM D, YYYY')}</span>
        </div>
      )
    }
    <div>
      <span className="block text-gray-600 text-sm">Author</span>
      <span className="block text-2xl font-bold">{employeeName(author)}</span>
    </div>
    <div className="flex flex-row items-center justify-center pr-4">
      {children}
    </div>
  </div>
)

ShiftReportHeader.propTypes = {
  shiftReport: PropTypes.object.isRequired,
  author: PropTypes.object.isRequired,
  onEditEndTime: PropTypes.func,
  readOnly: PropTypes.bool,
  children: PropTypes.node
}

ShiftReportHeader.defaultProps = {
  readOnly: false
}

export default ShiftReportHeader;

import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import Webcam from 'react-webcam'
import PrimaryButton from '../components/form/PrimaryButton'
import SecondaryButton from './SecondaryButton'

const videoConstraints = {
  width: 320,
  height: 320,
  facingMode: 'user'
}

const WebcamCapture = ({onCapture, image}) => {
  const webcamRef = useRef(null)
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc)
  }, [webcamRef, onCapture])

  const clearImage = () => onCapture(null)

  return (
    <div className="flex flex-col">
      { image ? (
        <div className="relative mx-auto" style={{ width: '320px', height: '320px' }}>
          <div className="absolute bottom-0 mb-4 text-center w-full">
            <SecondaryButton onClick={clearImage}>
              <span id="photo-retake-button" className="fa fa-redo mr-2"></span> Retake
            </SecondaryButton>
          </div>
          <img id="captured-image" src={image} className="mb-4 rounded-lg" size="320x320" alt="verification" />
        </div>
      ) : (
        <>
          <div className="relative bg-gray-200 rounded-lg mx-auto" style={{ width: '320px', height: '320px' }}>
            <div className="absolute w-full h-full flex self-center items-center justify-center">
              <div className="text-center">
                <i className="fas fa-spinner fa-spin fa-2x"/>
              </div>
            </div>
            <div className="absolute w-full bottom-0 z-20 text-center mb-4">
              <PrimaryButton onClick={capture} className="">
                <span id="photo-capture-button" className="fa fa-camera mr-2"></span> Capture Photo
              </PrimaryButton>
            </div>
          <Webcam
            id="webcam"
            audio={false}
            screenshotQuality={0.75}
            width={320}
            height={320}
            screenshotFormat={'image/jpeg'}
            ref={webcamRef}
            mirrored={true}
            videoConstraints={videoConstraints}
            className="mb-4 rounded-lg z-10"
          />
          </div>
        </>
      )}
    </div>
  )
}

WebcamCapture.propTypes = {
  onCapture: PropTypes.func.isRequired,
  image: PropTypes.string
}

export default WebcamCapture 
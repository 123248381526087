import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../../form/PrimaryButton'
import WoundCareTableRow from './WoundCareTableRow'

const WoundCareTable = ({
  readOnly,
  woundCares,
  onEditWoundCare,
  onAddWoundCare,
}) => (
  <table className="w-full bg-white rounded-lg shadow-md table--rounded">
    <thead>
      <tr className="bg-gray-100 leading-snug">
        <th className="p-4 text-left align-top">
          <span className="block">Location</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Wound-type/stage</span>
        </th>
        <th className="p-4 text-left">
          <span className="block">Cleansed with</span>
        </th>
        <th className="p-4 text-left">
          <span className="block">Packed with</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">LxWxD</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Covered with</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Drainage type/amount</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Secured with</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Malodorous</span>
        </th>
        <th className="p-4 text-left align-top">
          <span className="block">Tunneling</span>
        </th>
        {!readOnly && (
          <th></th>
        )}
      </tr>
    </thead>
    <tbody id="wounds-table">
      {woundCares.map((woundCare,i) => (
        <WoundCareTableRow
          key={i}
          readOnly={readOnly}
          woundCare={woundCare}
          onClick={onEditWoundCare}
          index={i}
        />
      ))}
      {!readOnly && (
        <tr>
          <td colSpan="11" className={woundCares.length % 2 === 0 ? 'text-center p-4' : 'text-center p-4 bg-gray-100'}>
            <PrimaryButton id="wounds-primary-button" type="button" onClick={onAddWoundCare}><i className="fas fa-plus-circle inline-block mr-1" /> Add wound care</PrimaryButton>
          </td>
        </tr>
      )}
    </tbody>
  </table>
)

WoundCareTable.propTypes = {
  woundCares: PropTypes.array.isRequired,
  onEditWoundCare: PropTypes.func,
  onAddWoundCare: PropTypes.func,
  readOnly: PropTypes.bool
}

WoundCareTable.defaultProps = {
  readOnly: false
}

export default WoundCareTable

import React from 'react';
import ReactModal from 'react-modal';

if(document.getElementById('root')) {
  ReactModal.setAppElement('#root');
}

const Modal = props => (
  <ReactModal
    isOpen={props.isOpen}
    className="w-full h-full top-0 left-0 right-0 bottom-0 modal__content"
    overlayClassName="top-0 left-0 right-0 bottom-0 fixed modal__overlay"
    {...props}
  >
    {props.children}
  </ReactModal>
);

export default Modal;
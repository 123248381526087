import React from 'react'
import xor from 'lodash/xor'
import BreathSoundsCheckboxes from './BreathSoundsCheckboxes'

class BreathSoundsTable extends React.Component {
  _handleCheck = (direction, value) => {
    switch(direction) {
    case 'right':
      this._buildValues(this.props.values.breath_sounds_right, value, 'breath_sounds.breath_sounds_right')
      break
    case 'left':
      this._buildValues(this.props.values.breath_sounds_left, value, 'breath_sounds.breath_sounds_left')
      break
    case 'upper':
      this._buildValues(this.props.values.breath_sounds_upper, value, 'breath_sounds.breath_sounds_upper')
      break
    case 'lower':
      this._buildValues(this.props.values.breath_sounds_lower, value, 'breath_sounds.breath_sounds_lower')
      break;
    default:
      return null
    }
  }

  _buildValues = (existingValue, newValue, key) => {
    if(existingValue && !existingValue.includes('clear') && newValue === 'clear') {
      const newValues = existingValue.filter(x => ['coarse', 'diminished'].includes(x)).concat('clear')
      this.props.onCheck(key, newValues)
    } else {
      const newValues = xor(existingValue, [newValue])
      this.props.onCheck(key, newValues)
    }
  }

  render() {
    const { options, values } = this.props

    return (
      <table className="w-full bg-white" >
        <thead >
          <tr >
            <td className="w-2/3 p-4 text-left align-top">
            </td>
            <td className="p-4 text-left align-top">
              Right
            </td>
            <td className="p-4 text-left align-top">
              Left
            </td>
            <td className="p-4 text-left align-top">
              Upper
            </td>
            <td className="p-4 text-left align-top">
              Lower
            </td>
          </tr>
        </thead>
        <tbody>
          {options.map((opt, index) => (
            <BreathSoundsCheckboxes
              key={index}
              index={index}
              formState={values}
              onClick={this._handleCheck}
              onMinorClick={this.props.onCheck}
              value={opt.value}
              label={opt.label}
            />
          ))}
        </tbody>
      </table>
    )
  }
}

export default BreathSoundsTable

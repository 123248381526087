import React from "react";
import { connect } from "react-redux";
import { getClient, getUser } from "../../../../selectors";
import Component from "../../../../components/shiftReports/clinical/v1/intake";
import { createIntake, updateIntake } from "../../../../actions";

class Intake extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      isLoading: true
    }
  
    if(props.intake === null) {
      props.createIntake({shiftReportId: props.shiftReport.id})
    }
  }
  
  render = () => (this.props.intake && <Component {...this.props} />)
}



const mapDispatchToProps = (dispatch) => ({
  createIntake: (intake) => dispatch(createIntake(intake)),
  updateIntake: (intake) => dispatch(updateIntake(intake)),
});

const mapStateToProps = (state, props) => {
  const shiftReport = state.entities.shiftReports.byId[props.match.params.id]
  
  return {
    user: getUser(state),
    client: getClient(state),
    shiftReport: shiftReport,
    intake: shiftReport.intake ? state.entities.intakes.byId[shiftReport.intake] : null
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(Intake);

export default Container;

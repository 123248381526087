import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if(process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: "https://8e4c1d3aa48e4d1a960cf15e91bf2aee@sentry.io/1894800"});
}

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();

import React, { useMemo } from 'react';
import moment from 'moment';
import { generateNoteTimeRange } from '../../../../utils/timeUtils';

const DefaultPlus = ({onClick}) => (
  <button className="text-gray-300 relative" onClick={onClick} >
    <i className="fas fa-plus-circle text-5xl" />
  </button>
)

const SuccessCheck = ({onClick}) => (
  <button className="text-green-600 relative" onClick={onClick} >
    <i className="fas fa-check-circle text-5xl" />
  </button>
)

const ClinicalContinuationNotes = ({shiftReport, clinicalContinuationNotes, onEdit, onAdd}) => {
  const times = useMemo(() => {
    return generateNoteTimeRange(
      shiftReport.start,
      shiftReport.end
    );
  }, [shiftReport]);

  const renderIcon = (time) => {
    const notes = clinicalContinuationNotes;
    const formattedTime = moment(time).format();
    const note = notes.find(obj => obj.recordedAt === formattedTime);

    if(note) {
      return <SuccessCheck onClick={() => onEdit(note.id)} />;
    } else {
      return <DefaultPlus onClick={() => onAdd(time)} />;
    }
  }

  return (
    <table className="w-full bg-white rounded-lg shadow-md table--rounded">
      <thead>
        <tr className="bg-gray-100">
          {times.map(time => (
            <th className="pr-4 pb-4 pt-4" key={time}>
              {moment(time).format('H:mm')}
            </th>
          ))}
        </tr>
      </thead>
      <tbody id="clinical-notes-table">
        <tr>
          {times.map(time => (
            <td className="pr-4 text-center py-2" key={time}>
              { renderIcon(time) }
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default ClinicalContinuationNotes;

import React from "react";
import { connect } from "react-redux";
import { getClient, getUser } from "../../../../selectors";
import { createAssessment, updateAssessment } from "../../../../actions";

const mapDispatchToProps = (dispatch) => ({
  createAssessment: (assessment) => dispatch(createAssessment(assessment)),
  updateAssessment: (assessment) => dispatch(updateAssessment(assessment))
});

const mapStateToProps = (state, props) => {
  const shiftReport = state.entities.shiftReports.byId[props.match.params.id]

  return {
    user: getUser(state),
    client: getClient(state),
    shiftReport: shiftReport,
    assessment: shiftReport.assessment ? state.entities.assessments.byId[shiftReport.assessment] : null
  }
}

const connectAssessment = (WrappedComponent) => {
  class ConnectedAssessment extends React.Component {
    constructor(props) {
      super(props)

      if(props.assessment === null) {
        props.createAssessment({shiftReportId: props.shiftReport.id})
      }
    }

    render = () => <WrappedComponent {...this.props} />
  }

  return connect(mapStateToProps, mapDispatchToProps)(ConnectedAssessment);
}

export default connectAssessment


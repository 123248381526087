import React from "react";
import { connect } from "react-redux";
import { getClient, getUser } from "../../../../selectors";
import Component from "../../../../components/shiftReports/clinical/v1/output";
import { createOutput, updateOutput } from "../../../../actions";

class Output extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      isLoading: true
    }
  
    if(props.output === null) {
      props.createOutput({shiftReportId: props.shiftReport.id})
    }
  }
  
  render = () => (this.props.output && <Component {...this.props} />)
}



const mapDispatchToProps = (dispatch) => ({
  createOutput: (output) => dispatch(createOutput(output)),
  updateOutput: (output) => dispatch(updateOutput(output)),
});

const mapStateToProps = (state, props) => {
  const shiftReport = state.entities.shiftReports.byId[props.match.params.id]
  
  return {
    user: getUser(state),
    client: getClient(state),
    shiftReport: shiftReport,
    output: shiftReport.output ? state.entities.outputs.byId[shiftReport.output] : null
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(Output);

export default Container;

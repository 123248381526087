import { createSelector } from 'reselect'

const getPersonalCareTasksByShiftReportId = (state, props) => state.entities.shiftReports.byId[props.shiftReportId].personalCareTasks.map(id => state.entities.personalCareTasks.byId[id]);

export const getPersonalCareTasks = createSelector(
  [getPersonalCareTasksByShiftReportId], (personalCareTasks) => {
    return personalCareTasks;
  })

export const getPersonalCareTaskMatrix = createSelector(
  [getPersonalCareTasks], (personalCareTasks) => {
  const personalCareTaskMatrix = []
  personalCareTasks
    .forEach(personalCareTask => {
      if(personalCareTaskMatrix[personalCareTask.type] === undefined) {
        personalCareTaskMatrix[personalCareTask.type] = [];
      }
      personalCareTaskMatrix[personalCareTask.type][personalCareTask.recordedAt] = personalCareTask;
    })

  return personalCareTaskMatrix
})

import React from 'react'
import Header from '../../../Header'
import Container from '../../../layout/Container'
import ShiftReportHeader from '../../../ShiftReportHeader'

const AssessmentLayout = (props) => {
  return (
    <Container>
      <Header client={props.client} user={props.user} />
      <div className="p-8">
        <ShiftReportHeader
          shiftReport={props.shiftReport}
          author={props.user}
          readOnly={true}
          onEditEndTime={null}
        >
        </ShiftReportHeader>
        {props.children}
      </div>
    </Container>
  );
}


export default AssessmentLayout;

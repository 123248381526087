import moment from 'moment'
import uuidv4 from 'uuid/v4'
import { generateShiftReport } from '../utils/generateShiftReport'
import { SHIFT_REPORT_STATUS_PENDING } from '../constants'
import {
  serializeHhaShiftReport,
  serializeClinicalShiftReport
} from "../serializer/shiftReport";
import { assessmentFields} from '../constants'


// Settings

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const updateSettings = settings => ({
  type: UPDATE_SETTINGS,
  payload: {
    ...settings,
    lastUpdated: Date.now(),
  },
})


// Vitals

export const VITALS_CREATED = 'VITALS_CREATED';
export const createVitals = vitals => ({
  type: VITALS_CREATED,
  payload: {
    ...vitals,
    id: uuidv4(),
    lastUpdated: Date.now(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  }
})

export const VITALS_UPDATED = 'VITALS_UPDATED';
export const updateVitals = vitals => ({
  type: VITALS_UPDATED,
  payload: {
    ...vitals,
    lastUpdated: Date.now(),
    updatedAt: moment().format(),
  },
})

// Wound Care

export const WOUND_CARE_CREATED = 'WOUND_CARE_CREATED';
export const createWoundCare = woundCares => ({
  type: WOUND_CARE_CREATED,
  payload: {
    ...woundCares,
    id: uuidv4(),
    recordedAt: moment().format(),
    lastUpdated: Date.now(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  }
})

export const WOUND_CARE_UPDATED = 'WOUND_CARE_UPDATED';
export const updateWoundCare = woundCares => ({
  type: WOUND_CARE_UPDATED,
  payload: {
    ...woundCares,
    lastUpdated: Date.now(),
    updatedAt: moment().format(),
  },
})

// PAIN ASSESSMENTS

export const PAIN_ASSESSMENT_CREATED = 'PAIN_ASSESSMENT_CREATED';
export const createPainAssessment = painAssessments => ({
  type: PAIN_ASSESSMENT_CREATED,
  payload: {
    ...painAssessments,
    id: uuidv4(),
    recordedAt: moment().format(),
    lastUpdated: Date.now(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  }
})

export const PAIN_ASSESSMENT_UPDATED = 'PAIN_ASSESSMENT_UPDATED';
export const updatePainAssessment = painAssessments => ({
  type: PAIN_ASSESSMENT_UPDATED,
  payload: {
    ...painAssessments,
    lastUpdated: Date.now(),
    updatedAt: moment().format(),
  },
})


// Actions

// Reset all
export const APPLICATION_DATA_RESET = 'APPLICATION_RESET';
export const resetApplicationData = () => ({
  type: APPLICATION_DATA_RESET,
})

// Personal Care Tasks
export const PERSONAL_CARE_TASK_CREATED = 'PERSONAL_CARE_TASK_CREATED';
export const createPersonalCareTask = (shiftReportId, type, recordedAt, status, comment) => ({
  type: PERSONAL_CARE_TASK_CREATED,
  payload: {
    id: uuidv4(),
    shiftReportId,
    type,
    comment,
    recordedAt,
    status,
    createdAt: moment().format(),
    updatedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})

export const PERSONAL_CARE_TASK_UPDATED = 'PERSONAL_CARE_TASK_UPDATED';
export const updatePersonalCareTask = personalCareTask => ({
  type: PERSONAL_CARE_TASK_UPDATED,
  payload: {
    ...personalCareTask,
    updatedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})

export const PERSONAL_CARE_TASK_DELETED = 'PERSONAL_CARE_TASK_DELETED';
export const deletePersonalCareTask = personalCareTask => ({
  type: PERSONAL_CARE_TASK_DELETED,
  payload: {
    personalCareTask,
    deletedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})


// Activities

export const ACTIVITY_CREATED = 'ACTIVITY_CREATED';
export const createActivity = (shiftReportId, type, recordedAt, status, comment) => ({
  type: ACTIVITY_CREATED,
  payload: {
    id: uuidv4(),
    shiftReportId,
    type,
    recordedAt,
    status,
    comment,
    createdAt: moment().format(),
    updatedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})

export const ACTIVITY_UPDATED = 'ACTIVITY_UPDATED';
export const updateActivity = activity => ({
  type: ACTIVITY_UPDATED,
  payload: {
    ...activity,
    updatedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})

export const ACTIVITY_DELETED = 'ACTIVITY_DELETED';
export const deleteActivity = activity => ({
  type: ACTIVITY_DELETED,
  payload: {
    activity,
    deletedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})

// Nursing Notes
//
export const CLINICAL_CONTINUATION_NOTE_CREATED = 'CLINICAL_CONTINUATION_NOTE_CREATED';
export const createCCN = ({shiftReportId, body, recordedAt}) => ({
  type: CLINICAL_CONTINUATION_NOTE_CREATED,
  payload: {
    id: uuidv4(),
    shiftReportId,
    body,
    recordedAt,
    createdAt: moment().format(),
    updatedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})

export const CLINICAL_CONTINUATION_NOTE_UPDATED = 'CLINICAL_CONTINUATION_NOTE_UPDATED';
export const updateCCN = ccn => ({
  type: CLINICAL_CONTINUATION_NOTE_UPDATED,
  payload: {
    ...ccn,
    updatedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})

export const CLINICAL_CONTINUATION_NOTE_DELETED = 'CLINICAL_CONTINUATION_NOTE_DELETED';
export const deleteCCN = clinicalContinuationNote => ({
  type: CLINICAL_CONTINUATION_NOTE_DELETED,
  payload: {
    clinicalContinuationNote,
    deletedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})


// Incident Reports
export const INCIDENT_REPORT_CREATED = 'INCIDENT_REPORT_CREATED'
export const createIncidentReport = (reportObj) => {
  const {
    shiftReportId,  
    type,
    notified,
    comment,
    recordedAt
  } = reportObj

  return {
    type: INCIDENT_REPORT_CREATED,
    payload: {
      id: uuidv4(),
      shiftReportId,
      type,
      notified,
      comment,
      recordedAt,
      createdAt: moment().format(),
      updatedAt: moment().format(),
      lastUpdated: Date.now()
    }
  }
} 

export const INCIDENT_REPORT_UPDATED = 'INCIDENT_REPORT_UPDATED'
export const updateIncidentReport = incidentReport => ({
  type: INCIDENT_REPORT_UPDATED,
  payload: {
    ...incidentReport,
    updatedAt: moment().format(),
    lastUpdated: Date.now()
  }
})

export const INCIDENT_REPORT_DELETED = 'INCIDENT_REPORT_DELETED'
export const deleteIncidentReport = id => ({
  type: INCIDENT_REPORT_DELETED,
  payload: {
    id,
    deletedAt: moment().format(),
    lastUpdated: Date.now()
  }
})

// Assessments
export const ASSESSMENT_CREATED = 'ASSESSMENT_CREATED'
export const createAssessment = (obj) => {
  const { shiftReportId } = obj

  return {
    type: ASSESSMENT_CREATED,
    payload: {
      id: uuidv4(),
      shiftReportId,
      ...assessmentFields,
      lastUpdated: Date.now()
    }
  }
}

export const ASSESSMENT_UPDATED = 'ASSESSMENT_UPDATED'
export const updateAssessment = assessment => ({
  type: ASSESSMENT_UPDATED,
  payload: {
    ...assessment,
    lastUpdated: Date.now()
  }
})

export const ASSESSMENT_DELETED = 'ASSESSMENT_DELETED'
export const deleteAssessment = id => ({
  type: ASSESSMENT_DELETED,
  payload: {
    id,
    deletedAt: moment().format(),
    lastUpdated: Date.now()
  }
})

// Intakes
export const INTAKE_CREATED = 'INTAKE_CREATED'
export const createIntake = intake => ({
  type: INTAKE_CREATED,
  payload: {
    ...intake,
    id: uuidv4(),
    lastUpdated: Date.now(),
  }
})

export const INTAKE_UPDATED = 'INTAKE_UPDATED'
export const updateIntake = intake => ({
  type: INTAKE_UPDATED,
  payload: {
    ...intake,
    lastUpdated: Date.now()
  }
})

export const INTAKE_DELETED = 'INTAKE_DELETED'
export const deleteIntake = id => ({
  type: INTAKE_DELETED,
  payload: {
    id,
    deletedAt: moment().format(),
    lastUpdated: Date.now()
  }
})

// Outputs
export const OUTPUT_CREATED = 'OUTPUT_CREATED'
export const createOutput = output => ({
  type: OUTPUT_CREATED,
  payload: {
    ...output,
    id: uuidv4(),
    lastUpdated: Date.now(),
  }
})

export const OUTPUT_UPDATED = 'OUTPUT_UPDATED'
export const updateOutput = output => ({
  type: OUTPUT_UPDATED,
  payload: {
    ...output,
    lastUpdated: Date.now()
  }
})

export const OUTPUT_DELETED = 'OUTPUT_DELETED'
export const deleteOutput = id => ({
  type: OUTPUT_DELETED,
  payload: {
    id,
    deletedAt: moment().format(),
    lastUpdated: Date.now()
  }
})

// Shift Reports

export const SHIFT_REPORT_CREATED = 'SHIFT_REPORT_CREATED';
export const createShiftReport = (userId, clientId, shiftStart, shiftEnd, shiftType) => ({
  type: SHIFT_REPORT_CREATED,
  payload: generateShiftReport(userId, clientId, shiftStart, shiftEnd, shiftType)
})

export const SHIFT_REPORT_IMAGE_CAPTURED = 'SHIFT_REPORT_IMAGE_CAPTURED'
export const captureShiftReportImage = (shiftReportId, image) => ({
  type: SHIFT_REPORT_IMAGE_CAPTURED,
  payload: {
    id: uuidv4(),
    shiftReportId: shiftReportId,
    contents: image,
    createdAt: moment().format(),
    updatedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})

export const SHIFT_REPORT_UPDATED = 'SHIFT_REPORT_UPDATED';
export const updateShiftReport = shiftReport => ({
  type: SHIFT_REPORT_UPDATED,
  payload: {
    ...shiftReport,
    lastUpdated: Date.now(),
  },
})

export const SHIFT_REPORT_DELETED = 'SHIFT_REPORT_DELETED';
export const deleteShiftReport = (id, clientId) => ({
  type: SHIFT_REPORT_DELETED,
  payload: {
    id,
    clientId,
    deletedAt: moment().format(),
    lastUpdated: Date.now(),
  }
})


// Clients

export const CLIENT_FETCH_REQUESTED = 'CLIENT_FETCH_REQUESTED';
export const CLIENT_FETCH_SUCCEEDED = 'CLIENT_FETCH_SUCCEEDED';
export const CLIENT_FETCH_FAILED = 'CLIENT_FETCH_FAILED';
const clientFetchRequested = id => ({
  type: CLIENT_FETCH_REQUESTED,
  payload: {
    id,
  }
})

export const fetchClientIfNeeded = id => (
  clientFetchRequested(id)
)


// Shift Report
export const FETCH_CLIENT_SHIFT_REPORTS_REQUESTED = 'FETCH_CLIENT_SHIFT_REPORTS_REQUESTED';
export const FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED = 'FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED';
export const FETCH_CLIENT_SHIFT_REPORTS_FAILED = 'FETCH_CLIENT_SHIFT_REPORTS_FAILED';
export const fetchShiftReportsByClientId = clientId => ({
  type: FETCH_CLIENT_SHIFT_REPORTS_REQUESTED,
  payload: {
    clientId: clientId,
  }
});

export const POST_SHIFT_REPORT_REQUESTED = 'POST_SHIFT_REPORT_REQUESTED';
export const POST_SHIFT_REPORT_SUCCEEDED = 'POST_SHIFT_REPORT_SUCCEEDED';
export const POST_SHIFT_REPORT_FAILED = 'POST_SHIFT_REPORT_FAILED';
export const submitHhaShiftReport = (shiftReportObj) => {
  const { shiftReport, ...rest } = shiftReportObj
  return {
    type: POST_SHIFT_REPORT_REQUESTED,
    payload: {
      id: shiftReport.id,
      status: SHIFT_REPORT_STATUS_PENDING,
      lastUpdated: Date.now(),
      serialized: serializeHhaShiftReport({
        shiftReport,
        ...rest
      }),
    }
  }
}

export const submitClinicalShiftReport = (shiftReportObj) => {
  const { shiftReport, ...rest} = shiftReportObj
  return {
    type: POST_SHIFT_REPORT_REQUESTED,
    payload: {
      id: shiftReport.id,
      status: SHIFT_REPORT_STATUS_PENDING,
      lastUpdated: Date.now(),
      serialized: serializeClinicalShiftReport({
        shiftReport,
        ...rest
      })
    }
  }
}

// User

export const UPDATE_USER = 'UPDATE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const updateUser = user => ({
  type: UPDATE_USER,
  payload: {
    ...user,
    lastUpdated: Date.now()
  }
})

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: {
    id: null,
    lastUpdated: Date.now()
  }
})

import React from "react";
import { connect } from "react-redux";
import Component from "../components/Home";
import { fetchClientIfNeeded, updateUser } from "../actions";
import Loader from "../components/Loader";
import { getClient, getEmployees } from "../selectors";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRefreshed: false,
    };
  }
  componentDidMount = () => {
    if (this.props.clientId === "") {
      this.props.history.push("/settings");
    } else {
      this.props.fetchClientIfNeeded(this.props.clientId);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.lastUpdated !== this.props.lastUpdated) {
      this.props.history.push("/client");
    }
  };

  _sortedEmployees = () => {
    return this.props.employees.sort((a, b) => {
      if (a.lastName < b.lastName) {
        return -1;
      } else if (a.lastName > b.lastName) {
        return 1;
      }
      return 0;
    });
  };

  _options = () => {
    return this._sortedEmployees().map(employee => {
      return {
        value: employee.id,
        label: employee.lastName + ', ' + employee.firstName + ' (' + employee.role + ')',
      }
    });
  };

  _handleSubmit = (userId) => {
    this.props.updateUser({
      id: userId,
    });
  };

  _refreshUsers = (e) => {
    this.props.fetchClientIfNeeded(this.props.clientId);
    this.setState({
      isRefreshed: true,
    });
  };

  render = () => (
    <>
      {this.props.isFetching && <Loader>Getting client information...</Loader>}
      <Component
        options={this._options()}
        onSubmit={this._handleSubmit}
        refreshUsers={this._refreshUsers}
        isRefreshed={this.state.isRefreshed}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchClientIfNeeded: (id) => dispatch(fetchClientIfNeeded(id)),
  updateUser: (user) => dispatch(updateUser(user)),
});

const mapStateToProps = (state) => ({
  clientId: state.settings.clientId,
  client: getClient(state),
  lastUpdated: state.user.lastUpdated,
  employees: getEmployees(state) || [],
  isFetching: state.clients.isFetching,
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Home);

export default Container;

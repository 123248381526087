import moment from "moment";

/**
 * This function generates an array of ISO8601 datetime strings, either one or two hours apart, from one hour after
 * a start time to an end time.
 * @param startTime
 * @param endTime
 * @returns {[]}
 */
export const generateTimeRange = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const diff = moment.duration(end.diff(start)).asHours();
  const times = [];

  let now = start.clone();
  while (now < end) {
    times.push(now.format());
    now = now.clone().add(diff > 4 ? 2 : 1, "hours");
  }

  return times;
};

export const generateNoteTimeRange = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const diff = moment.duration(end.diff(start)).asHours();
  const times = [];

  times.push(start.clone().format())

  if(diff > 2) {
    let now = start.clone();
    while(now < end) {
      now = now.clone().add(2, 'hours')
      if(now.isAfter(end)) {
        times.push(end.clone().format())
      } else {
        times.push(now.format())
      }
    }
  } else {
    const last = end.clone();

    times.push(last.format())
  }

  return times
}

export const isMidnight = (time) => {
  return moment(time).format("H:mm") === "0:00";
};

export const renderMidnight = (endTime) => {
  const time = moment(endTime).format("H:mm");
  return time === "0:00" ? "24:00" : time;
};

/**
 * This function generates an array of objects to be used
 * to generate a TimeSelect dropdown.  Each object contains
 * an ISO8601 datetime string and a label.  Each datetime is
 * 15 minutes from the previous one, and if the last one is
 * midnight, it will read "24:00"
 *
 * @param startTime
 * @param endTime
 * @returns {[]}
 */
export const timeOptions = (startTime, endTime, twelveHourFormat = false) => {
  const options = [];
  const first = moment(startTime);
  const last = moment(endTime);

  let now = first;
  while (now <= last) {
    options.push({
      value: now.format(),
      label: twelveHourFormat ? now.format("h:mma") : now.format('H:mm'),
    });

    now = now.clone().add(15, "minutes");
  }

  const lastIndex = options.length - 1;
  if (options[lastIndex].label === "0:00") {
    options[lastIndex].label = "24:00";
  }

  return options;
};

export const shiftTimeOptions = (startTime, endTime) => {
  const options = [];
  const first = moment(startTime);
  const last = moment(endTime);

  let now = first;
  while (now <= last) {
    options.push({
      value: now.format(),
      label: now.format("h:mma"),
    });

    now = now.clone().add(15, "minutes");
  }

  const lastIndex = options.length - 1;
  if (options[lastIndex].label === "0:00") {
    options[lastIndex].label = "24:00";
  }

  return options;
};

export const timeOptionSelect = (time) => {
  return {
    value: moment(time).format(),
    label: moment(time).format("h:mma")
  }
}

export const minSinceMidnight = (time) => {
  const momentTime = moment(time);
  const momentMidnight = momentTime.clone().startOf("day");
  const diffMinutes = momentTime.diff(momentMidnight, "minutes");
  return parseInt(diffMinutes);
};

export const numHours = (date, start, end) => {
  return moment
    .duration(getDate(date, end).diff(getDate(date, start)))
    .asHours();
};

export const toMinutes = (time) => {
  return parseInt((time % 1440) % 60, 10);
};

export const toHours = (time) => {
  return parseInt(((time % 1440) / 60) % 24, 10);
};

export const getTime = (hours, minutes, position, twelveHourFormat = false) => {
  hours = hours + "";
  minutes = minutes + "";

  if (hours === 0 && position === "end") {
    hours = 24;
  }

  if (hours.length === 1) {
    hours = "0" + hours;
  }

  if (minutes.length === 1) {
    minutes = "0" + minutes;
  }

  return hours + ":" + minutes;
};

export const getDate = (date, time) => {
  return time < 1440
    ? date.clone().set({
        hour: toHours(time),
        minute: toMinutes(time),
        second: 0,
      })
    : date
        .clone()
        .add(1, "days")
        .set({
          hour: toHours(time),
          minute: toMinutes(time),
          second: 0,
        });
};

export const checkTime = (endTime) => {
  const currentTime = moment(Date.now());
  const endTimeMinusThirty = moment(endTime).clone().subtract(30, "minutes");
  return currentTime.isBefore(endTimeMinusThirty);
};

export const compareTimes = (endTime, currentTime) => {
  return moment.duration(moment(endTime).diff(moment(currentTime)));
};

import React from 'react'
import moment from 'moment'
import omit from 'lodash/omit';
import {
  basicAssessmentMappings,
  digestiveAssessmentMappings,
  cardiovascularAssessmentMappings,
  pulmonaryAssessmentMappings
} from '../../../../constants'
import {
  getCheckedValues,
  assessmentComplete
} from '../../../../utils/assessmentUtils'
import {
  edemaGradeOptions,
  pulsesStrengthOptions
} from './CardiovascularAssessmentForm'
import {
  sputumAmountOptions,
  sputumColorOptions,
  oxygenDeliveryMethodOptions,
  breathSoundsOptions
} from './PulmonaryAssessmentForm'

const compactJoin = (array, joinString) => {
  return array.filter(x => x).join(joinString)
}

const checkAssessmentComplete = WrappedComponent => props => {
  return assessmentComplete(props.assessment) && <WrappedComponent {...props} />
}

// Basic Summary Components
// ========================

const DispositionSummary = ({assessment}) => {
  const checkedValues = getCheckedValues(omit(assessment, ['disposition_happiness']), basicAssessmentMappings)
  const happiness = {
    'happy': 'Happy',
    'sad': 'Sad'
  }[assessment.disposition_happiness]

  return compactJoin([checkedValues, happiness], ', ')
}

// Digestive Summary Components
// ============================

const BowelSummary = ({assessment}) => {
  const checkedValues = getCheckedValues(omit(assessment, ['bowels_continence', 'bowels_last_bm']), digestiveAssessmentMappings)
  const continence = {
    'continent': 'Continent',
    'incontinent': 'Incontinent'
  }[assessment.bowels_continence]

  return (
    <div id="bowel_summary">
      <div>{compactJoin([checkedValues, continence], ', ')}</div>
      <div>Last BM {moment(assessment.bowels_last_bm).format('MMM D')}</div>
    </div>
  )
}

const BowelSoundsSummary = ({assessment}) => (
  <div>
    <div>RUQ: {assessment.bowel_sounds_ruq_audible} | {assessment.bowel_sounds_ruq_activity}</div>
    <div>RLQ: {assessment.bowel_sounds_rlq_audible} | {assessment.bowel_sounds_rlq_activity}</div>
    <div>LUQ: {assessment.bowel_sounds_luq_audible} | {assessment.bowel_sounds_luq_activity}</div>
    <div>LLQ: {assessment.bowel_sounds_llq_audible} | {assessment.bowel_sounds_llq_activity}</div>
  </div>
)

const BladderSummary = ({assessment}) => {
  const omittedFields = [
    'bladder_continence',
    'bladder_catheter_care',
    'bladder_catheter_location',
    'bladder_catheter_type',
    'bladder_catheter_size',
    'bladder_catheter_insertion_date',
    'bladder_catheter_change_frequency',
    'bladder_catheter_change_frequency_value'
  ]
  const checkedValues = getCheckedValues(omit(assessment, omittedFields), digestiveAssessmentMappings)
  const continence = {
    'continent': 'Continent',
    'incontinent': 'Incontinent'
  }[assessment.bladder_continence]

  return (
    <div>
      <div>{compactJoin([continence, checkedValues], ', ')}</div>
      <div >{assessment.bladder_catheter_care && (
        <div>
          <div>
            Catheter: {assessment.bladder_catheter_location} / {assessment.bladder_catheter_location} / {assessment.bladder_catheter_size}
          </div>
          <div>Inserted {assessment.bladder_catheter_insertion_date}</div>
          <div>Change Frequency: {assessment.bladder_catheter_change_frequency_value}</div>
        </div>
      )}</div>
    </div>
  )
}

const AbdomenSummary = ({assessment}) => {
  const size = {
    'flat': 'Flat',
    'distended': 'Distended',
    'round': 'Round'
  }[assessment.abdomen_size]
  const firmness = {
    'soft': 'Soft',
    'hard': 'Hard',
    'tender': 'Tender'
  }[assessment.abdomen_firmness]
  return (
    <div>
      <div>{compactJoin([size, firmness, assessment.abdomen_mass_value, assessment.abdomen_feeding_tube_value], ', ')}</div>
      {assessment.abdomen_girth !== '' && (<div>Girth: {assessment.abdomen_girth} inches</div>)}
    </div>
  )
}

// Cardiovascular Summary Components
// =================================

const ApicalSummary = ({assessment}) => {
  const apicalString = {
    'regular': 'Regular',
    'irregular': 'Irregular'
  }[assessment.apical_rhythm]

  return apicalString
}

const EdemaSummary = ({assessment}) => {
  const pittingString = {
    'pitting': 'Pitting',
    'non_pitting': 'Non-Pitting'
  }[assessment.edema_pitting]
  const jvdString = assessment.edema_jvd && cardiovascularAssessmentMappings.edema_jvd

  const grade = edemaGradeOptions.find(x => x.value === assessment.edema_grade)

  return (
    <div>
      {compactJoin([jvdString, pittingString], ', ')}
      <div>Location: {assessment.edema_location}</div>
    <div>Grade: {grade ? grade.label : ''}</div>
    </div>
  )
}

const CapillaryRefillSummary = ({assessment}) => {
  const timingString = {
    'lt_three': '< 3 Seconds',
    'gt_three': '> 3 Seconds'
  }[assessment.capillary_refill_timing]
  const clubbingString = assessment.capillary_refill_clubbing && cardiovascularAssessmentMappings.capillary_refill_clubbing

  return (
    <div>
      {compactJoin([timingString, clubbingString], ', ')}
    </div>
  )
}

const PulsesSummary = ({assessment, pulseType}) => {
  const presenceMapping = {
    'present': 'Present',
    'absent': 'Absent'
  }
  const presenceLeft = presenceMapping[assessment[`${pulseType}_pulses_presence_left`]]
  const presenceRight = presenceMapping[assessment[`${pulseType}_pulses_presence_right`]]
  const strengthLeft = pulsesStrengthOptions.find(x => x.value === assessment[`${pulseType}_pulses_strength_left`])
  const strengthRight = pulsesStrengthOptions.find(x => x.value === assessment[`${pulseType}_pulses_strength_right`])

  return (
    <div>
      <div >Left: {presenceLeft}{strengthLeft ? `, ${strengthLeft.label}` : ''}</div>

      <div >Right: {presenceRight}{strengthRight ? `, ${strengthRight.label}` : ''}</div>
    </div>
  )
}

// Pulmonary Summary Components
// ============================

const ChestSummary = ({assessment}) => {
  const chestExpansionString = {
    'symmetrical': 'Symmetrical',
    'asymmetrical': 'Asymmetrical'
  }[assessment.chest_expansion_symmetry]
  const barrelString = assessment.chest_expansion_barrel && pulmonaryAssessmentMappings.chest_expansion_barrel

  return <div>{compactJoin([chestExpansionString, barrelString], ', ')}</div>
}

const BreathSoundsSummary = ({assessment}) => {
  const lookup = (value) => {
    return breathSoundsOptions.find(x => x.value === value)
  }

  const right = assessment.breath_sounds_right.map(x => lookup(x))
  const left = assessment.breath_sounds_left.map(x => lookup(x))
  const upper = assessment.breath_sounds_upper.map(x => lookup(x))
  const lower = assessment.breath_sounds_lower.map(x => lookup(x))

  return (
    <div >
      <div>Right: {compactJoin(right.map(x => x.label), ', ')}</div>
      <div>Left: {compactJoin(left.map(x => x.label), ', ')}</div>
      <div>Upper: {compactJoin(upper.map(x => x.label), ', ')}</div>
      <div>Lower: {compactJoin(lower.map(x => x.label), ', ')}</div>
    </div>
  )
}

const RespiratorySummary = ({assessment}) => {
  const respiratoryDepthString = {
    'deep': 'Deep',
    'shallow': 'Shallow'
  }[assessment.respiratory_depth]
  const accessoryString = assessment.respiratory_accessory && pulmonaryAssessmentMappings.respiratory_accessory

  return <div>{compactJoin([respiratoryDepthString, accessoryString], ', ')}</div>
}

const QualitySummary = ({assessment}) => {
  return <div>{getCheckedValues(assessment, pulmonaryAssessmentMappings)}</div>
}

const PatternSummary = ({assessment}) => {
  const patternString = {
    'labored': 'Labored',
    'unlabored': 'Ulabored'
  }[assessment.pattern_labor]

  return <div>{patternString}</div>
}

const CoughSummary = ({assessment}) => {
  const presenceString = {
    'absent': 'Absent',
    'present': 'Present'
  }[assessment.cough_presence]

  const productiveString = {
    'productive': 'Productive',
    'non_productive': 'Non-Productive'
  }[assessment.cough_productive]

  const wetnessString = {
    'wet': 'Wet',
    'dry': 'Dry'
  }[assessment.cough_wetness]

  const hackingString = assessment.cough_hacking && pulmonaryAssessmentMappings.cough_hacking

  return <div>{compactJoin([presenceString, productiveString, wetnessString, hackingString], ', ')}</div>
}

const SputumSummary = ({assessment}) => {
  const thicknessString = {
    'thin': 'Thin',
    'thick': 'Thick'
  }[assessment.sputum_thickness]
  const odorString = assessment.sputum_foul_odor && pulmonaryAssessmentMappings.sputum_foul_odor

  const amount = sputumAmountOptions.find(x => x.value === assessment.sputum_amount)
  const color = sputumColorOptions.find(x => x.value === assessment.sputum_color)

  return (
    <div>
      <div>{compactJoin([thicknessString, odorString], ', ')}</div>
      <div>Amount: {amount ? amount.label : ''}</div>
      <div>Color: {color ? color.label : ''}</div>
    </div>
  )
}

const OxygenSummary = ({assessment}) => {
  const liters = `${assessment.oxygen_liters} L`
  const deliveryMethod = oxygenDeliveryMethodOptions.find(x => x.value === assessment.oxygen_delivery_method)
  const continuity = {
    'continuous': 'Continuous',
    'prn': 'PRN'
  }[assessment.oxygen_continuity]

  return (
    <div>
      <div>{assessment.oxygen_administered && compactJoin([liters, deliveryMethod.label, continuity], ', ')}</div>
      <div>
        {assessment.oxygen_trach && `Trach: ${assessment.oxygen_trach_value}`}
      </div>
      <div>
        {assessment.oxygen_cpap && `CPAP: ${assessment.oxygen_cpap_value}`}
      </div>
      <div>
        {assessment.oxygen_bipap && `BI-PAP: ${assessment.oxygen_bipap_value}`}
      </div>
      <div>
        {assessment.oxygen_apap && `APAP: ${assessment.oxygen_apap_value}`}
      </div>

    </div>
  )
}

export const Summaries = {
  Disposition: checkAssessmentComplete(DispositionSummary),
  Bowel: checkAssessmentComplete(BowelSummary),
  BowelSounds: checkAssessmentComplete(BowelSoundsSummary),
  Bladder: checkAssessmentComplete(BladderSummary),
  Abdomen: checkAssessmentComplete(AbdomenSummary),
  Apical: checkAssessmentComplete(ApicalSummary),
  Edema: checkAssessmentComplete(EdemaSummary),
  CapillaryRefill: checkAssessmentComplete(CapillaryRefillSummary),
  Pulses: checkAssessmentComplete(PulsesSummary),
  Chest: checkAssessmentComplete(ChestSummary),
  BreathSounds: checkAssessmentComplete(BreathSoundsSummary),
  Respiratory: checkAssessmentComplete(RespiratorySummary),
  Quality: checkAssessmentComplete(QualitySummary),
  Pattern: checkAssessmentComplete(PatternSummary),
  Cough: checkAssessmentComplete(CoughSummary),
  Sputum: checkAssessmentComplete(SputumSummary),
  Oxygen: checkAssessmentComplete(OxygenSummary)
}

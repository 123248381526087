import React from 'react'
import pick from 'lodash/pick';
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import SecondaryButton from '../../../SecondaryButton'
import SaveButton from '../../../form/SaveButton'
import FormCheckbox from '../../../form/FormCheckbox'
import Error from '../../../form/Error'
import {
  basicDispositionFields,
  basicHeadFields,
  basicEyeFields,
  basicEarFields,
  basicNoseFields,
  basicMouthFields,
  basicTongueFields
} from '../../../../utils/assessmentUtils'
import { CONTINUE, EXIT, basicAssessmentMappings } from '../../../../constants'
import AssessmentCheckboxes from './AssessmentCheckboxes'
import { validationSchema } from './basicAssessmentValidations'

class BasicAssessmentForm extends React.Component {
  constructor(props) {
    super(props)

    this.initialValues = {
      disposition: {
        ...pick(props.assessment, basicDispositionFields),
      },
      head: {
        ...pick(props.assessment, basicHeadFields)
      },
      eyes: {
        ...pick(props.assessment, basicEyeFields)
      },
      ears: {
        ...pick(props.assessment, basicEarFields)
      },
      nose: {
        ...pick(props.assessment, basicNoseFields)
      },
      mouth: {
        ...pick(props.assessment, basicMouthFields)
      },
      tongue: {
        ...pick(props.assessment, basicTongueFields)
      }
    }

    this.dispositionFields = basicDispositionFields;
    this.headFields = basicHeadFields;
    this.eyeFields = basicEyeFields;
    this.earFields = basicEarFields;
    this.noseFields = basicNoseFields;
    this.mouthFields = basicMouthFields;
    this.tongueFields = basicTongueFields;
    this.formRef = React.createRef();

    this.state = {
      submitType: CONTINUE
    }
  }

  _handleSubmit = (values) => {
    // Ensure we don't submit a value if the "other" checkbox
    // is not checked
    if(!values.head.head_other) {
      values.head.head_other_value = '';
    }

    if(!values.eyes.eyes_other) {
      values.eyes.eyes_other_value = '';
    }

    if(!values.ears.ears_other) {
      values.ears.ears_other_value = '';
    }

    if(!values.nose.nose_other) {
      values.nose.nose_other_value = '';
    }

    if(!values.mouth.mouth_other) {
      values.mouth.mouth_other_value = '';
    }

    if(!values.tongue.tongue_other) {
      values.tongue.tongue_other_value = '';
    }

    this.props.onSubmit({
      id: this.props.assessment.id,
      ...values.disposition,
      ...values.head,
      ...values.eyes,
      ...values.ears,
      ...values.nose,
      ...values.mouth,
      ...values.tongue
    });

    if(this.state.submitType === CONTINUE) {
      this.props.onContinue()
    } else {
      this.props.onCancel()
    }
  }

  render() {
    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onSubmit={this._handleSubmit}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          setFieldValue,
          submitForm,
          isSubmitting,
          isValidating
        }) => {

        if (Object.keys(errors).length > 0 && isSubmitting && !isValidating) {
          this.formRef.current.scrollIntoView({behavior: 'smooth'});
        }

        const renderError = (category, key = null) => {
          if(key) {
            return errors[category] &&
              errors[category][key] &&
              <Error id={`error_${key}`}>{errors[category][key]}</Error>
          } else if(typeof errors[category] === 'string') {
            return errors[category] && <Error id={`error_${category}`} >{errors[category]}</Error>
          }
        }

         return (
           <form onSubmit={handleSubmit} ref={this.formRef}>
             <div className="p-6">
               <div className="my-6">
                 <h4 id="disposition-heading" className="block text-gray-800 text-xl font-bold mb-6">Disposition</h4>
                 <div className="flex flex-wrap" id="disposition-checkboxes">
                   <FormCheckbox
                     className="mr-4 p-2 rounded flex items-center"
                     onClick={() => setFieldValue('disposition.disposition_stable', !values.disposition.disposition_stable)}
                     checked={!!values.disposition.disposition_stable}
                   >
                     Stable
                   </FormCheckbox>
                   <FormCheckbox
                     className="mr-4 p-2 rounded flex items-center"
                     onClick={() => {
                       if(values.disposition.disposition_happiness === 'happy') {
                         setFieldValue('disposition.disposition_happiness', '')
                       } else {
                         setFieldValue('disposition.disposition_happiness', 'happy')
                       }
                     }}
                     checked={values.disposition.disposition_happiness === 'happy'}
                   >
                     Happy
                   </FormCheckbox>
                   <FormCheckbox
                     className="mr-4 p-2 rounded flex items-center"
                     onClick={() => setFieldValue('disposition.disposition_anxious', !values.disposition.disposition_anxious)}
                     checked={!!values.disposition.disposition_anxious}
                   >
                     Anxious
                   </FormCheckbox>
                   <FormCheckbox
                     className="mr-4 p-2 rounded flex items-center"
                     onClick={() => {
                       if(values.disposition.disposition_happiness === 'sad') {
                         setFieldValue('disposition.disposition_happiness', '')
                       } else {
                         setFieldValue('disposition.disposition_happiness', 'sad')
                       }
                     }}
                     checked={values.disposition.disposition_happiness === 'sad'}
                   >
                     Sad
                   </FormCheckbox>

                   <FormCheckbox
                     className="mr-4 p-2 rounded flex items-center"
                     onClick={() => setFieldValue('disposition.disposition_irritable', !values.disposition.disposition_irritable)}
                     checked={!!values.disposition.disposition_irritable}
                   >
                     Irritable
                   </FormCheckbox>

                   <FormCheckbox
                     className="mr-4 p-2 rounded flex items-center"
                     onClick={() => setFieldValue('disposition.disposition_withdrawn', !values.disposition.disposition_withdrawn)}
                     checked={!!values.disposition.disposition_withdrawn}
                   >
                     Withdrawn
                   </FormCheckbox>

                   <FormCheckbox
                     className="mr-4 p-2 rounded flex items-center"
                     onClick={() => setFieldValue('disposition.disposition_change_in_condition', !values.disposition.disposition_change_in_condition)}
                     checked={!!values.disposition.disposition_change_in_condition}
                   >
                     Change in condition
                   </FormCheckbox>
                 </div>
                 {renderError('disposition')}
               </div>

               <hr />

               <AssessmentCheckboxes
                 fields={this.headFields}
                 category="head"
                 title="Head/Face/Scalp"
                 values={values['head']}
                 errors={errors}
                 onClick={setFieldValue}
                 onInputChange={handleChange}
                 includeOther={true}
                 assessmentLabels={basicAssessmentMappings}
               />

               <hr />

               <AssessmentCheckboxes
                 fields={this.eyeFields}
                 category="eyes"
                 title="Eyes"
                 values={values['eyes']}
                 errors={errors}
                 onClick={setFieldValue}
                 onInputChange={handleChange}
                 includeOther={true}
                 assessmentLabels={basicAssessmentMappings}
               />

               <hr />

               <AssessmentCheckboxes
                 fields={this.earFields}
                 category="ears"
                 title="Ears"
                 values={values['ears']}
                 errors={errors}
                 onClick={setFieldValue}
                 onInputChange={handleChange}
                 includeOther={true}
                 assessmentLabels={basicAssessmentMappings}
               />

               <hr />

               <AssessmentCheckboxes
                 fields={this.noseFields}
                 category="nose"
                 title="Nose"
                 values={values['nose']}
                 errors={errors}
                 onClick={setFieldValue}
                 onInputChange={handleChange}
                 includeOther={true}
                 assessmentLabels={basicAssessmentMappings}
               />

               <hr />

               <AssessmentCheckboxes
                 fields={this.mouthFields}
                 category="mouth"
                 title="Mouth"
                 values={values['mouth']}
                 errors={errors}
                 onClick={setFieldValue}
                 onInputChange={handleChange}
                 includeOther={true}
                 assessmentLabels={basicAssessmentMappings}
               />

               <hr />

               <AssessmentCheckboxes
                 fields={this.tongueFields}
                 category="tongue"
                 title="Tongue"
                 values={values['tongue']}
                 errors={errors}
                 onClick={setFieldValue}
                 onInputChange={handleChange}
                 includeOther={true}
                 assessmentLabels={basicAssessmentMappings}
               />
             </div>

             <div className="p-4 px-8 text-right bg-gray-100 rounded-b-lg">
               <SecondaryButton
                 id="cancel-button"
                 className="inline-block mr-4"
                 type="button"
                 onClick={this.props.onCancel}
               >Cancel</SecondaryButton>
               <SaveButton
                 id="save-exit"
                 className="mr-4"
                 type="submit"
                 onClick={(e) => {
                   e.preventDefault()
                   this.setState({submitType: EXIT}, () => submitForm())}
                 }
               >Save and Exit</SaveButton>
               <SaveButton
                 id="save-continue"
                 className="mr-4"
                 type="submit"
                 onClick={(e) => {
                   e.preventDefault()
                   this.setState({submitType: CONTINUE}, () => submitForm())}
                 }
               >Save and Continue</SaveButton>
             </div>
           </form>
         )}}

      </Formik>
    )
  }
}

BasicAssessmentForm.propTypes = {
  assessment: PropTypes.object,
  onSubmit: PropTypes.func,
  onContinue: PropTypes.func,
  onCancel: PropTypes.func
}

export default BasicAssessmentForm;

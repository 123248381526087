import React from 'react'
import { Formik } from "formik";
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Yup from "yup";
import CenterContainer from "./layout/CenterContainer";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./form/PrimaryButton";
import TimeOfDayIcon from "./TimeOfDayIcon";
import {
  getDate,
  timeOptionSelect,
  timeOptions,
} from "../utils/timeUtils";
import TimeSelect from './TimeSelect';

const ShiftReportTimeEnd = (props) => (
  <CenterContainer>
    <Formik
      initialValues={{
        end: props.end ? timeOptionSelect(props.end) : "",
      }}
      validationSchema={Yup.object().shape({
        end: Yup.string().required(),
      })}
      onSubmit={(values) => {
        props.handleEndNext({
          end: values.end.value,
        });
      }}
    >
      {({ values, handleSubmit, setFieldValue, errors }) => (
        <form
          onSubmit={handleSubmit}
          className="w-1/2 bg-white shadow-lg border-lg rounded-lg"
        >
          <div className="p-8">
            <span className="font-bold text-lg">Create a new shift report</span>
            <p id="shift-time-start-heading" className="mb-6 text-gray-600">
              Select the shift end time
            </p>
            <div className="mb-4">
              <div className="flex justify-between">
                <div className="w-2/5">
                  <div>
                    <div className="text-sm text-gray-600">What time does this shift end?</div>
                    <div className="text-xl my-2">
                      <TimeSelect
                        id="time-end"
                        value={values.end}
                        options={timeOptions(moment(props.start), moment().add(1, 'days').startOf('day'), true)}
                        placeholder="Choose an end time..."
                        onChange={time => setFieldValue('end', time)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between bg-gray-100 px-8 py-4">
              <div className="text-gray-600">
                <div className="uppercase text-sm">{getDate(props.date, values.start).format("ll")}</div>
                <div className="text-xl">
                  <TimeOfDayIcon time={moment(props.start)} />
                  <span className="ml-2 inline-block">{moment(props.start).format("h:mma")}</span>
                  <span className="mx-1">&#45;</span>
                  {values.end && (<>
                    <TimeOfDayIcon time={moment(values.end.value)} />
                    <span className="ml-2 inline-block">{moment(values.end.value).format("h:mma")}</span>
                  </>)}
                </div>
              </div>
            <div className="rounded-b-lg">
              <SecondaryButton
                id="time-end-secondary-button"
                type="button"
                className="inline-block mr-4"
                onClick={props.onBackClick}
              >
                Back
              </SecondaryButton>
              <PrimaryButton
                id="time-end-primary-button"
                disabled={!values.end || errors.end}
                type="submit"
              >
                Next
              </PrimaryButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  </CenterContainer>
)

ShiftReportTimeEnd.propTypes = {
  date: PropTypes.object.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  handleEndNext: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired
}

export default ShiftReportTimeEnd
import React from 'react'
import PropTypes from 'prop-types'
import CenterContainer from './layout/CenterContainer'
import SecondaryButton from './SecondaryButton'
import SaveButton from './form/SaveButton'
import Modal from './Modal'
import Label from './form/Label';
import Input from './form/Input';
import ReactTooltip from 'react-tooltip';

const STATUS_COMPLETED = 'completed';
const STATUS_REFUSED = 'refused';
const MAX_CHARS = 60;

class CompletedRefusedToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      status: props.status,
      comment: props.comment,
      commentEnabled: props.comment !== '',
    };
  }

  _handleChange = (status) => {
    if(status === this.state.status) {
      this.setState({
        status: null,
        comment: ''
      })
    } else {
      this.setState({
        status,
      })
    }
  }

  _handleToggleComment = () => {
    this.setState({
      commentEnabled: true,
    });
  }

  _handleCommentOnChange = comment => {
    this.setState({
      comment: comment.substring(0, 60),
    });
  }

  _handleSubmit = () => {
    this.setState({
      isEditing: false,
      commentEnabled: this.state.comment !== '',
      comment: null !== this.state.status ? this.state.comment : '',
    });
    this.props.onSubmit(this.state.status, this.state.comment);
  }

  _charsRemaining = () => {
    return MAX_CHARS - this.state.comment.length;
  }

  _buttonClassName = () => {
    let baseClass = 'text-gray-300 relative';

    if(this.props.status === STATUS_COMPLETED) {
      baseClass = 'text-green-600';
    } else if(this.props.status === STATUS_REFUSED) {
      baseClass = 'text-red-500';
    }

    if(this.props.disabled) {
      baseClass += ' cursor-default'
    }

    return baseClass
  }

  _handleCancel = () => {
    this.setState({
      isEditing: false,
      status: this.props.status,
      commentEnabled: this.state.comment !== '',
    });
  }

  _buttonIconClassName = () => {
    if(this.props.status === null) {
      return 'fas fa-plus-circle text-5xl';
    } else if(this.props.status === STATUS_REFUSED) {
      return 'fas fa-times-circle text-5xl';
    }

    return 'fas fa-check-circle text-5xl';
  }

  render = () => (
    <React.Fragment>
      <ReactTooltip className="bg-white text-blue-900 text-base rounded-lg" effect="solid"/>
      <div
        data-tip={this.state.comment}
        className="inline-block"
      >
        <button
          onClick={() => this.setState({isEditing: true})}
          className={this._buttonClassName()}
          disabled={this.props.disabled}
        >
          <i className={this._buttonIconClassName()} />
          {this.state.comment !== '' && (<i className="fas fa-comment text-blue-600 p-1 rounded-full absolute stroke-white" style={{marginLeft: '-.75em', marginTop: '-.25em'}}></i>)}
        </button>
      </div>
      <Modal
        isOpen={this.state.isEditing}
      >
        <CenterContainer>
          <div className="bg-white rounded-lg shadow-lg">
            <div className="p-8 pb-0 mb-4">
              <h1 id="completed-refused-heading" className="text-lg">{this.props.heading}</h1>
              <h2 className="text-gray-600 mb-4">{this.props.subheading}</h2>
              <button
                id="completed-button"
                onClick={() => this._handleChange(STATUS_COMPLETED)}
                className={this.state.status === STATUS_COMPLETED ?
                  'block bg-green-100 border-2 focus:outline-none outline-none border-green-200 text-green-600 p-8 rounded-lg inline-block mr-2 w-48' :
                  'block bg-gray-100 border-2 border-gray-200 text-gray-600 p-8 rounded-lg inline-block mr-2 w-48 hover:shadow-lg outline-none focus:outline-none'}
              >
                <i className={this.state.status === STATUS_COMPLETED ? 'fas fa-check-circle text-5xl block mb-4' : 'fas fa-check-circle text-5xl block mb-4 text-gray-500'} />
                <div>
                  Completed
                </div>
              </button>
              <button
                id="refused-button"
                onClick={() => this._handleChange(STATUS_REFUSED)}
                className={this.state.status === STATUS_REFUSED ?
                  'block bg-red-100 border-2 focus:outline-none border-red-200 text-red-600 p-8 rounded-lg inline-block ml-2 w-48' :
                  'block bg-gray-100 border-2 border-gray-200 text-gray-600 p-8 rounded-lg inline-block ml-2 w-48 hover:shadow-lg focus:outline-none'}
              >
                <i className={this.state.status === STATUS_REFUSED ?
                  'fas fa-times-circle text-5xl block mb-4 text-red-500' :
                  'fas fa-times-circle text-5xl block mb-4 text-gray-500'}
                />
                <div>
                  Client Refused
                </div>
              </button>
            </div>
            {this.state.commentEnabled ? (
              <div className="px-8 mb-8">
                <Label htmlFor="comment">Comment</Label>
                <Input
                  id="comment"
                  name="comment"
                  type="text"
                  className="mb-1"
                  value={this.state.comment}
                  onChange={e => this._handleCommentOnChange(e.target.value)}
                />
                <span className={this._charsRemaining() > 15 ? "text-sm text-gray-700" : "text-sm text-red-600"}><b>{this._charsRemaining()}</b> characters remaining</span>
              </div>
            ) : (
              <div className="px-8 mb-8">
                <span
                  id="add-a-comment"
                  className="text-blue-600 cursor-pointer inline-block text-sm"
                  onClick={this._handleToggleComment}
                >
                  <i className="fal fa-plus"></i> Add a comment
                </span>
              </div>
            )}
            <div className="p-4 bg-gray-100 text-right rounded-b-lg">
              <SecondaryButton id="modal-secondary-button" className="mr-4" onClick={this._handleCancel}>Cancel</SecondaryButton>
              <SaveButton id="modal-primary-button" onClick={this._handleSubmit}>Save</SaveButton>
            </div>
          </div>
        </CenterContainer>
      </Modal>
    </React.Fragment>
  )
}

CompletedRefusedToggle.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string,
  comment: PropTypes.string,
  disabled: PropTypes.bool
}

export default CompletedRefusedToggle

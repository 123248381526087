import humps from 'humps';

export const serializeHhaShiftReport = ({
  shiftReport,
  personalCareTasks,
  activities,
  vitals,
  incidentReports,
  verificationImage
}) => {
  const {
    lastUpdated,
    deletedAt,
    status,
    ...rest
  } = {
    ...shiftReport,
    vitals: serializeCollection(vitals),
    activities: serializeCollection(activities),
    personalCareTasks: serializeCollection(personalCareTasks),
    incidentReports: serializeCollection(incidentReports),
    verificationImage: serializeVerificationImage(verificationImage)
  }

  const requestBody = humps.decamelizeKeys(
    mergeClinicalDefaults(rest)
  )

  return JSON.stringify(requestBody);
}

export const serializeClinicalShiftReport = ({
  shiftReport,
  painAssessments,
  assessment,
  intake,
  output,
  woundCares,
  vitals,
  clinicalContinuationNotes,
  verificationImage
}) => {
  const {
    lastUpdated,
    deletedAt,
    status,
    ...rest
  } = {
    ...shiftReport,
    painAssessments: serializeCollection(painAssessments),
    assessments: serializeCollection([assessment]),
    intakes: serializeCollection([intake]),
    outputs: serializeCollection([output]),
    vitals: serializeCollection(vitals),
    woundCares: serializeCollection(woundCares),
    clinicalContinuationNotes: serializeCollection(clinicalContinuationNotes),
    verificationImage: serializeVerificationImage(verificationImage)
  }

  const payload = filterClinical(rest)

  const requestBody = humps.decamelizeKeys(
    mergeHHADefaults(payload)
  )

  return JSON.stringify(requestBody)
}

const filterClinical = payload => {
  const {
    assessment,
    intake,
    output,
    ...rest
  } = {
    ...payload
  }

  return rest
}

const mergeHHADefaults = (obj) => (
  Object.assign(obj, {
    activities: [],
    personalCareTasks: [],
    incidentReports: []
  })
)

const mergeClinicalDefaults = (obj) => (
  Object.assign(obj, {
    painAssessments: [],
    woundCares: [],
    clinicalContinuationNotes: [],
    intake: null,
    output: null,
    assessment: null
  })
)

const serializeVerificationImage = verificationImage => {
  if(null === verificationImage) {
    return null;
  }

  const { shiftReportId, lastUpdated, ...i} = verificationImage
  return {
    ...i
  }
}

const serializeCollection = (collection) => {
  return collection.map(object => sanitize(object))
}

const sanitize = (object) => {
  const {shiftReportId, deletedAt, lastUpdated, ...rest} = object;
  return rest
}

import { 
  LOCATION_MAPPINGS, 
  WOUND_ASSESSMENT_TYPE_MAPPINGS, 
  WOUND_ASSESSMENT_STAGE_MAPPINGS, 
  WOUND_ASSESSMENT_DRAINAGE_TYPE_MAPPINGS, 
  WOUND_ASSESSMENT_COVERED_WITH_MAPPINGS, 
  WOUND_ASSESSMENT_PACKED_WITH, 
  WOUND_ASSESSMENT_CLEANSED_WITH, 
  WOUND_ASSESSMENT_SECURED_WITH 
} from "../constants"
import { create } from "lodash"

const createOptions = (fieldMappings) => {
  return Object.keys(fieldMappings).map(key => create({label: fieldMappings[key], value: key}))
}

const locationOptions = createOptions(LOCATION_MAPPINGS)
const typeOptions = createOptions(WOUND_ASSESSMENT_TYPE_MAPPINGS)
const stageOptions = createOptions(WOUND_ASSESSMENT_STAGE_MAPPINGS)
const drainageTypeOptions = createOptions(WOUND_ASSESSMENT_DRAINAGE_TYPE_MAPPINGS)
const coveredWithOptions = createOptions(WOUND_ASSESSMENT_COVERED_WITH_MAPPINGS)
const packedWithOptions = createOptions(WOUND_ASSESSMENT_PACKED_WITH)
const cleansedWithOptions = createOptions(WOUND_ASSESSMENT_CLEANSED_WITH)
const securedWithOptions = createOptions(WOUND_ASSESSMENT_SECURED_WITH)

export const woundAssessmentOptions = (field) => {
  switch(field){
    case "location":
      return locationOptions
    case "type":
      return typeOptions
    case "stage":
      return stageOptions
    case "drainageType":
      return drainageTypeOptions
    case "coveredWith":
      return coveredWithOptions
    case "packedWith":
      return packedWithOptions
    case "cleansedWith":
      return cleansedWithOptions
    case "securedWith":
      return securedWithOptions
    default:
      break;
  }
}

export const getLabel = (field, value) => {
  const fieldOptions = woundAssessmentOptions(field).filter(option => option.value === value)
  return fieldOptions[0].label
}

import moment from 'moment'
import { generateTimeRange } from './timeUtils'

export default class ActivityValidator {
  constructor(start, end, activityTimes) {
    this.start = start
    this.end = end
    this.activityTimes = activityTimes
    this.timeRange = generateTimeRange(start, end)
  }

  isNotEmpty() {
    return this.activityTimes.length > 0
  }

  firstActivityOnTime() {
    const startMoment = moment(this.start)
    const firstMoment = moment(this.activityTimes[0])

    return moment.duration(firstMoment.diff(startMoment)).asHours() <= 2
  }

  lastActivityOnTime() {
    const endMoment = moment(this.end)
    const lastMoment = moment(this.activityTimes[this.activityTimes.length - 1])

    return moment.duration(endMoment.diff(lastMoment)).asHours() < 4
  }

  allOtherActivitiesOnTime() {
    const length = this.activityTimes.length
    const arry = []

    for(let i=0; i < (length - 1); i++) {
      const time = moment(this.activityTimes[i])
      const nextTime = moment(this.activityTimes[i + 1])
      const diff = moment.duration(nextTime.diff(time)).asHours()

      arry.push(diff <= 2)
    }

    return arry.every(Boolean)
  }


  validate() {
    return [
      this.isNotEmpty(),
      this.firstActivityOnTime(),
      this.lastActivityOnTime(),
      this.allOtherActivitiesOnTime()
    ].every(Boolean)
  }
}

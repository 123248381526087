import React from 'react';
import { connect } from 'react-redux';
import { updateWoundCare, createWoundCare } from '../../../../actions';
import WoundCareForm from '../../../../components/shiftReports/clinical/v1/WoundCareForm';
import WoundCareTable from '../../../../components/shiftReports/clinical/v1/WoundCareTable';

class WoundCares extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      isEditing: false,
      curWoundCareId: null,
    };
  };

  _handleAddWoundCare = () => {
    this.setState({
      isAdding: true,
    });
  };

  _handleSubmitAddWoundCare = woundCare => {
    this.props.addWoundCare({
      ...woundCare,
      shiftReportId: this.props.shiftReport.id,
    });
    this.setState({
      isAdding: false,
    });
  };

  _handleSubmitEditWoundCare = woundCares => {

    this.props.updateWoundCare({
      ...this.props.woundCares[this.state.curWoundCareId],
      ...woundCares
    });
    this.setState({
      isEditing: false,
      curWoundCareId: null,
    });
  };

  _handleCancelEditWoundCare = () => {
    this.setState({
      isEditing: false,
      curWoundCareId: null,
    });
  };

  _handleCancelAddWoundCare = () => {
    this.setState({
      isAdding: false,
    });
  };

  _handleEditWoundCare = id => {
    this.setState({
      isEditing: true,
      curWoundCareId: id,
    });
  };

  render = () => (
    <React.Fragment>
      {this.state.isAdding && (
        <WoundCareForm
          onCancel={this._handleCancelAddWoundCare}
          onSubmit={this._handleSubmitAddWoundCare}
          woundCare={{
            location: null,
            type: null,
            stage: null,
            drainageType: null,
            coveredWith: null,
            packedWith: null,
            cleansedWith: null,
            securedWith: null,
            recordedAt: null,
            length: null,
            width: null,
            depth: null,
            malodorus: false,
            tunneling: false,
            other: null,
            note: null
          }}
        />
      )}
      {this.state.isEditing && (
        <WoundCareForm
          onCancel={this._handleCancelEditWoundCare}
          onSubmit={this._handleSubmitEditWoundCare}
          woundCare={this.props.woundCares[this.state.curWoundCareId]}
        />
      )}
      <WoundCareTable
        woundCares={this.props.woundCares}
        onEditWoundCare={this._handleEditWoundCare}
        onAddWoundCare={this._handleAddWoundCare}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => ({
  updateWoundCare: woundCares => dispatch(updateWoundCare(woundCares)),
  addWoundCare: woundCares => dispatch(createWoundCare(woundCares))
});

const mapStateToProps = (state, ownProps) => ({
  woundCares: ownProps.shiftReport.woundCares.map(id => state.entities.woundCares.byId[id]),
  ...ownProps
});

export default connect(mapStateToProps, mapDispatchToProps)(WoundCares);

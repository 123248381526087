import * as Yup from 'yup'

const verifyChecked = (category) => (obj) => (
  Object
    .keys(obj)
    .filter(x => x.startsWith(`${category}_`))
    .map(x => obj[x])
    .some(x => x)
);

const verifyOneOtherChecked = (category) => (obj) => (
  Object
    .keys(obj)
    .filter(x => x.startsWith(`${category}_`) && !x.includes('alert'))
    .map(x => obj[x])
    .some(x => x)
);

const checkOneMessage = 'You must check at least one option';
const alertCheckOneMessage = 'You must check at least one other option';

export const validationSchema = Yup.object().shape({
  consciousness: Yup.object().shape({
    consciousness_alert: Yup.boolean(),
    consciousness_oriented: Yup.boolean(),
    consciousness_forgetful: Yup.boolean(),
    consciousness_lethargic: Yup.boolean(),
    consciousness_follows_simple_commands: Yup.boolean(),
    consciousness_non_responsive: Yup.boolean(),
    consciousness_disoriented: Yup.boolean(),
    consciousness_short_term_memory_loss: Yup.boolean(),
  }).test(
    'consciousness-answered-test',
    checkOneMessage,
    verifyChecked('consciousness')
  ).test('consciousness-alert-selected',
  alertCheckOneMessage,
    verifyOneOtherChecked('consciousness')
  )

});

import React from 'react';

const vitalValidations = vital => {
  const notifications = [];

  if(vital.bloodPressureSystolic !== '' &&
    vital.bloodPressureDiastolic !== '' && (
      vital.bloodPressureSystolic > 120 ||
      vital.bloodPressureSystolic < 90 ||
      vital.bloodPressureDiastolic < 60 ||
      vital.bloodPressureDiastolic > 80)
  ) {
    notifications.push(<span>Blood pressure ({vital.bloodPressureSystolic}/{vital.bloodPressureDiastolic}) is outside of the normal range.</span>);
  }

  if(vital.respirationRate !== '' && (vital.respirationRate < 12 || vital.respirationRate > 18)) {
    notifications.push(<span>Respiration rate ({vital.respirationRate} respirations per minute) is outside of the normal range.</span>);
  }

  if(vital.pulse !== '' && (vital.pulse < 60 || vital.pulse > 100)) {
    notifications.push(<span>Pulse ({vital.pulse}) is outside of the normal range.</span>);
  }

  if(vital.temperature !== '' && (vital.temperature < 97.8 || vital.temperature > 99.1)) {
    notifications.push(<span>Temperature ({vital.temperature}&deg;F) is outside of the normal range.</span>);
  }

  if(vital.oxygenSaturationLevel !== '' && vital.oxygenSaturationLevel < 92) {
    notifications.push(<span>Oxygen saturation level ({vital.oxygenSaturationLevel}) is outside of the normal range.</span>);
  }

  return notifications;
};

export default vitalValidations;
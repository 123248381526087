import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CompletedRefusedToggle from './CompletedRefusedToggle'
import { personalCareTaskLabels } from '../constants'

class PersonalCareTaskWidget extends React.Component {
  _handleChange = (status, comment) => {
    if(status === null && this.props.personalCareTask) {
      this._delete();
    } else if(status !== null && this._shouldCreate()) {
      this._create(status, comment);
    } else if(status !== null) {
      this._update(status, comment);
    }
  }

  _delete = () => {
    this.props.deletePersonalCareTask(this.props.personalCareTask);
  }

  _create = (status, comment) => {
    this.props.createPersonalCareTask(this.props.shiftReportId, this.props.type, this.props.recordedAt, status, comment);
  }

  _update = (status, comment) => {
    this.props.updatePersonalCareTask({
      id: this.props.personalCareTask.id,
      status,
      comment
    });
  }

  _shouldCreate = () => {
    return this.props.personalCareTask === null;
  }

  render = () => (
    <CompletedRefusedToggle
      heading={personalCareTaskLabels[this.props.type]}
      subheading={moment(this.props.recordedAt).format('H:mm')}
      onSubmit={this._handleChange}
      status={this.props.personalCareTask ? this.props.personalCareTask.status : null}
      comment={this.props.personalCareTask ? this.props.personalCareTask.comment : ''}
      disabled={this.props.disabled}
    />
  )
}

PersonalCareTaskWidget.propTypes = {
  personalCareTask: PropTypes.object,
  deletePersonalCareTask: PropTypes.func,
  updatePersonalCareTask: PropTypes.func,
  createPersonalCareTask: PropTypes.func,
  type: PropTypes.string,
  recordedAt: PropTypes.string,
  disabled: PropTypes.bool,
}

export default PersonalCareTaskWidget

import { call, put, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr';
import humps from 'humps';
import { CLIENT_FETCH_REQUESTED, CLIENT_FETCH_SUCCEEDED, CLIENT_FETCH_FAILED } from '../actions';
import Api from '../services/Api';
import clientSchema from '../schemas/client';


function* fetchClient(action) { try {
    const response = yield call(Api.fetchClient, action.payload.id);
    if(response.status === 200) {
      const normalized = normalize(yield response.json(), clientSchema);
      yield put({
        type: CLIENT_FETCH_SUCCEEDED,
        payload: {
          lastUpdated: Date.now(),
          clients: {
            byId: humps.camelizeKeys(normalized.entities.clients),
          },
          employees: {
            byId: humps.camelizeKeys(normalized.entities.employees),
          }
        }
      });
    } else {
      yield put({
        type: CLIENT_FETCH_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: CLIENT_FETCH_FAILED,
      message: e.message
    });
  }
}

/*
  Does not allow concurrent fetches of client. If "CLIENT_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* clientSaga() {
  yield takeLatest(CLIENT_FETCH_REQUESTED, fetchClient);
}

export default clientSaga;
import React from 'react';
import { connect } from 'react-redux';
import { updatePainAssessment, createPainAssessment } from '../../../../actions';
import PainAssessmentForm from '../../../../components/shiftReports/clinical/v1/painAssessment/PainAssessmentForm';
import PainAssessmentTable from '../../../../components/shiftReports/clinical/v1/painAssessment/PainAssessmentTable';

class PainAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      isEditing: false,
      curPainAssessmentId: null,
    };
  };

  _handleAddPainAssessment = () => {
    this.setState({
      isAdding: true,
    });
  };

  _handleSubmitAddPainAssessment = painAssessment => {
    this.props.addPainAssessment({
      ...painAssessment,
      shiftReportId: this.props.shiftReport.id,
    });
    this.setState({
      isAdding: false,
    });
  };

  _handleSubmitEditPainAssessment = painAssessments => {

    this.props.updatePainAssessment({
      ...this.props.painAssessments[this.state.curPainAssessmentId],
      ...painAssessments
    });
    this.setState({
      isEditing: false,
      curPainAssessmentId: null,
    });
  };

  _handleCancelEditPainAssessment = () => {
    this.setState({
      isEditing: false,
      curPainAssessmentId: null,
    });
  };

  _handleCancelAddPainAssessment = () => {
    this.setState({
      isAdding: false,
    });
  };

  _handleEditPainAssessment = id => {
    this.setState({
      isEditing: true,
      curPainAssessmentId: id,
    });
  };

  render = () => (
    <React.Fragment>
      {this.state.isAdding && (
        <PainAssessmentForm
          onCancel={this._handleCancelAddPainAssessment}
          onSubmit={this._handleSubmitAddPainAssessment}
          painAssessment={{
            location: null,
            scale: null,
            onset: null,
            present_level: null,
            worst_pain_gets: null,
            best_pain_gets: null,
            description: null
          }}
        />
      )}
      {this.state.isEditing && (
        <PainAssessmentForm
          onCancel={this._handleCancelEditPainAssessment}
          onSubmit={this._handleSubmitEditPainAssessment}
          painAssessment={this.props.painAssessments[this.state.curPainAssessmentId]}
        />
      )}
      <PainAssessmentTable
        painAssessments={this.props.painAssessments}
        onEditPainAssessment={this._handleEditPainAssessment}
        onAddPainAssessment={this._handleAddPainAssessment}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => ({
  updatePainAssessment: painAssessments => dispatch(updatePainAssessment(painAssessments)),
  addPainAssessment: painAssessments => dispatch(createPainAssessment(painAssessments))
});

const mapStateToProps = (state, ownProps) => ({
  painAssessments: ownProps.shiftReport.painAssessments.map(id => state.entities.painAssessments.byId[id]),
  ...ownProps
});

export default connect(mapStateToProps, mapDispatchToProps)(PainAssessment);

import React from 'react';
import Select from 'react-select';

const TimeSelect = props => (
  <Select
    {...props}
    className="react-select-container react-select-container--time"
    classNamePrefix="react-select"
  />
);

export default TimeSelect;

import { createSelector } from 'reselect'

const getClinicalContinuationNotesByShiftReportId = (state, props) => {
  return state.entities
    .shiftReports
    .byId[props.shiftReport.id]
    .clinicalContinuationNotes.map(id => {
      return state.entities.clinicalContinuationNotes.byId[id];
    });
}

export const getClinicalContinuationNotes = createSelector(
  [getClinicalContinuationNotesByShiftReportId], (ccns) => {
    return ccns;
  });


import React, { useState } from 'react'
import Select from 'react-select'
import { InputWithCharsLeft } from '../../../../components/form/Input'
import {intakeOptions} from '../../../../constants'

const Intake = (props) => {
  const [intake, setIntake] = useState({
    breakfast: props.intake.breakfast ? props.intake.breakfast : "",
    lunch: props.intake.lunch ? props.intake.lunch : "",
    dinner: props.intake.dinner ? props.intake.dinner : "",
    snackOne: props.intake.snackOne ? props.intake.snackOne : "",
    snackTwo: props.intake.snackTwo ? props.intake.snackTwo : "",
    snackThree: props.intake.snackThree ? props.intake.snackThree : "",
    liquid: props.intake.liquid ? props.intake.liquid : 0,
  })

  const _handleChange = (e) => {
    let { name, value } = e.target;
    if(name === 'liquid' && value > 100){
      value = 100
    }
    if(name === 'liquid' && value < 0){
      value = 0
    }
    setIntake({...intake, [name]: value})
  }

  const _handleSelectChange = async (option, name) => {
    setIntake({...intake, [name]: option})
  }
  
  const _handleUpdate = () => {
    props.updateIntake({id: props.intake.id, ...intake})
  }

  const _handleStepUp = (e) =>{
    const {name, value} = e.target.previousSibling
    if(intake[name] < 100){
      setIntake({...intake, [name]: parseInt(value) + 1})
    }
  }

  const _handleStepDown = (e) =>{
    const {name, value} = e.target.nextSibling
    if(intake[name] > 0){
      setIntake({...intake, [name]: parseInt(value) - 1})
    }
  }

  return (
    <div className="ml-1 my-2 bg-white rounded-lg shadow-md">
              <form onBlur={_handleUpdate}>

                <label className="p-3 pb-3 pl-4 flex justify-between items-center">
                  Breakfast
                  <Select 
                    id="intake-breakfast-select"
                    name="breakfast"
                    onBlur={_handleUpdate}
                    options={intakeOptions.map(option => {
                      return {
                        value: option.value,
                        label: option.label
                      }})}
                      className="react-select-container--clinical w-56"
                      classNamePrefix="react-select"
                      placeholder="Select one..."
                      value={intake.breakfast ? {
                        value: intake.breakfast,
                        label: intake.breakfast + "%"
                      } : ""}
                      onChange={(selected, action) => { 
                        _handleSelectChange(selected.value, action.name)}}
                      /></label>

                    <label className="p-3 pb-3 pl-4 bg-gray-100 flex justify-between items-center">
                    Lunch
                    <Select 
                      id="intake-lunch-select"
                      name="lunch"
                      onBlur={_handleUpdate}
                      options={intakeOptions.map(option => {
                        return {
                          value: option.value,
                          label: option.label
                        }})}
                        className="react-select-container--clinical w-56"
                        classNamePrefix="react-select"
                        placeholder="Select one..."
                        value={intake.lunch ? {
                          value: intake.lunch,
                          label: intake.lunch + "%"
                        } : ""}
                        onChange={(selected, action) => { 
                          _handleSelectChange(selected.value, action.name)}}
                        /></label>
                    <label className="p-3 pb-3 pl-4 flex justify-between items-center">
                    Dinner
                    <Select 
                      onBlur={_handleUpdate}
                      id="intake-dinner-select"
                      name="dinner"
                      options={intakeOptions.map(option => {
                        return {
                          value: option.value,
                          label: option.label
                        }})}
                        className="react-select-container--clinical w-56"
                        classNamePrefix="react-select"
                        placeholder="Select one..."
                        value={intake.dinner ? {
                          value: intake.dinner,
                          label: intake.dinner + "%"
                        } : ""}
                        onChange={(selected, action) => { 
                          _handleSelectChange(selected.value, action.name)}}
                        /></label>
                    <label className="p-3 pb-6 pl-4 bg-gray-100 flex justify-between items-center">
                      Snack 1
                    <InputWithCharsLeft
                      onBlur={_handleUpdate}
                      id="snack-one"
                      name="snackOne"
                      type="text"
                      value={intake.snackOne ? intake.snackOne : ""}
                      onChange={_handleChange}
                      className="px-4 py-3 w-56 outline-none rounded-lg border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200"
                      maxLength={60}
                      absolute
                    />
                    </label>
                    <label className="p-3 pb-6 pl-4 flex justify-between items-center">
                      Snack 2
                    <InputWithCharsLeft
                      id="snack-two"
                      name="snackTwo"
                      type="text"
                      onBlur={_handleUpdate}
                      value={intake.snackTwo ? intake.snackTwo : ""}
                      onChange={_handleChange}
                      className="px-4 py-3 w-56 outline-none rounded-lg border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200"
                      maxLength={60}
                      absolute
                    />
                    </label>
                    <label className="p-3 pb-6 pl-4 bg-gray-100 flex justify-between items-center">
                      Snack 3
                    <InputWithCharsLeft
                      id="snack-three"
                      name="snackThree"
                      type="text"
                      onBlur={_handleUpdate}
                      value={intake.snackThree ? intake.snackThree : ""}
                      onChange={_handleChange}
                      className="px-4 py-3 w-56 outline-none rounded-lg border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200"
                      maxLength={60}
                      absolute
                    />
                    </label>
                    <label className="p-3 pb-3 pl-4 flex justify-between items-center">
                      Liquid
                      <div className="px-2 rounded-lg bg-gray-300 flex justify-around items-center">
                        <i className="fas fa-minus p-2 pr-3 text-2xl" onClick={_handleStepDown}/>
                        <input
                          id="liquid"
                          name="liquid"
                          type="number"
                          onBlur={_handleUpdate}
                          value={intake.liquid}
                          onChange={_handleChange}
                          readOnly={true}
                          className="px-4 py-3 w-32 text-center outline-none border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200 remove-input-steps"
                          />
                          <i className="fas fa-plus p-2 pl-3 text-2xl" onClick={_handleStepUp}/>
                      </div>
                    </label>
                </form>

    </div>
  )
}

export default Intake

import React from 'react';

const Success = props => (
  <div {...props} className="fixed bg-green-200 p-4 pt-3 pb-3 text-green-600 w-full flex justify-between">
    <div className="flex items-center">
      <i className="fas fa-thumbs-up mr-2" /> {props.children}
    </div>
    <div >
      <button onClick={() => props.onDismiss()} className="text-2xl"><i className="fal fa-times" /></button>
    </div>
  </div>
);

export default Success;

import React from 'react';
import PrimaryButton from './PrimaryButton';

const className = 'fas fa-save mr-1';
const disabledClassName = 'fas fa-save mr-1 text-gray-500';

const SaveButton = props => (
  <PrimaryButton {...props} className={props.className}><i className={props.disabled ? disabledClassName : className} /> {props.children}</PrimaryButton>
);

export default SaveButton;
import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Header from '../../../Header'
import Vitals from '../../../../containers/Vitals'
import IncidentReports from '../../../../containers/IncidentReports'
import {
  personalCareTaskHints,
  activities,
  activityLabels,
  personalCareTasks,
  personalCareTaskLabels
} from '../../../../constants'
import Container from '../../../layout/Container'
import PrimaryButton from '../../../../components/form/PrimaryButton'
import ShiftReportHeader from '../../../ShiftReportHeader'
import PersonalCareTaskWidget from "../../../PersonalCareTaskWidget"
import ActivityWidget from "../../../ActivityWidget";

const ShiftReport = props => (
  <Container>
    <Header client={props.client} user={props.user} />
    <div className="p-8">
      <ShiftReportHeader 
        shiftReport={props.shiftReport} 
        author={props.user}
        onEditEndTime={props.onEditEndTime}
      >
        <Link to="/client" className="cursor-pointer bg-gray-400 p-3 px-6 text-gray-800 mr-2 inline-block font-semibold rounded-full">
          <i className="fas fa-save inline-block mr-2" /> Save and Exit
        </Link>

        <PrimaryButton id="report-submit-button" onClick={props.onSubmit}><i className="fas fa-file-upload mr-2 inline-block" /> Submit Shift Report</PrimaryButton>
      </ShiftReportHeader>

      <h1 id="personal-care-heading" className="text-2xl mb-4">Personal Care Tasks</h1>
      <table id="personal-care-time-table" className="w-full mb-8 table--rounded shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-100">
            <th></th>
            {props.times.map(time => (
              <th className="pr-4 pb-4 pt-4" key={time}>
                {moment(time).format('H:mm')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody id="personal-care-table">
        {personalCareTasks.map((type, i) => (
          <tr className={i % 2 === 0 ? 'bg-white' : 'bg-gray-100'} key={i}>
            <td className="p-4 leading-tight w-64">
              <span className="block">{personalCareTaskLabels[type]}</span>
              {personalCareTaskHints[type] !== undefined && (<span className="text-sm text-gray-600">{personalCareTaskHints[type]}</span>)}
            </td>
            {props.times.map(time => (
            <td className="pr-4 text-center py-2" key={time}>
              <PersonalCareTaskWidget
                type={type}
                recordedAt={time}
                shiftReportId={props.shiftReport.id}
                personalCareTask={props.personalCareTaskMatrix[type] && props.personalCareTaskMatrix[type][time] ? props.personalCareTaskMatrix[type][time] : null}
                deletePersonalCareTask={props.deletePersonalCareTask}
                updatePersonalCareTask={props.updatePersonalCareTask}
                createPersonalCareTask={props.createPersonalCareTask}
              />
            </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>

      <h1 className="text-2xl mb-4">Activities</h1>
      <table id="activities-time-table" className="w-full mb-8 rounded-md table--rounded shadow-md">
        <thead>
        <tr className="bg-gray-100">
          <th></th>
          {props.times.map(time => (
            <th className="pr-4 pb-4 pt-4" key={time}>
              {moment(time).format('H:mm')}
            </th>
          ))}
        </tr>
        </thead>
        <tbody id="activities-table">
        {activities.map((type, i) => (
          <tr className={i % 2 === 0 ? 'bg-white' : 'bg-gray-100'} key={i}>
            <td className="p-4 leading-tight w-64">
              {activityLabels[type]}
            </td>
            {props.times.map(time => (
              <td className="pr-4 text-center py-2" key={time}>
                <ActivityWidget
                  type={type}
                  recordedAt={time}
                  shiftReportId={props.shiftReport.id}
                  activity={props.activityMatrix[type] && props.activityMatrix[type][time] ? props.activityMatrix[type][time] : null}
                  deleteActivity={props.deleteActivity}
                  updateActivity={props.updateActivity}
                  createActivity={props.createActivity}
                />
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>

      <h1 className="text-2xl mb-4">Vitals</h1>
      <div className="mb-8 flex flex-wrap">
        <Vitals shiftReport={props.shiftReport} />
      </div>

      <h1 className="text-2xl mb-4">Incident Reports</h1>
      <div className="mb-8 flex flex-wrap">
        <IncidentReports shiftReport={props.shiftReport} />
      </div>
    </div>
  </Container>
);

export default ShiftReport;
